import { useQuery } from 'react-query';

import { useApi } from '../../context/ApiContext';

export const useGetStrategy = (id) => {
  const client = useApi();

  return useQuery(
    ['get-strategy', id],
    () => client.get(`/contracts/optimizationSettings/${id}`).then((res) => res.data),
    {
      cacheTime: 0,
    },
  );
};
