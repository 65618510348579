import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import Collapse from '../../../../components/Collapse';
import { useGetContractsProductCombined } from '../../../../endpoints/contracts/useGetContractsProductCombined';
import { TextRegular } from '../../../../style/utils';
import ListProductTemplate from '../ListProductTemplate';

const OptimizationStrategyTemplate = ({ className, products, event, fixationsDetail }) => {
  const [t] = useTranslation();
  const { data } = useGetContractsProductCombined(event.contract);
  if (data === undefined) return null;
  const oldProducts = products;
  products = data.productsByPeriod.sort((a, b) => new Date(a.productStart) - new Date(b.productStart));
  products.forEach((product, index) => {
    const oldProduct = oldProducts.find((e) => e.productStart === product.productStart);
    let changedPeriod;
    if (event.changedPeriods.length > 0) {
      changedPeriod = event.changedPeriods.find((e) => e.periodStart === product.productStart);
    }
    if (oldProduct || changedPeriod) {
      product.fixations = oldProducts.find((e) => e.productStart === product.productStart)?.fixations;
    } else {
      // remove product
      delete products[index];
    }
  });
  return (
    <Collapse
      className={className}
      collapseProps={{
        defaultActiveKey: [`0`],
        collapsible: 'disabled',
      }}
      panelProps={{
        key: 0,
        header: t('global-fixing-and-advice'),
        showArrow: false,
      }}
    >
      <div style={{ display: 'flex' }}>
        <TextRegular className="infomap-green">{t('global-past-fixations')}</TextRegular>
        <TextRegular className="infomap-orange">{t('global-next-fixations')}</TextRegular>
        <TextRegular className="infomap-gray">{t('global-variable-percentage')}</TextRegular>
      </div>
      {products.map((product) => (
        <>
          {event.changedPeriods.length > 0 &&
          event.changedPeriods?.find((e) => e.periodStart === product.productStart) ? (
            <div className="blockData" key={product.productStart}>
              <ListProductTemplate
                amountFixed={product.amountFixed}
                date={product.productStart}
                fixationsDetail={fixationsDetail.find((e) => e.periodStart === product.productStart)}
                notFixed={oldProducts.find((e) => e.productStart === product.productStart)?.notFixed || 0}
                periodMonths={product.productMonths}
                product={product}
              />
            </div>
          ) : null}
          {event.changedPeriods.length === 0 ? (
            <div className="blockData" key={product.productStart}>
              <ListProductTemplate
                amountFixed={product.amountFixed}
                date={product.productStart}
                fixationsDetail={fixationsDetail.find((e) => e.periodStart === product.productStart)}
                notFixed={oldProducts.find((e) => e.productStart === product.productStart)?.notFixed || 0}
                periodMonths={product.productMonths}
                product={product}
              />
            </div>
          ) : null}
        </>
      ))}
    </Collapse>
  );
};

export default styled(OptimizationStrategyTemplate)`
  ${({ theme: { colors } }) => css`
    .blockData {
      margin: 19px;
      width: 160px;
      position: relative;
      break-inside: avoid;
      display: inline-block;
      vertical-align: top;
    }
    .infomap-green {
      display: flex;
      align-items: center;
      padding: 6px;
      font-size: 0.875rem;
      margin: 0 8px 8px 0;
    }
    .infomap-orange {
      display: flex;
      align-items: center;
      padding: 6px;
      font-size: 0.875rem;
      margin: 0 8px 8px 0;
    }
    .infomap-gray {
      display: flex;
      align-items: center;
      padding: 6px;
      font-size: 0.875rem;
      margin: 0 8px 8px 0;
    }
    .infomap-green::before {
      border-radius: 40px;
      content: '';
      display: flex;
      height: 9px;
      width: 9px;
      background: ${colors.green};
      print-color-adjust: exact;
      margin-right: 8px;
    }
    .infomap-orange::before {
      border-radius: 40px;
      content: '';
      display: flex;
      height: 9px;
      width: 9px;
      background: ${colors.orange};
      print-color-adjust: exact;
      margin-right: 8px;
    }
    .infomap-gray::before {
      border-radius: 40px;
      content: '';
      display: flex;
      height: 9px;
      width: 9px;
      background: ${colors.gray};
      print-color-adjust: exact;
      margin-right: 8px;
    }
  `}
`;
