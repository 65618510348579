import moment from 'moment';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import Collapse from '../../../components/Collapse/Collapse';
import { OPT_HISTORY_EVENT } from '../../../constants';
import { useGetContractsProductCombined } from '../../../endpoints/contracts/useGetContractsProductCombined';
import ContractDetailsInfoContract from '../../../screens/Contract/ContractDetails/ContractDetailsInfoContract';
import EventItem from '../../../screens/PageHistoric/Event/EventItem';
import FixationStateTemplate from './FixationStateTemplate';
import FixationTipTemplate from './FixationTipTemplate';
import MarketTrendHistoricTemplate from './MarketTrendHistoricTemplate';
import NoteHistoricTemplate from './NoteHistoricTemplate';
import OptimizationBlocksTemplate from './OptimizationBlocksTemplate';
import OptimizationParameterTemplate from './OptimizationParameterTemplate';
import OptimizationPeriodTemplate from './OptimizationPeriodsTemplate';
import OptimizationStrategyTemplate from './OptimizationStrategyTemplate';

export const getTheCorrectHistory = (dateLimit, history = []) =>
  history.filter((value) => !!moment(value.date).isBefore(dateLimit));

const EventTemplate = ({
  className,
  history,
  contract,
  optimizationHistory,
  activeKey,
  isPending,
  energyPriceUnit,
}) => {
  const [t] = useTranslation();
  const { data: products, isFetching: isProductsFetching } = useGetContractsProductCombined(contract.id);

  React.useEffect(() => {
    const mystyle = document.createElement('style');
    mystyle.innerHTML = `
    body {
      -webkit-print-color-adjust: exact;
    }
    `;
    document.head.appendChild(mystyle);
  }, []);

  const displaySubMenu = useCallback(
    (event, index) => {
      let blocks = [];
      const allSubMenu = [];
      const combinedContract = { ...contract, ...event.contractDetail };

      const GRAY_BLOCKS = {
        A_COMMENT: 'a',
        B_CONTRACT_INFO: 'b',
        C_OPTIMIZATION_PARAMS: 'c',
        D_CHART: 'd',
        E_FIXATIONS_OPTIMIZATIONS: 'e',
        F_FIXING_TIPS: 'f',
        G_OPTIMIZATION_PERIOD: 'g',
        H_FIXING_STATES: 'h',
      };
      switch (event.event) {
        case OPT_HISTORY_EVENT.NEW_CONTRACT:
          blocks = [
            GRAY_BLOCKS.A_COMMENT,
            GRAY_BLOCKS.B_CONTRACT_INFO,
            GRAY_BLOCKS.C_OPTIMIZATION_PARAMS,
            GRAY_BLOCKS.D_CHART,
            GRAY_BLOCKS.E_FIXATIONS_OPTIMIZATIONS,
          ];
          break;
        case OPT_HISTORY_EVENT.NEW_STRATEGY:
          blocks = [GRAY_BLOCKS.A_COMMENT, GRAY_BLOCKS.C_OPTIMIZATION_PARAMS, GRAY_BLOCKS.E_FIXATIONS_OPTIMIZATIONS];
          break;
        case OPT_HISTORY_EVENT.NEW_OPTIMIZATION_PERIOD:
          blocks = [GRAY_BLOCKS.A_COMMENT, GRAY_BLOCKS.G_OPTIMIZATION_PERIOD, GRAY_BLOCKS.E_FIXATIONS_OPTIMIZATIONS];
          break;
        case OPT_HISTORY_EVENT.SWITCH_MARKETTRENDS:
          blocks = [GRAY_BLOCKS.A_COMMENT, GRAY_BLOCKS.D_CHART, GRAY_BLOCKS.E_FIXATIONS_OPTIMIZATIONS];
          break;
        case OPT_HISTORY_EVENT.UPDATE_MARKETTRENDS:
          blocks = [GRAY_BLOCKS.A_COMMENT, GRAY_BLOCKS.D_CHART, GRAY_BLOCKS.E_FIXATIONS_OPTIMIZATIONS];
          break;
        case OPT_HISTORY_EVENT.OPTIMIZATION_DATE_UPCOMING:
          blocks = [GRAY_BLOCKS.A_COMMENT, GRAY_BLOCKS.F_FIXING_TIPS];
          break;
        case OPT_HISTORY_EVENT.MARKET_EVENT_STOP_LOSS:
        case OPT_HISTORY_EVENT.MARKET_EVENT_TAKE_PROFIT:
        case OPT_HISTORY_EVENT.MARKET_EVENT_LOW_LEVEL:
          blocks = [GRAY_BLOCKS.A_COMMENT, GRAY_BLOCKS.F_FIXING_TIPS];
          break;
        case OPT_HISTORY_EVENT.MARKET_EVENT_END:
          blocks = [GRAY_BLOCKS.A_COMMENT, GRAY_BLOCKS.E_FIXATIONS_OPTIMIZATIONS];
          break;
        case OPT_HISTORY_EVENT.EXPIRED_OPTIMIZATION:
          blocks = [GRAY_BLOCKS.A_COMMENT, GRAY_BLOCKS.E_FIXATIONS_OPTIMIZATIONS];
          break;
        case OPT_HISTORY_EVENT.FIXATIONS_CHANGE:
          blocks = [GRAY_BLOCKS.A_COMMENT, GRAY_BLOCKS.H_FIXING_STATES, GRAY_BLOCKS.E_FIXATIONS_OPTIMIZATIONS];
          break;
        case OPT_HISTORY_EVENT.MONTH_END:
          blocks = [GRAY_BLOCKS.A_COMMENT, GRAY_BLOCKS.E_FIXATIONS_OPTIMIZATIONS];
          break;
        default:
          blocks = [];
          break;
      }
      blocks.map((block) => {
        switch (block) {
          case GRAY_BLOCKS.A_COMMENT:
            if (event.userComment) {
              allSubMenu.push(<NoteHistoricTemplate noteValue={event.userComment} optimizationHistoryId={event.id} />);
            }
            break;
          case GRAY_BLOCKS.B_CONTRACT_INFO:
            allSubMenu.push(<ContractDetailsInfoContract history open contract={combinedContract} />);
            break;
          case GRAY_BLOCKS.C_OPTIMIZATION_PARAMS:
            allSubMenu.push(
              <OptimizationParameterTemplate
                contract={combinedContract}
                contractHasAlert={false}
                isProductsFetching={isProductsFetching}
                optimizationSettings={event}
                products={products}
              />,
            );
            break;
          case GRAY_BLOCKS.D_CHART:
            allSubMenu.push(
              <MarketTrendHistoricTemplate
                description={event.marketTrendsDetail.description}
                energyPriceUnit={energyPriceUnit}
                header={`${t('market-trend-final')} ${event.marketTrendsDetail.name}`}
                history={getTheCorrectHistory(moment(event.date).format('YYYY-MM-DD'), history)}
                keyCollapse={`m2-${index}`}
                trends={event.marketTrendsDetail.trends}
                trendtype={event.marketTrendsDetail.trendtype}
              />,
            );
            break;
          case GRAY_BLOCKS.E_FIXATIONS_OPTIMIZATIONS:
            if (event.optimizationDetail.products.length > 0) {
              if (combinedContract.isPercent) {
                // Percentage mode
                allSubMenu.push(
                  <OptimizationStrategyTemplate
                    event={event}
                    fixationsDetail={event.fixationsDetail}
                    products={event.optimizationDetail.products}
                  />,
                );
              } else {
                // Blocks mode
                // Test if blocksChartDetails is defined
                // eslint-disable-next-line no-lonely-if
                if (event.blocksChartDetails) {
                  const { fixationsUnit, consumptionsUnit, fixations, consumptions } = event.blocksChartDetails;

                  allSubMenu.push(
                    <OptimizationBlocksTemplate
                      consumptions={consumptions}
                      consumptionsUnit={consumptionsUnit}
                      fixations={fixations}
                      fixationsUnit={fixationsUnit}
                    />,
                  );
                } else {
                  // console.log('event.blocksChartDetails not defined, event:', event);
                }
              }
            }
            break;
          case GRAY_BLOCKS.F_FIXING_TIPS:
            allSubMenu.push(<FixationTipTemplate optimizationSettings={event} />);
            break;
          case GRAY_BLOCKS.G_OPTIMIZATION_PERIOD:
            allSubMenu.push(<OptimizationPeriodTemplate optimizationSettings={event} />);
            break;
          case GRAY_BLOCKS.H_FIXING_STATES:
            if (event.fixationsDetail.length > 0) {
              allSubMenu.push(
                <FixationStateTemplate contractId={event.contract} fixationsDetail={event.fixationsDetail} />,
              );
            }
            break;
          default:
        }
        return null;
      });
      return allSubMenu;
    },
    [contract, energyPriceUnit, history, isProductsFetching, products, t],
  );

  if (isPending) {
    return null;
  }

  return (
    <>
      {optimizationHistory.map((item, index) => (
        <>
          {item.event === OPT_HISTORY_EVENT.NEW_OBJECTIVES ? null : (
            <section className={`${className} sheet`} key={index}>
              <Collapse
                className="beige"
                collapseProps={{
                  activeKey,
                }}
                panelProps={{
                  key: index,
                  header: <EventItem {...item} />,
                  showArrow: false,
                }}
              >
                {displaySubMenu(item).map((item, index) => (
                  <React.Fragment key={index}>{React.cloneElement(item, { keyElem: index })}</React.Fragment>
                ))}
              </Collapse>
            </section>
          )}
        </>
      ))}
    </>
  );
};

export default styled(EventTemplate)`
  ${({ theme: { colors } }) => css`
    @media print {
      body {
        -webkit-print-color-adjust: exact;
      }
    }
    .beige {
      background: ${colors.beige};
    }
  `}
`;
