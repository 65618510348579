import { message } from 'antd';
import { useQuery } from 'react-query';

import { useApi } from '../../context/ApiContext';

export const useGetKamContractList = (userId) => {
  const client = useApi();

  return useQuery(
    'get-kam-contract-list',
    () => client.get(`/multiaccess/kam/contractsListForClient/${userId}`).then((res) => res.data),
    {
      onError() {
        message.error('Cannot get the contract list');
      },
    },
  );
};
