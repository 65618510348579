import { Col, Row, Space } from 'antd';
import moment from 'moment';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Hr from '../../../../components/Hr';
import MarketItem from '../../../../components/MarketItem';
import { Error, Label } from '../../../../style/utils';
import { getErrorMessage } from '../../../../utils/input';
import { isBoolean } from '../../../../utils/user';

const FormStep2 = ({ marketList }) => {
  const [t] = useTranslation();
  const { control, register, errors, clearErrors, watch, setValue, getValues } = useFormContext();
  const { fields } = useFieldArray({ name: 'multiaccess.clientMarkets' });
  const { multiaccess } = watch();

  React.useEffect(() => {
    if (multiaccess.clientCanLogin) {
      setValue(
        'multiaccess.clientMarkets',
        marketList.map((market) => ({
          contracts: market.contracts || undefined,
          country: market.country,
          enabled: market.enabled || false,
          energy: market.energy,
          marketTrendsPartner: market.marketTrendsPartner || false,
          marketTrendsCompany: market.marketTrendsCompany || false,
          marketTrendsUser: market.marketTrendsUser || false,
          hasCompanyMarketTrend: market.hasCompanyMarketTrend,
          period: market.periodStart
            ? [moment(market.periodStart, 'YYYY-MM-DD'), moment(market.periodEnd, 'YYYY-MM-DD')]
            : [],
        })),
      );
    }
  }, [marketList, multiaccess.clientCanLogin, setValue]);

  React.useEffect(() => {
    register(
      { name: 'multiaccess.clientCanLogin' },
      { validate: (value) => (!isBoolean(value) ? 'global-field-required' : null) },
    );
  }, [register]);

  React.useEffect(() => {
    if (multiaccess.clientCanLogin) {
      register({ name: 'multiaccess.contractsManagedBy' }, { required: true });
    }
  }, [multiaccess.clientCanLogin, register]);

  // const AccessToolRadio = React.useMemo(
  //   () => [
  //     { key: 0, value: false, label: t('multi-access-edit-manage-kam-step-2') },
  //     { key: 1, value: true, label: t('multi-access-edit-manage-client-step-2') },
  //   ],
  //   [t],
  // );

  return (
    <Space direction="vertical" size="large">
      {/* <Controller
        as={Input.Radio}
        control={control}
        error={errors?.multiaccess?.clientCanLogin}
        items={AccessToolRadio}
        label={t('multi-access-edit-is-manage-kam-step-2')}
        name="multiaccess.clientCanLogin"
      /> */}

      {multiaccess.clientCanLogin && (
        <Space direction="vertical" size="large">
          <Label>{t('multi-access-edit-explain1-info')}</Label>
          <Space direction="vertical">
            <Row gutter={[40, 20]}>
              {fields.map((field, index) => (
                <Col className="gutter-row" key={field.id} md={9} sm={12} xs={24}>
                  <MarketItem
                    clearErrors={clearErrors}
                    control={control}
                    country={field.country}
                    data={fields}
                    energy={field.energy}
                    errors={errors?.multiaccess?.clientMarkets?.[index]}
                    field={field}
                    getValues={getValues}
                    // hasCompanyMarketTrend={field.hasCompanyMarketTrend}
                    // managedByClient={multiaccess.contractsManagedBy === MANAGED_BY.CLIENT}
                    name={`multiaccess.clientMarkets[${index}]`}
                    register={register}
                    setValue={setValue}
                  />
                </Col>
              ))}
            </Row>
            {errors?.multiaccess?.clientMarkets?.message && (
              <Error>{getErrorMessage(errors.multiaccess.clientMarkets)}</Error>
            )}
          </Space>
        </Space>
      )}
      {multiaccess.clientCanLogin && <Hr />}
    </Space>
  );
};

export default FormStep2;
