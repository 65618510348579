import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Collapse from '../../../../components/Collapse';
import Hr from '../../../../components/Hr/Hr';
import ChartBlocs from '../../../../screens/Contract/ContractDetails/ContractBlocsProducts/components/ChartBlocs';
import Table from '../../../../screens/Contract/ContractDetails/ContractBlocsProducts/components/Table';

const OptimizationBlocksTemplate = ({ fixationsUnit, consumptionsUnit, fixations, consumptions }) => {
  const [t] = useTranslation();
  return (
    <>
      <Collapse
        collapseProps={{
          defaultActiveKey: ['0'],
          collapsible: 'disabled',
        }}
        panelProps={{
          key: 0,
          header: t('contract-detail-blocks-title'),
          showArrow: false,
        }}
      >
        <div>
          <>
            <ChartBlocs
              consumptionsUnit={consumptionsUnit}
              data={fixations}
              fixationsUnit={fixationsUnit}
              hoveredDate={undefined}
              mwh={consumptions}
              showOptimizations={false}
            />
            <Hr />
            <Hr />
            <Table
              showOptimizations
              showTypeColumn
              consumptionsUnit={consumptionsUnit}
              data={fixations}
              fixationsUnit={fixationsUnit}
            />
          </>
        </div>
      </Collapse>
    </>
  );
};

export default styled(OptimizationBlocksTemplate)``;
