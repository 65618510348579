import React from 'react';
import { useTranslation } from 'react-i18next';

import Collapse from '../../../../components/Collapse/Collapse';
import FormatDate from '../../../../components/FormatDate';
import getFormattedPeriodDate from '../../../../components/FormatPeriodDate/getFormattedPeriodDate';
import Table from '../../../../components/Table';
import { useGetProductDetails } from '../../../../endpoints/Product/useGetProductDetails';

const FixationPast = ({ fixationDetail, contractId, open = false }) => {
  const [t] = useTranslation();
  const { data } = useGetProductDetails(contractId, fixationDetail.periodStart);

  const headers = React.useMemo(() => {
    if (!data) return [];
    const { contract, personalObjectives } = data;
    const columns = [
      {
        title: t('fixation-past-table-day'),
        dataIndex: 'date',
        // eslint-disable-next-line no-nested-ternary
        width: 240,
        render: (date) => <FormatDate date={date} />,
      },
      {
        title: t('fixation-past-table-volume'),
        width: 130,
        dataIndex: 'amount',
        align: 'center',
        render: (amount) => `${amount}%`,
      },
    ];
    if (data.fixations[0].prices.length >= 2 && personalObjectives.defined) {
      contract.indexLabels.forEach((label, index) => {
        columns.push({
          title: label,
          key: label,
          width: contract.indexLabels.length - 1 === index ? undefined : 120,
          render: (record) => `${record.prices[index]} ${t(`global-energy-${contract.energyPriceUnit}`)}`,
        });
      });
      columns.push({
        title: t('fixation-past-table-average-price'),
        key: 'average-price',
        align: 'center',
        width: 180,
        render: (record) =>
          record.averagePrice
            ? `${record.averagePrice} ${t(`global-energy-${contract.energyPriceUnit}`)}`
            : t('fixation-past-table-not-defined'),
      });
      if (data.fixations[0].prices.length === 2) {
        columns.push({
          title: '',
          key: 'none',
          width: 400,
        });
      }
    } else if (personalObjectives.defined) {
      columns.push({
        title: t('fixation-past-table-price'),
        key: 'price',
        width: 180,
        render: (record) =>
          record.prices[0]
            ? `${record.prices[0]} ${t(`global-energy-${contract.energyPriceUnit}`)}`
            : t('fixation-past-table-not-defined'),
      });
      columns.push({
        title: '',
        key: 'none',
        width: 400,
      });
    } else
      columns.push({
        title: '',
        key: 'none',
        width: 400,
      });

    return columns;
  }, [data, t]);

  return (
    <Collapse
      collapseProps={{
        defaultActiveKey: [open ? '0' : ''],
        collapsible: open ? 'disabled' : '',
      }}
      panelProps={{
        key: 0,
        header: t(
          getFormattedPeriodDate({
            date: fixationDetail.periodStart,
            granularity: data?.contract?.fixationGranularity,
          }),
        ),
        showArrow: !open,
      }}
    >
      {data && data.fixations.length !== 0 ? (
        <Table
          noBgHover
          columns={headers}
          dataSource={data.fixations.map((f, index) => ({ ...f, key: index }))}
          isClickable={false}
          name="fixation-past"
        />
      ) : (
        <p>{t('no-fixation-for-this-period')}</p>
      )}
    </Collapse>
  );
};

export default FixationPast;
