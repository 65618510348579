import React, { useMemo } from 'react';

import { useGetProductDetails } from '../../../endpoints/Product/useGetProductDetails';
import FormatPeriodDate from '../../FormatPeriodDate';

export default ({ location }) => {
  const params = location.pathname.split('/');
  const { data } = useGetProductDetails(params[2], params[3]);

  return useMemo(() => {
    if (!data) {
      return '';
    }
    return <FormatPeriodDate date={params[3]} format="MMM YYYY" granularity={data.contract.fixationGranularity} />;
  }, [data, params]);
};
