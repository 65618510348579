import { Col, Row, Space } from 'antd';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Collapse from '../../../../components/Collapse';
import getFormattedPeriodDate from '../../../../components/FormatPeriodDate/getFormattedPeriodDate';
import { OPT_HISTORY_EVENT } from '../../../../constants';
import { useGetStrategy } from '../../../../endpoints/contracts/useGetStrategy';
import { Label, TextRegular } from '../../../../style/utils';

const FixingTipsTemplate = ({ optimizationSettings }) => {
  const [t] = useTranslation();
  const { data } = useGetStrategy(optimizationSettings.contract);

  const informationContract = React.useMemo(() => {
    if (!data) return [];
    const isBlocks = optimizationSettings.contractDetail?.isBlocks;
    const infos = [{ data: [] }];
    if (optimizationSettings.event === OPT_HISTORY_EVENT.OPTIMIZATION_DATE_UPCOMING) {
      if (!optimizationSettings.upcomingOptimizationsDetails.optimizations) return [];
      optimizationSettings.upcomingOptimizationsDetails.optimizations.forEach((item) => {
        if (isBlocks) {
          const unit = optimizationSettings.contractDetail?.blocksSettings?.fixationUnit;
          const textUnit = t(`global-unit-${unit}`);
          infos[0].data.push([
            `${getFormattedPeriodDate({
              date: item.periodStart,
              periodMonths: item.periodMonths,
              format: 'MMM YYYY',
            })} - ${item.amount} ${textUnit}`,
          ]);
        } else {
          infos[0].data.push([`${moment(item.periodStart).format('DD MMM YYYY')} (${item.amount}%)`]);
        }
      });
    } else {
      if (optimizationSettings.optimizationDetail.products.length === 0) return [];
      optimizationSettings.optimizationDetail.products.forEach((item) => {
        const fixationData = item.fixations.filter((i) => i.eventType);
        item.fixations = fixationData;
        fixationData.forEach((value) => {
          infos[0].data.push([
            `${getFormattedPeriodDate({
              date: item.productStart,
              granularity: data.contract.fixationGranularity,
              format: 'MMM YYYY',
            })}: ${moment(value.periodStart).format('DD MMM YYYY')} (${value.amount}%)`,
          ]);
        });
      });
    }
    return infos;
  }, [data, optimizationSettings, t]);

  return (
    <>
      <Collapse
        collapseProps={{
          defaultActiveKey: ['0'],
          collapsible: 'disabled',
        }}
        panelProps={{
          key: 0,
          header: t('contract-detail-fixing-tips'),
          showArrow: false,
        }}
      >
        <Row className="rowHistoric" gutter={[18, 25]}>
          {informationContract.map((item) => (
            <Col key={item.title} sm={12} xs={24}>
              <Space direction="vertical">
                <Label>{item.title}</Label>
                <Space direction="vertical" size={0}>
                  {item.data.map((info) => {
                    if (typeof info === 'string') {
                      return <TextRegular key={info}>{info}</TextRegular>;
                    }

                    return info;
                  })}
                </Space>
              </Space>
            </Col>
          ))}
        </Row>
        <br />
      </Collapse>
    </>
  );
};

export default FixingTipsTemplate;
