import { Input } from 'antd';
import styled, { css } from 'styled-components';

export const WrapperInputText = styled.div`
  & + & {
    margin-top: 18px;
  }
`;

export const InputStyled = styled(Input)`
  ${({ theme: { fontSize, colors }, width = 100 }) => css`
    background: #fafafa;
    border-radius: 4px;
    min-width: ${width}px;
    font-size: ${fontSize.regular};
    line-height: 1.667em;
    color: ${colors.grayDark};
  `}
`;

export const InputPasswordStyled = styled(Input.Password)`
  ${({ theme: { fontSize, colors } }) => css`
    background: #fafafa;
    border-radius: 4px;
    min-width: 100px;
    font-size: ${fontSize.regular};
    color: ${colors.grayDark};
  `}
`;
