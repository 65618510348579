import { useTranslation } from 'react-i18next';

import { useUserInfo } from '../../../context/UserInfoContext';
import { isKam } from '../../../utils/user';

export default () => {
  const [t] = useTranslation();
  const { userInfo } = useUserInfo();

  return isKam(userInfo.role) ? t('breadcrumb-customer-kam') : t('breadcrumb-customer-manager');
};
