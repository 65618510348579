import { Col, Row, Space } from 'antd';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import FormatDate from '../../../../components/FormatDate';
import FormatPeriodDate from '../../../../components/FormatPeriodDate';
import getFormattedPeriodDate from '../../../../components/FormatPeriodDate/getFormattedPeriodDate';
import HistoricCalendarMonthIcon from '../../../../components/icons/HistoricCalendarMonthIcon';
import HistoricFixationOutdated from '../../../../components/icons/HistoricFixationOutdated';
import HistoricFixationUpdateIcon from '../../../../components/icons/HistoricFixationUpdateIcon';
import HistoricMarketTrendSwitchIcon from '../../../../components/icons/HistoricMarketTrendSwitchIcon';
import HistoricMarketTrendUpdateIcon from '../../../../components/icons/HistoricMarketTrendUpdateIcon';
import HistoricNewContractIcon from '../../../../components/icons/HistoricNewContractIcon';
import HistoricNewEventIcon from '../../../../components/icons/HistoricNewEventIcon';
import HistoricOptimizationDateUpcomingIcon from '../../../../components/icons/HistoricOptimizationDateUpcomingIcon';
import HistoricStopLoss from '../../../../components/icons/HistoricStopLoss';
import HistoricStrategyIcon from '../../../../components/icons/HistoricStrategyIcon';
import { OPT_HISTORY_EVENT } from '../../../../constants';
import { useGetStrategy } from '../../../../endpoints/contracts/useGetStrategy';
import { Date, Description, EventName } from './EventItem.styled';

const EventItem = ({
  event,
  date,
  contract,
  contractDetail,
  marketTrendsDetail,
  changedPeriods,
  optimizationPeriodDetails,
  upcomingOptimizationsDetails,
}) => {
  const [t] = useTranslation();
  const { data } = useGetStrategy(contract);

  const description = useMemo(() => {
    if (!data) return [];
    switch (event) {
      case OPT_HISTORY_EVENT.NEW_CONTRACT:
        return (
          <div>
            <Row align="start">
              <Col>
                <span>{t('event-item-new-contract-market-trend')}</span> <span>{marketTrendsDetail.name}</span>
              </Col>
            </Row>
            <Row>
              <Col>
                {t('event-item-new-contract-strategy')} <span>{t(`strategy-global-${contractDetail.strategy}`)}</span>
              </Col>
            </Row>
          </div>
        );
      case OPT_HISTORY_EVENT.NEW_STRATEGY:
        return (
          <div>
            {contractDetail.optimizationSettings ? (
              <>
                <Row align="start">
                  <Col>
                    <span>{t('strategy-risk-appetite')}</span>{' '}
                    {contractDetail.optimizationSettings?.strategyIsDefinedPerPeriod ? (
                      <span>{t(`strategy-by-period`)}</span>
                    ) : (
                      <span>{t(`strategy-global-${contractDetail.optimizationSettings.globalStrategy}`)}</span>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span>{t('strategy-optimization-advice-percentage')}</span>{' '}
                    {contractDetail.optimizationSettings.strategyIsDefinedPerPeriod ? (
                      <span>{t(`strategy-by-period`)}</span>
                    ) : (
                      <>
                        <span>{t('strategy-global')}</span>{' '}
                        <span>{t(`${contractDetail.optimizationSettings.globalMaximumOptimization} %`)}</span>
                      </>
                    )}
                  </Col>
                </Row>
              </>
            ) : (
              <Row align="start">
                <Col>
                  <span>{t('strategy-risk-appetite')}</span>{' '}
                  <span>{t(`strategy-global-${contractDetail.strategy}`)}</span>
                </Col>
              </Row>
            )}
          </div>
        );
      case OPT_HISTORY_EVENT.NEW_OPTIMIZATION_PERIOD:
        return (
          <div>
            <Row align="start">
              <Col>
                <span>{t('event-item-period-concerned')}</span>{' '}
                <span>
                  {getFormattedPeriodDate({
                    date: optimizationPeriodDetails.periodStart,
                    granularity: data.contract.fixationGranularity,
                    format: 'MMM YYYY',
                  })}
                </span>{' '}
                <span>
                  {t(
                    `(${moment(optimizationPeriodDetails.optimizationPeriodStart).format('DD/MM/YY')}
                     ${t('global-to')} ${moment(optimizationPeriodDetails.optimizationPeriodEnd).format('DD/MM/YY')})`,
                  )}
                </span>{' '}
              </Col>
            </Row>
          </div>
        );
      case OPT_HISTORY_EVENT.EXPIRED_OPTIMIZATION:
      case OPT_HISTORY_EVENT.FIXATIONS_CHANGE:
        return (
          changedPeriods.length > 0 && (
            <Row gutter={2}>
              <Col>{t('event-item-fixations-change')}</Col>
              <Col>
                <Row gutter={4} justify="start">
                  {changedPeriods.map(({ periodStart, periodMonths }, index) => (
                    <Col key={periodStart}>
                      <FormatPeriodDate date={periodStart} periodMonths={periodMonths} />
                      {index === changedPeriods.length - 1 ? '' : ', '}
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          )
        );
      case OPT_HISTORY_EVENT.UPDATE_MARKETTRENDS:
        return (
          <Row align="start">
            <Col>
              <span>{t('event-item-new-contract-market-trend')}</span> <span>{marketTrendsDetail.name}</span>
            </Col>
          </Row>
        );

      case OPT_HISTORY_EVENT.SWITCH_MARKETTRENDS:
        return (
          <div>
            <Row align="start">
              <Col>
                <span>{t('event-item-baseline-forecast')}</span> <span>{marketTrendsDetail.name}</span>
              </Col>
            </Row>
          </div>
        );
      default:
        return null;
    }
  }, [
    data,
    event,
    t,
    marketTrendsDetail,
    contractDetail.strategy,
    contractDetail.optimizationSettings,
    optimizationPeriodDetails,
    changedPeriods,
  ]);

  const iconEvent = useMemo(() => {
    switch (event) {
      case OPT_HISTORY_EVENT.NEW_STRATEGY:
        return <HistoricStrategyIcon />;
      case OPT_HISTORY_EVENT.FIXATIONS_CHANGE:
        return <HistoricFixationUpdateIcon />;
      case OPT_HISTORY_EVENT.NEW_OPTIMIZATION_PERIOD:
        return <HistoricCalendarMonthIcon />;
      case OPT_HISTORY_EVENT.SWITCH_MARKETTRENDS:
        return <HistoricMarketTrendSwitchIcon />;
      case OPT_HISTORY_EVENT.UPDATE_MARKETTRENDS:
        return <HistoricMarketTrendUpdateIcon />;
      case OPT_HISTORY_EVENT.EXPIRED_OPTIMIZATION:
        return <HistoricFixationOutdated />;
      case OPT_HISTORY_EVENT.MONTH_END:
        return <HistoricCalendarMonthIcon />;
      case OPT_HISTORY_EVENT.NEW_CONTRACT:
        return <HistoricNewContractIcon />;
      case OPT_HISTORY_EVENT.MARKET_EVENT_STOP_LOSS:
        return <HistoricStopLoss />;
      case OPT_HISTORY_EVENT.MARKET_EVENT_TAKE_PROFIT:
      case OPT_HISTORY_EVENT.MARKET_EVENT_LOW_LEVEL:
      case OPT_HISTORY_EVENT.MARKET_EVENT_END:
        return <HistoricNewEventIcon />;
      case OPT_HISTORY_EVENT.OPTIMIZATION_DATE_UPCOMING:
        return <HistoricOptimizationDateUpcomingIcon />;
      default:
        return null;
    }
  }, [event]);

  let addonText = '';
  switch (event) {
    case OPT_HISTORY_EVENT.UPDATE_MARKETTRENDS:
      if (!marketTrendsDetail.trendtype === 'partner') {
        event = 'update-partner-markettrends';
      }
      break;
    case OPT_HISTORY_EVENT.OPTIMIZATION_DATE_UPCOMING:
      event = `fixationDateUpcoming`;
      addonText = `${moment(upcomingOptimizationsDetails.date).format('DD/MM/YYYY')}`;
      break;
    default:
      break;
  }

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Row align="center" justify="space-between">
        <Col>
          <Date>
            <FormatDate isHistory date={date} />
          </Date>
        </Col>
      </Row>
      <Space>
        {iconEvent}
        <EventName>
          {t(`event-item-title-${event}`)}
          {addonText}
        </EventName>
      </Space>
      <Description>{description}</Description>
    </Space>
  );
};
export default EventItem;
