import { Col, Row, Space } from 'antd';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Hr from '../../../../components/Hr';
import Input from '../../../../components/Input';
import { regexEmail, regexNumeric } from '../../../../constants';
import { useUserInfo } from '../../../../context/UserInfoContext';
import { Label } from '../../../../style/utils';
import Demo from './components/Demo';

// import { isBoolean } from '../../../../utils/user';

const FormStep1 = ({ isCreateForm }) => {
  const [t] = useTranslation();
  const { control, register, errors } = useFormContext();
  const { userInfo } = useUserInfo();

  React.useEffect(() => {
    register({ name: 'company' }, { required: true });
    register(
      { name: 'multiaccess.clientReference' },
      {
        required: false,
        validate: (value) => {
          if (!value) return true;
          if (!value.match(regexNumeric)) {
            return 'This filed must be numeric';
          }
          return undefined;
        },
      },
    );
    register({ name: 'lastName' }, { required: true });
    register({ name: 'firstName' }, { required: true });
    register(
      { name: 'username' },
      {
        required: true,
        validate: (value) => {
          if (!value.match(regexEmail)) {
            return 'email-not-valid';
          }

          return undefined;
        },
      },
    );
    register(
      { name: 'phone' },
      { validate: (value) => (value.length < 3 ? 'global-field-phone-required' : undefined) },
    );
  }, [isCreateForm, register]);

  return (
    <Space direction="vertical" size="large">
      <Row gutter={[38, 20]}>
        <Col lg={7} xs={24}>
          <Controller
            as={Input.Text}
            control={control}
            error={errors.company}
            label={t('multi-access-edit-company-step-1')}
            name="company"
          />
        </Col>
        <Col lg={7} xs={24}>
          <Controller
            as={Input.Text}
            control={control}
            error={errors?.multiaccess?.clientReference}
            label={t('multi-access-edit-reference-step-1')}
            name="multiaccess.clientReference"
          />
        </Col>
      </Row>

      <Hr />

      <Row gutter={[0, 20]}>
        <Col>
          <Label>{t('multi-access-create-client-description-title')}</Label>
        </Col>
        <Col span={24}>
          <Row gutter={[38, 20]}>
            <Col lg={7} xs={24}>
              <Controller
                as={Input.Text}
                control={control}
                error={errors.lastName}
                label={t('multi-access-edit-lastname-step-1')}
                name="lastName"
              />
            </Col>
            <Col lg={7} xs={24}>
              <Controller
                as={Input.Text}
                control={control}
                error={errors.firstName}
                label={t('multi-access-edit-firstname-step-1')}
                name="firstName"
              />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={[38, 20]}>
            <Col lg={7} xs={24}>
              <Controller
                as={Input.Text}
                control={control}
                disabled={!isCreateForm}
                error={errors.username}
                label={t('multi-access-edit-email-step-1')}
                name="username"
              />
            </Col>
            <Col lg={7} xs={24}>
              <Controller
                as={Input.Phone}
                control={control}
                country={userInfo.language}
                error={errors.phone}
                label={t('multi-access-edit-phone-step-1')}
                name="phone"
              />
            </Col>
          </Row>
        </Col>
      </Row>

      {isCreateForm && !userInfo?.isTenantBlock && (
        <>
          <Hr />
          <Demo />
          <Hr />
        </>
      )}
    </Space>
  );
};

export default FormStep1;
