import React from 'react';

function HistoricStrategyIcon() {
  return (
    <svg
      style={{ display: 'block' }}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="17.641"
      viewBox="0 0 20 17.641"
    >
      <defs>
        <clipPath id="clip-path">
          <path fill="#202124" d="M0 0H20V17.641H0z" data-name="Rectangle 907" />
        </clipPath>
      </defs>
      <g fill="#202124" clipPath="url(#clip-path)" data-name="Groupe 1535">
        <path
          d="M49.454 35.232a1.781 1.781 0 01-3.234 1.029l1.867-1.044-1.787-1.128a1.782 1.782 0 013.15 1.143"
          data-name="Tracé 235"
          transform="translate(-36.494 -26.41)"
        />
        <path
          d="M35.815 16.29a5.389 5.389 0 00-4.432 2.317l1.644 1.037a3.456 3.456 0 11-.13 3.885l-1.7.949a5.394 5.394 0 104.615-8.188"
          data-name="Tracé 236"
          transform="translate(-24.634 -12.862)"
        />
        <path
          d="M24.587 0a8.814 8.814 0 00-7.332 3.914l1.515.957a7.031 7.031 0 11-.227 7.542l-1.564.874A8.821 8.821 0 1024.587 0"
          data-name="Tracé 237"
          transform="translate(-13.407)"
        />
        <path
          d="M49.454 35.232a1.781 1.781 0 01-3.234 1.029l1.867-1.044-1.787-1.128a1.782 1.782 0 013.15 1.143"
          data-name="Tracé 238"
          transform="translate(-36.494 -26.41)"
        />
        <path
          d="M35.815 16.29a5.389 5.389 0 00-4.432 2.317l1.644 1.037a3.456 3.456 0 11-.13 3.885l-1.7.949a5.394 5.394 0 104.615-8.188"
          data-name="Tracé 239"
          transform="translate(-24.634 -12.862)"
        />
        <path
          d="M49.454 35.232a1.781 1.781 0 01-3.234 1.029l1.867-1.044-1.787-1.128a1.782 1.782 0 013.15 1.143"
          data-name="Tracé 240"
          transform="translate(-36.494 -26.41)"
        />
        <path
          d="M35.815 16.29a5.389 5.389 0 00-4.432 2.317l1.644 1.037a3.456 3.456 0 11-.13 3.885l-1.7.949a5.394 5.394 0 104.615-8.188"
          data-name="Tracé 241"
          transform="translate(-24.634 -12.862)"
        />
        <path
          d="M49.454 35.232a1.781 1.781 0 01-3.234 1.029l1.867-1.044-1.787-1.128a1.782 1.782 0 013.15 1.143"
          data-name="Tracé 242"
          transform="translate(-36.494 -26.41)"
        />
        <path
          d="M49.454 35.232a1.781 1.781 0 01-3.234 1.029l1.867-1.044-1.787-1.128a1.782 1.782 0 013.15 1.143"
          data-name="Tracé 243"
          transform="translate(-36.494 -26.41)"
        />
        <path
          d="M8.3 21.754l-.57.318-1.8 1.008-1.477.82-1.593.9L0 26.392V16.51l3.047 1.925 1.543.972 1.425.9 1.74 1.1z"
          data-name="Tracé 244"
          transform="translate(0 -13.036)"
        />
      </g>
    </svg>
  );
}

export default HistoricStrategyIcon;
