import React from 'react';
import styled from 'styled-components';

const InvisibleButton = ({ children, className, name, ...props }) => (
  <button className={className} type="button" {...props} data-testid={`invisible-button-${name}`}>
    {children}
  </button>
);

export default styled(InvisibleButton)`
  background-color: transparent;
  padding: 0;
  border: 0;
  outline: none;
  display: flex;
  align-items: center;

  & :hover {
    cursor: pointer;
  }

  & :focus {
    outline: none;
  }
`;
