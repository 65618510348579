import { useMutation, useQueryClient } from 'react-query';

import { useApi } from '../../context/ApiContext';
import { useTenant } from '../../context/TenantContext';

export const useCreateGroup = (isCreatingGroup = false, id = '') => {
  const client = useApi();
  const queryClient = useQueryClient();
  const { env } = useTenant();

  return useMutation(
    (payload) => {
      if (isCreatingGroup)
        return client.post(`${env.REACT_APP_BACKEND_PORTAL_URL}/users/multi-access/kamGroup`, payload);
      return client.put(`${env.REACT_APP_BACKEND_PORTAL_URL}/users/multi-access/kamGroup/${id}`, payload);
    },
    {
      onSuccess() {
        if (!isCreatingGroup) {
          queryClient.invalidateQueries(['group-details', id]);
        }
      },
    },
  );
};
