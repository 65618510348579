export function updateMtUserState(state, payload) {
  return {
    ...state,
    mtUser: {
      ...state.mtUser,
      ...payload,
    },
  };
}

export function updateNewContractState(state, payload) {
  return {
    ...state,
    newContractForm: {
      ...state.newContractForm,
      ...payload,
    },
  };
}

export function updateNewObjectivesState(state, payload) {
  return {
    ...state,
    objectives: {
      ...state.objectives,
      ...payload,
    },
  };
}

export function updateMultiAccessForm(state, payload) {
  return {
    ...state,
    multiAccessClient: {
      ...state.multiAccessClient,
      ...payload,
      multiaccess: {
        ...state.multiAccessClient.multiaccess,
        ...payload.multiaccess,
      },
    },
  };
}

export function updateNewTicketState(state, payload) {
  return {
    ...state,
    newTicketState: {
      ...state.newTicketState,
      ...payload,
    },
  };
}
