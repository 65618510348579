import { Collapse as CollapseAnt } from 'antd';
import React from 'react';
import styled, { css } from 'styled-components';

import { Label } from '../../style/utils';
import ArrowRight from '../icons/ArrowRight';

const { Panel } = CollapseAnt;

const WrapperArrow = styled.div`
  ${({ isActive }) => css`
    svg {
      transform: ${isActive ? 'rotate(90deg)' : 'rotate(0deg)'};
    }
  `}
`;

const Collapse = ({ className, collapseProps, panelProps, children }) => {
  const getCorrectIcon = React.useCallback(
    ({ isActive }) => (
      <WrapperArrow isActive={isActive}>
        <ArrowRight />
      </WrapperArrow>
    ),
    [],
  );

  return (
    <CollapseAnt className={className} expandIcon={getCorrectIcon} expandIconPosition="end" {...collapseProps}>
      <Panel {...panelProps} header={<Label>{panelProps.header}</Label>}>
        {children}
      </Panel>
    </CollapseAnt>
  );
};

export default styled(Collapse)`
  ${({ theme: { colors } }) => css`
    background: ${colors.grayLight};
    border: none;
    width: 100%;

    && .ant-collapse-item {
      border-bottom: none;
    }

    .ant-collapse-content {
      border-top: unset;
    }

    .ant-collapse-content > .ant-collapse-content-box {
      padding: 16px 0 0;
    }
    .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header {
      cursor: unset;
    }
  `}
`;
