import React from 'react';

const HistoricNewEventIcon = (props) => (
  <svg
    style={{ display: 'block' }}
    xmlns="http://www.w3.org/2000/svg"
    width="20.72"
    height="23.47"
    viewBox="0 0 20.72 23.47"
    {...props}
  >
    <defs>
      <style>{'.cls-1{fill:#221f20}'}</style>
    </defs>
    <g id="Calque_2" data-name="Calque 2">
      <g id="Calque_1-2" data-name="Calque 1">
        <path className="cls-1" d="M0 7.3h4.25v16.17H0zM6.26 15.7h4.25v7.77H6.26z" />
        <path
          className="cls-1"
          d="M20.72 7.41a7.54 7.54 0 0 0-15.08 0 7.48 7.48 0 0 0 6.75 7.38v8.67h4.25V14a7.41 7.41 0 0 0 4.08-6.59Zm-7.54 6a6.08 6.08 0 0 1-6.13-6 6.14 6.14 0 0 1 12.27 0 6.09 6.09 0 0 1-6.14 6.02Z"
        />
        <path className="cls-1" d="M13.88 3h-1.4v4.41a.71.71 0 0 0 .45.66l3.19 1.2.49-1.27-2.73-1Z" />
      </g>
    </g>
  </svg>
);

export default HistoricNewEventIcon;
