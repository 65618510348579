import { Popover as PopoverAnt, Space, Switch } from 'antd';
import moment from 'moment';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';

import { useTenant } from '../../context/TenantContext';
import { useUserInfo } from '../../context/UserInfoContext';
import useCookie from '../../hooks/useCookie';
import useGetFollowApp from '../../hooks/useGetFollowApp';
import useRedirectToPortal from '../../hooks/useRedirectToPortal';
import { Navigation } from '../../navigation';
import { TextColored, TextRegular } from '../../style/utils';
import { isClient, isFreemium, isMultiAccess } from '../../utils/user';
import Hr from '../Hr';
import AccountIcon from '../icons/AccountIcon';
import ModalSimple from '../Modals/ModalSimple';
import { BoldButton, LastName, Society, SubTitle, Title, WrapperEnterprise } from './Popover.styled';

const Popover = ({ showDemo, setShowDemo, isTimeTravelActive }) => {
  const [showPopover, setShowPopover] = React.useState(false);
  const followAppUrl = useGetFollowApp();
  const buttonRef = React.useRef();
  const { userInfo } = useUserInfo();
  const { getCookie, setCookie, removeCookie } = useCookie();
  const { role, admin, demo, lastName, firstName, company, products } = userInfo;
  const [t] = useTranslation();
  const themeContext = React.useContext(ThemeContext);
  const { redirectToPortal } = useRedirectToPortal();
  const { isLoginCredentialsNeeded } = useTenant();

  const handleVisibleChange = React.useCallback(
    (visible) => {
      setShowPopover(visible);
    },
    [setShowPopover],
  );

  const switchToManagerAccount = React.useCallback(() => {
    const cookieManager = getCookie('manager_yem_jwt');
    setCookie('yem_jwt', cookieManager);
    removeCookie('manager_yem_jwt');
    if (!getCookie('manager_yem_jwt')) window.location.assign(followAppUrl);
  }, [followAppUrl, getCookie, removeCookie, setCookie]);

  const handleAdminPortal = React.useCallback(() => {
    redirectToPortal(Navigation.ADMIN_PORTAL).go();
    setShowPopover(false);
  }, [redirectToPortal]);

  const handleLogout = React.useCallback(() => {
    redirectToPortal(Navigation.LOGOUT).go();
    setShowPopover(false);
  }, [redirectToPortal]);

  const handleChangeSwitch = React.useCallback(
    (checked) => {
      sessionStorage.setItem('travelInTime', checked);
      setShowDemo(checked);
    },
    [setShowDemo],
  );

  const title = React.useMemo(() => {
    let clientMarkets;
    let contractNumber;
    let freemium;
    let membershipApp;

    if (isClient(role) && isMultiAccess(userInfo)) {
      clientMarkets = userInfo.multiaccess?.clientMarkets?.map((market) => {
        if (!market.enabled) {
          return null;
        }
        let infoMarketStatus;
        const todayDate = moment();
        const dateMarketNotStarted = moment(todayDate).isBefore(market.periodStart);
        const dateMarketExpired = moment(todayDate).isAfter(market.periodEnd);
        const label = `${t('global-market-subscribed')} ${t(`country-${market.country}`)} / ${t(
          `global-${market.energy}`,
        )}`;

        if (dateMarketNotStarted) {
          infoMarketStatus = t('popover-market-not-started-yet');
        } else if (dateMarketExpired) infoMarketStatus = t('popover-market-expired');

        return (
          <Space direction="vertical" size={0}>
            <Title isOutdated={dateMarketNotStarted || dateMarketExpired} key={label}>
              {label} {infoMarketStatus}
            </Title>
            <SubTitle>
              {t('global-from')} {moment(market.periodStart).format('DD/MM/YYYY')} {t('global-to')}{' '}
              {moment(market.periodEnd).format('DD/MM/YYYY')}
            </SubTitle>
            <TextRegular>
              {t('number-contracts')} {market.contracts}
            </TextRegular>
          </Space>
        );
      });
    } else if (isClient(role) && !isMultiAccess(userInfo)) {
      if (isFreemium(userInfo)) {
        freemium = <Title>{t('mode-freemium')}</Title>;
      } else if (products) {
        membershipApp = (
          <Space direction="vertical" size={0}>
            <Title isOutdated={!products.follow.subscriptionValid}>
              {products.follow.subscriptionValid ? t('membership-app') : t('membership-app-expired')}
            </Title>
            <SubTitle>
              {t('global-from')} {moment(products.follow.subscriptionStart).format('DD/MM/YYYY')} {t('global-to')}{' '}
              {moment(products.follow.subscriptionEnd).format('DD/MM/YYYY')}
            </SubTitle>
          </Space>
        );

        contractNumber = (
          <Space direction="vertical" size={0}>
            <Title>{t('number-contracts')}</Title>
            <SubTitle>{products.follow.contracts}</SubTitle>
          </Space>
        );
      }
    }

    return (
      <Space direction="vertical" size={isMobile ? 'small' : 'large'}>
        <div>
          <LastName>
            {lastName} {firstName}
          </LastName>
          <Society>{company}</Society>
        </div>
        {clientMarkets}
        {freemium}
        {membershipApp}
        {contractNumber}
      </Space>
    );
  }, [company, firstName, lastName, products, role, t, userInfo]);

  const content = React.useMemo(() => {
    const managerYemJWT = getCookie('manager_yem_jwt');

    const multiAccessButton = (
      <>
        {!isLoginCredentialsNeeded && (
          <BoldButton onClick={() => redirectToPortal(Navigation.UPDATE_PASSWORD).go()}>
            {t('menu-update-password')}
          </BoldButton>
        )}
        {isMultiAccess(userInfo) && isClient(userInfo.role) && (
          <>
            <BoldButton onClick={() => redirectToPortal(Navigation.TERM_CGU).go()}>
              {t('menu-term-condition-cgu')}
            </BoldButton>
            <BoldButton onClick={() => redirectToPortal(Navigation.TERM_RGPD).go()}>
              {t('menu-term-condition-rgpd')}
            </BoldButton>
          </>
        )}
      </>
    );

    return (
      <div style={{ marginTop: isMobile ? '0' : '16px' }}>
        <TextColored>
          <Space direction="vertical">
            {multiAccessButton}
            {admin && <BoldButton onClick={handleAdminPortal}>Admin Portal</BoldButton>}
            {admin && <BoldButton to={`/${Navigation.ADMIN_CONTRACTS}`}>Admin YOP</BoldButton>}
            {managerYemJWT && (
              <BoldButton onClick={switchToManagerAccount}>{t('menu-support-switch-manager')}</BoldButton>
            )}
            <BoldButton onClick={handleLogout}>{t('menu-support-log-out')}</BoldButton>
            {(admin || demo) && (
              <Space align="center">
                <div>{t('menu-support-travel')}</div>
                <Switch
                  checked={isTimeTravelActive || showDemo}
                  disabled={isTimeTravelActive}
                  onChange={handleChangeSwitch}
                />
              </Space>
            )}
          </Space>
        </TextColored>
      </div>
    );
  }, [
    getCookie,
    isLoginCredentialsNeeded,
    t,
    userInfo,
    admin,
    switchToManagerAccount,
    handleLogout,
    demo,
    isTimeTravelActive,
    showDemo,
    handleChangeSwitch,
    handleAdminPortal,
    redirectToPortal,
  ]);

  if (isMobile) {
    return (
      <>
        <WrapperEnterprise ref={buttonRef} onClick={() => !showPopover && setShowPopover(true)}>
          <AccountIcon color={themeContext.colors.headerSmallIcon} />
        </WrapperEnterprise>
        {showPopover && (
          <ModalSimple
            content={
              <Space direction="vertical" size="middle">
                {title}
                <Hr />
                {content}
              </Space>
            }
            size="large"
            onCancel={() => setShowPopover(false)}
          />
        )}{' '}
      </>
    );
  }

  return (
    <div style={{ zIndex: '10', position: 'relative' }}>
      <PopoverAnt
        content={content}
        getPopupContainer={() => buttonRef.current}
        open={showPopover}
        placement="bottomRight"
        title={title}
        trigger="click"
        onOpenChange={handleVisibleChange}
      >
        <WrapperEnterprise ref={buttonRef} onClick={() => !showPopover && setShowPopover(true)}>
          <AccountIcon color={themeContext.colors.headerIcon} />
        </WrapperEnterprise>
      </PopoverAnt>
    </div>
  );
};

export default Popover;
