import styled from 'styled-components';

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TitleMiddlePage = styled.div`
  font-size: 45px;
  font-weight: bold;
`;

export const ContractName = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: black;
`;

export const DetailContract = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: black;

  div {
    font-weight: normal;
  }
`;

export const BottomInfo = styled.div`
  font-size: 1rem;
`;
