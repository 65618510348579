import moment from 'moment';

import { CONTRACT_FIXATION_GRANULARITY } from '../constants';

const CONTACT_PERIOD_VALIDATION_ERROR = {
  periodTooShort: 'form-contract-delivery-error-periodTooShort',
  mustStartOnQuarter: 'form-contract-delivery-error-mustStartOnQuarter',
  mustEndOnQuarterEnd: 'form-contract-delivery-error-mustEndOnQuarterEnd',
  mustBeMultiple6months: 'form-contract-delivery-error-mustBeMultiple6months',
  mustBeMultiple12months: 'form-contract-delivery-error-mustBeMultiple12months',
};

/**
 * Test if the start and end of contract date are valid
 * In case of error, return an error tag
 *
 * @param contractStart
 * @param contractEnd
 * @param fixationGranularity
 * @returns {{valid: boolean, error: string}}
 */
export function initialContractValidatePeriod(contractStart, contractEnd, fixationGranularity) {
  // Init
  let valid = true;
  let error = '';

  // Define start and end dates
  const startMonth = moment(contractStart).startOf('month');
  const endMonth = moment(contractEnd).startOf('month');

  // Determine the contract period, in months
  const contractMonths = moment(endMonth).add(1, 'month').diff(startMonth, 'months');

  if (valid) {
    switch (fixationGranularity) {
      // Test for FIXATION_GRANULARITY_MONTH
      case CONTRACT_FIXATION_GRANULARITY.MONTH:
        break;

      // Test for FIXATION_GRANULARITY_QUARTER
      //  - start date must begin on a quarter
      //  - length must be a multiple of a quarter
      case CONTRACT_FIXATION_GRANULARITY.QUARTER:
        // Test for start of contract -> must start on a quarter
        if (startMonth.month() % 3 !== 0) {
          valid = false;
          error = CONTACT_PERIOD_VALIDATION_ERROR.mustStartOnQuarter;
          break;
        }

        // Test that length is a multiple of quarters
        if (contractMonths % 3 !== 0) {
          valid = false;
          error = CONTACT_PERIOD_VALIDATION_ERROR.mustEndOnQuarterEnd;
          break;
        }

        break;

      // Test for FIXATION_GRANULARITY_YEAR
      //  - length must be a multiple of a year
      case CONTRACT_FIXATION_GRANULARITY.YEAR:
        // Test that length is a multiple of years
        if (contractMonths < 12) {
          valid = false;
          error = CONTACT_PERIOD_VALIDATION_ERROR.mustBeMultiple12months;
          break;
        }
        break;

      // Test for FIXATION_GRANULARITY_SEMESTER
      //  - length must be a multiple of 6 months
      case CONTRACT_FIXATION_GRANULARITY.SEMESTER:
        // Test that length is a multiple of 6 months
        if (contractMonths % 6 !== 0) {
          valid = false;
          error = CONTACT_PERIOD_VALIDATION_ERROR.mustBeMultiple6months;
          break;
        }
        break;

      default:
    }
  }

  return {
    valid,
    error,
  };
}
