import React from 'react';
import styled, { css } from 'styled-components';

import { TextRegular } from '../../../../../style/utils';

const ListItemTemplate = ({ label, className }) => <TextRegular className={className}>{label}</TextRegular>;
export default styled(ListItemTemplate)`
  ${({ color }) => css`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 6px;
    border-bottom: 1px solid rgb(199, 201, 203);
    print-color-adjust: exact;
    font-size: 0.875rem;
    break-inside: avoid;

    &::before {
      border-radius: 40px;
      ${color && "content: ''"};
      display: flex;
      height: 9px;
      width: 9px;
      background: ${color};
      margin-right: 8px;
      print-color-adjust: exact;
    }
  `}
`;
