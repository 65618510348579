import { Col, Row, Space } from 'antd';
import React from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { ThemeContext } from 'styled-components';

import Button from '../../../../components/Button';
import ButtonLink from '../../../../components/ButtonLink';
import Hr from '../../../../components/Hr';
import KamMultiple from '../../../../components/icons/KamMultipleIcon';
import Input from '../../../../components/Input';
import ModalDeleteGroup from '../../../../components/Modals/ModalDeleteGroup';
import WrapperWhiteBox from '../../../../components/WrapperWhiteBox';
import { useCreateGroup } from '../../../../endpoints/groups/useCreateGroup';
import { useGetGroupDetails } from '../../../../endpoints/groups/useGetGroupDetails';
import { useGetGroupKam } from '../../../../endpoints/groups/useGetGroupKam';
import { Navigation } from '../../../../navigation';
import history from '../../../../routes/history';
import { Label, TextRegular } from '../../../../style/utils';
import GroupItem from '../GroupItem';

const INITIAL_SELECT_VALUE = '-';

const GroupDetails = () => {
  const themeContext = React.useContext(ThemeContext);
  const queryClient = useQueryClient();
  const allGroups = queryClient.getQueryData('group-list');
  const [showModalDelete, setShowModalDelete] = React.useState(false);
  const [resultValue, setResultValue] = React.useState([]);
  const [canSave, setCanSave] = React.useState(false);
  const [t] = useTranslation();
  const { id } = useParams();
  const [member, setMember] = React.useState(INITIAL_SELECT_VALUE);
  const { register, control, handleSubmit, errors, reset } = useForm({
    defaultValues: {
      name: '',
      kamIds: [],
    },
  });
  const { fields } = useFieldArray({ control, name: 'kamIds' });

  const { data: groupDetails, isLoading: isGroupDetailsLoading } = useGetGroupDetails(id);
  const { data: allKamsList, isLoading: isKamListLoading } = useGetGroupKam();
  const { mutate: updateGroup, isLoading: isUpdateGroupLoading } = useCreateGroup(false, id);

  const allGroupsName = React.useMemo(() => allGroups.map((group) => group.name), [allGroups]);

  React.useEffect(() => {
    if (resultValue.length === 0 && fields.length > 0 && allKamsList) {
      setResultValue([
        ...fields
          .map((field) => allKamsList.find((kam) => kam.id === field.kamId))
          .sort((a, b) => a.name.localeCompare(b.name)),
      ]);
    }
  }, [allKamsList, fields, resultValue]);

  React.useEffect(() => {
    register(
      { name: 'name' },
      {
        required: true,
        validate: (value) => {
          const valueTrimmed = value.trim();

          if (allGroupsName.includes(valueTrimmed) && valueTrimmed !== groupDetails.name) {
            return 'error-name-group-already-taken';
          }
          return undefined;
        },
      },
    );
    register({ name: 'kamIds' });
  }, [allGroupsName, groupDetails?.name, register]);

  React.useEffect(() => {
    if (groupDetails) {
      reset({
        name: groupDetails.name,
        kamIds: groupDetails.kamIds.map((kamId) => ({ kamId })),
      });
    }
  }, [groupDetails, reset]);

  const listKam = React.useMemo(() => {
    if (!allKamsList) {
      return [];
    }

    return allKamsList
      .filter((item) => resultValue.findIndex((f) => f.id === item.id) === -1)
      .map((item) => ({
        value: item.id,
        label: item.name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [allKamsList, resultValue]);

  const handleAdd = () => {
    setCanSave(true);
    setResultValue(
      [...resultValue, allKamsList.find((kam) => kam.id === member)].sort((a, b) => a.name.localeCompare(b.name)),
    );
    setMember(INITIAL_SELECT_VALUE);
  };

  const handleChangeMember = React.useCallback((member) => {
    setMember(member);
  }, []);

  const handleDeleteMember = React.useCallback(
    (id) => {
      setCanSave(true);
      setResultValue(resultValue.filter((item) => item.id !== id));
    },
    [resultValue],
  );

  const onSubmit = React.useCallback(
    (data) => {
      updateGroup(
        { name: data.name.trim(), kamIds: resultValue.map((f) => f.id) },
        {
          onSuccess() {
            history.push(Navigation.GROUP_LIST);
          },
        },
      );
    },
    [resultValue, updateGroup],
  );

  if (isGroupDetailsLoading || isKamListLoading) {
    return null;
  }
  return (
    <>
      <WrapperWhiteBox
        icon={<KamMultiple />}
        preTitle={t('multi-access-group-details-pre-title')}
        title={groupDetails.name}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Space direction="vertical" size="large">
            <TextRegular>{t('multi-access-group-details-description')}</TextRegular>
            <Hr />
            <Space direction="vertical" size="large">
              <Row align="top" gutter={[40, 16]}>
                <Col md={8} xs={24}>
                  <Controller
                    as={Input.Text}
                    control={control}
                    error={errors.name}
                    label={t('multi-access-group-details-input-name')}
                    name="name"
                  />
                </Col>
                <Col md={16} xs={24}>
                  <Row align="bottom" gutter={[16, 16]}>
                    <Col md={12} xs={24}>
                      <Input.Select
                        items={listKam}
                        label={t('multi-access-group-details-input-members')}
                        loading={isKamListLoading}
                        value={member}
                        onChange={handleChangeMember}
                      />
                    </Col>
                    <Col>
                      <Button disabled={member === INITIAL_SELECT_VALUE} onClick={handleAdd}>
                        {t('global-add')}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Hr noBottom noTop />

              {fields.length !== 0 && (
                <>
                  <Label>{t('multi-access-group-list-members')}</Label>

                  <Row gutter={24}>
                    {resultValue &&
                      resultValue.map((member, index) => (
                        <Col key={member.id} md={12} xs={24}>
                          <GroupItem id={member.id} name={member.name} onDelete={() => handleDeleteMember(member.id)} />
                          <input
                            defaultValue={member.id}
                            name={`kamIds[${index}].kamId`}
                            ref={register()}
                            type="hidden"
                          />
                        </Col>
                      ))}
                  </Row>

                  <Hr noBottom noTop />
                </>
              )}
              <Row align="middle" gutter={[0, 16]} justify="space-between">
                <Col>
                  <Space size="middle">
                    <Button variant="secondary" onClick={() => history.goBack()}>
                      {t('global-close')}
                    </Button>
                    <Button disabled={!canSave} isLoading={isUpdateGroupLoading} type="submit">
                      {t('multi-access-group-create-valid')}
                    </Button>
                  </Space>
                </Col>
                <Col>
                  <ButtonLink color={themeContext.colors.orange} onClick={() => setShowModalDelete(true)}>
                    {t('multi-access-group-details-delete-group')}
                  </ButtonLink>
                </Col>
              </Row>
            </Space>
          </Space>
        </form>
      </WrapperWhiteBox>

      {showModalDelete && (
        <ModalDeleteGroup groupId={id} title={groupDetails.name} onClose={() => setShowModalDelete(false)} />
      )}
    </>
  );
};

export default GroupDetails;
