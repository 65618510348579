import moment from 'moment';

export const hasSomeClientMarketActivated = (clientMarkets) => clientMarkets?.some((market) => market.enabled);

export const dateMarketIncludeToday = (clientMarkets = []) => {
  const today = moment();

  return clientMarkets
    .filter((market) => market.enabled)
    .some((market) => {
      const periodStart = market.periodStart || market.period[0];
      const periodEnd = market.periodEnd || market.period[1];
      return moment(today).isSameOrAfter(periodStart) && moment(today).isSameOrBefore(moment(periodEnd).add(1, 'day'));
    });
};
