import { Space, Table as TableAnt } from 'antd';
import { TableProps } from 'antd/lib/table';
import { isEmpty } from 'lodash';
import React from 'react';
import styled, { css } from 'styled-components';

import useDesktop from '../../hooks/useDesktop';
import { transformErrorsToList } from '../../utils/errors';

type Props = {
  className?: string;
  errors?: object;
  name?: string;
  extraColumn?: boolean;
  noBgHover?: boolean;
  isClickable?: boolean;
  omittedErrorKeys?: string[];
} & TableProps<any>;

const Table = ({ className, name, extraColumn, errors, omittedErrorKeys, ...tableProps }: Props) => {
  const isDesktop = useDesktop();

  // Fix from antd to fix width issue
  // Add en empty column to preserve width
  const columns = React.useMemo(() => {
    let realColumns = [] as any;

    if (tableProps.columns) {
      realColumns = [...tableProps.columns];

      if (!isEmpty(tableProps.columns[tableProps.columns.length - 1]) && extraColumn) {
        realColumns.push({});
      }
    }

    return realColumns;
  }, [tableProps.columns, extraColumn]);

  return (
    <Space className={className} direction="vertical">
      <TableAnt
        data-testid={`table-${name}`}
        pagination={{ hideOnSinglePage: true }}
        size={isDesktop ? 'middle' : 'small'}
        {...tableProps}
        columns={columns}
        scroll={{ x: 'auto' }}
      />

      {transformErrorsToList(errors, omittedErrorKeys)}
    </Space>
  );
};

export const TableBoldText = styled(Table)<Props>`
  ${({ theme: { colors, fontSize } }) => css`
    .ant-table-thead > tr {
      & > th {
        border-bottom: unset;
        font-weight: 600;
        color: black;
        text-transform: unset;
        background: transparent;

        &:before {
          content: none !important;
        }
      }
    }

    .ant-table-tbody > tr.ant-table-row:hover > td {
      background: unset;
    }

    .ant-table-tbody > tr {
      & > td {
        border-bottom: unset;
        font-size: ${fontSize.regular};
        color: ${colors.grayDark};
      }
    }
  `}
`;

const styledTable = styled(Table)<Props>`
  ${({ theme: { colors, fontSize }, isClickable = true, noBgHover }) => css`
    width: 100%;

    .ant-table-tbody > tr.ant-table-row:hover > td {
      background: ${noBgHover ? 'unset' : `${colors.baseColor}17`};
      cursor: ${isClickable ? 'pointer' : 'default'};
    }

    .ant-table-thead > tr {
      font-size: ${fontSize.extraSmall};
      & > th {
        color: ${colors.grayDark};
        white-space: nowrap;
        background: unset;
        border-bottom: 1px solid ${colors.gray};
        text-transform: uppercase;

        &:before {
          content: none !important;
        }
      }
    }

    .ant-table-tbody > tr {
      font-size: ${fontSize.regular};
      & > td {
        color: ${colors.grayDark};
        white-space: nowrap;
        border-bottom: 1px solid ${colors.gray};
        vertical-align: middle;
      }
      & > td.ant-table-column-sort {
        background: unset;
      }
    }
  `}
`;

export default styledTable;
