const fontSize = {
  extraSmall: '12px',
  small: '14px',
  regular: '15px',
  extraSmallHeader: '17px',
  smallHeader: '20px',
  header: '24px',
  bigHeader: '28px',
  key: '42px',
};

export default fontSize;
