import React from 'react';

function HistoricCalendarMonthIcon() {
  return (
    <svg
      style={{ display: 'block' }}
      xmlns="http://www.w3.org/2000/svg"
      width="16.221"
      height="15.975"
      viewBox="0 0 16.221 15.975"
    >
      <defs>
        <clipPath id="clip-path">
          <path fill="#202124" d="M0 0H16.221V15.975H0z" data-name="Rectangle 912" />
        </clipPath>
      </defs>
      <g clipPath="url(#clip-path)" data-name="Groupe 1539">
        <path
          fill="#202124"
          d="M15.5 3.562h-1.86a.009.009 0 01-.008-.008V.893A.876.876 0 0012.825 0a.84.84 0 00-.871.839v2.714a.008.008 0 01-.008.008h-7.48a.009.009 0 01-.008-.008V.838A.84.84 0 003.587 0a.876.876 0 00-.808.893v2.66a.008.008 0 01-.008.008H.725A.678.678 0 000 4.184v10.829a1.048 1.048 0 001.12.961h13.987a1.044 1.044 0 001.114-.961V4.184a.676.676 0 00-.72-.622M5.13 13.869H2.655a.445.445 0 01-.444-.445V11.9a.445.445 0 01.444-.445H5.13a.445.445 0 01.444.445v1.52a.445.445 0 01-.444.445m.009-3.475H2.646a.436.436 0 01-.436-.436V8.416a.436.436 0 01.436-.435h2.493a.436.436 0 01.436.435v1.542a.436.436 0 01-.436.436m4.506 3.475H7.056a.388.388 0 01-.388-.388v-1.633a.388.388 0 01.388-.388h2.589a.388.388 0 01.388.388v1.633a.388.388 0 01-.388.388m-.021-3.475H7.077a.409.409 0 01-.409-.409v-1.6a.408.408 0 01.409-.408h2.547a.408.408 0 01.408.408v1.6a.409.409 0 01-.408.409m4.473 0h-2.578a.393.393 0 01-.393-.394V8.373a.393.393 0 01.393-.393H14.1a.393.393 0 01.393.393V10a.393.393 0 01-.393.393"
          data-name="Tracé 247"
          transform="translate(0 .001)"
        />
      </g>
    </svg>
  );
}

export default HistoricCalendarMonthIcon;
