import React from 'react';
import RpiPhoneInput from 'react-phone-input-2';
import styled, { css } from 'styled-components';

const getCountryCode = (country) => {
  if (typeof country === 'string') {
    if (country === 'en') return 'gb';

    return country;
  }
};

const Phone = React.forwardRef(({ className, country, ...props }, ref) => (
  <div className={className} ref={ref}>
    <RpiPhoneInput country={getCountryCode(country)} data-testid="phone-input" inputClass="input-class" {...props} />
  </div>
));

Phone.displayName = 'Phone';

export default styled(Phone)`
  ${({ disabled, theme: { fontSize, colors }, error }) => css`
    .input-class {
      width: inherit;
      color: ${disabled ? 'rgba(0, 0, 0, 0.25)' : colors.grayDark};
      font-size: ${fontSize.regular};

      ${error && 'border: 1px solid red'};

      &:focus {
        ${error && 'box-shadow: 0 0 0 2px #f1d8d8'};
      }
    }

    .react-tel-input .flag-dropdown {
      ${error && 'border-top: 1px solid red'};
      ${error && 'border-left: 1px solid red'};
      ${error && 'border-bottom: 1px solid red'};

      &:focus {
        border: 1px solid blue;
      }
    }
  `}
`;
