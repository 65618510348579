import { InputProps } from 'antd';
import omit from 'lodash/omit';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { callAll } from '../../../utils';
import { InputPasswordStyled, InputStyled } from './Text.styled';

type Props = InputProps & {
  precision?: number;
  customOnChange?: () => void;
  error: boolean;
};

const Text: FC<Props> = React.forwardRef(({ className, ...props }, ref) =>
  props?.type === 'password' ? (
    <InputPasswordStyled {...omit(props, ['error'])} className={className} ref={ref} />
  ) : (
    <InputStyled
      {...omit(props, ['error'])}
      className={className}
      ref={ref}
      onChange={(event) => {
        const { value } = event.target;

        if (props.precision && /[,.]/.test(value) && value.split(/[,.]/)[1].length === props.precision + 1) {
          return;
        }

        callAll(props.onChange, props.customOnChange)(event);
      }}
    />
  ),
);

Text.displayName = 'Text';

export default styled(Text)`
  ${({ error }) => css`
    ${error && 'border: 1px solid red'};

    &&:focus {
      ${error && 'box-shadow: 0 0 0 2px #f1d8d8'};
      ${error && 'border-color: red'};
    }
    &&:hover {
      ${error && 'border-color: red'};
    }
  `}
`;
