import { useQuery } from 'react-query';

import { useApi } from '../../context/ApiContext';

export const useGetProductDetails = (contractId, periodStart) => {
  const client = useApi();

  return useQuery(['product-details', contractId, periodStart], () =>
    client.get(`/contracts/product/detail/${contractId}/${periodStart}`).then((res) => res.data),
  );
};
