import styled, { css } from 'styled-components';

export const WrapperScroll = styled.div`
  ${({ theme: { breakpoints } }) => css`
    min-height: 100%;
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding: 0 25px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    @media (min-width: ${breakpoints.lg}) {
      width: 990px;
      padding: 0;
    }
  `}
`;
