import { Space } from 'antd';
import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetConsumption } from '../../../endpoints/objectives/useGetConsumption';
import { useUpdateConsumption } from '../../../endpoints/objectives/useUpdateConsumption';
// eslint-disable-next-line max-len
import ObjectivesIndexTable from '../../../screens/Objectives/components/ObjectivesConsumption/components/ObjectivesIndexTable';
import { TextRegular } from '../../../style/utils';
import { Contract } from '../../../ts/types/contract';
import Button from '../../Button';
import Modal from '../../Modal';
import SafeHTMLTranslate from '../../SafeHTMLTranslate';

type Props = {
  contract: Contract;
  onCancel: () => void;
};

const ModalChangeConsumption: FC<Props> = ({ contract, onCancel }) => {
  const [t] = useTranslation();
  const { data } = useGetConsumption(contract.id);
  const { mutate: updateConsumption, isLoading } = useUpdateConsumption(contract.id);

  const updateStateForm = useCallback(
    (values) => {
      updateConsumption(values, {
        onSuccess() {
          onCancel();
        },
      });
    },
    [onCancel, updateConsumption],
  );

  const footer = useMemo(() => {
    return (
      <Space>
        <Button variant="secondary" onClick={onCancel}>
          {t('global-cancel')}
        </Button>
        <Button isLoading={isLoading} type="submit">
          {t('global-valid')}
        </Button>
      </Space>
    );
  }, [isLoading, onCancel, t]);

  if (!data) {
    return null;
  }

  return (
    <Modal preTitle={contract.name} size="middle" title={t('modal-change-consumption-title')} onCancel={onCancel}>
      <Space direction="vertical" size="large">
        <TextRegular>
          <SafeHTMLTranslate
            template="modal-change-consumption-description-info"
            variables={{ unit: t(`global-energy-${contract.energyUnit}`) }}
          />
        </TextRegular>

        <ObjectivesIndexTable
          energyUnit={data.contract.energyUnit}
          fixationGranularity={contract.fixationGranularity}
          footer={footer}
          indexCount={contract.indexCount}
          indexLabels={contract.indexLabels}
          periods={data.consumption.amountsByPeriod}
          updateStateForm={updateStateForm}
        />
      </Space>
    </Modal>
  );
};

export default ModalChangeConsumption;
