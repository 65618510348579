import * as React from 'react';

const France = (props) => (
  <svg height={15.1} style={{ display: 'block' }} width={23.15} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M0 0h7.7v15H0z"
      style={{
        fill: '#234083',
      }}
      transform="translate(0 .001)"
    />
    <path
      d="M7.7 0h7.7v15H7.7z"
      style={{
        fill: '#fff',
      }}
      transform="translate(0 .001)"
    />
    <path
      d="M15.4 0h7.7v15h-7.7z"
      style={{
        fill: '#e21b22',
      }}
      transform="translate(0 .001)"
    />
  </svg>
);

export default France;
