import { useQueryClient } from 'react-query';

export default ({ location }) => {
  const queryClient = useQueryClient();
  const contractId = location.pathname.split('/')[2];
  const allContract = queryClient.getQueryData('get-contracts');
  const contract = allContract.find((c) => c.id === contractId);

  return contract?.name || '';
};
