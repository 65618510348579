import { Col, Row, Space } from 'antd';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Collapse from '../../../../components/Collapse';
import getFormattedPeriodDate from '../../../../components/FormatPeriodDate/getFormattedPeriodDate';
import { useGetStrategy } from '../../../../endpoints/contracts/useGetStrategy';
import { Label, TextRegular } from '../../../../style/utils';

const OptimizationPeriodTemplate = ({ optimizationSettings }) => {
  const [t] = useTranslation();
  const { data } = useGetStrategy(optimizationSettings.contract);

  const informationContract = React.useMemo(() => {
    if (!optimizationSettings) return [];
    if (!data) return [];
    const infos = [];

    infos.push({
      title: `${t('global-period')}`,
      data: [
        `${getFormattedPeriodDate({
          date: optimizationSettings.optimizationPeriodDetails.periodStart,
          granularity: data.contract.fixationGranularity,
          format: 'MMM YYYY',
        })}`,
      ],
    });

    infos.push({
      title: `${t('global-optimization_period')}`,
      data: [
        `${t('contract-detail-start')} ${moment(
          optimizationSettings.optimizationPeriodDetails.optimizationPeriodStart,
        ).format('DD/MM/YYYY')}`,
        `${t('contract-detail-end')} ${moment(
          optimizationSettings.optimizationPeriodDetails.optimizationPeriodEnd,
        ).format('DD/MM/YYYY')}`,
      ],
    });

    return infos;
  }, [data, optimizationSettings, t]);

  return (
    <>
      <Collapse
        collapseProps={{
          defaultActiveKey: ['0'],
          collapsible: 'disabled',
        }}
        panelProps={{
          key: 0,
          header: t('contract-detail-optimization-period'),
          showArrow: false,
        }}
      >
        <Row className="rowHistoric" gutter={[18, 25]}>
          {informationContract.map((item) => (
            <Col key={item.title} sm={6} xs={24}>
              <Space direction="vertical">
                <Label>{item.title}</Label>
                <Space direction="vertical" size={0}>
                  {item.data.map((info) => {
                    if (typeof info === 'string') {
                      return <TextRegular key={info}>{info}</TextRegular>;
                    }

                    return info;
                  })}
                </Space>
              </Space>
            </Col>
          ))}
        </Row>
        <br />
      </Collapse>
    </>
  );
};

export default OptimizationPeriodTemplate;
