import { Col, Row, Space } from 'antd';
import React, { useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import useGetPreviousUrl from '../../hooks/useGetPreviousUrl';
import { HeaderOne, HeaderThree } from '../../style/utils';
import ArrowBack from '../icons/ArrowBack';
import { ExtraHeader } from './WrapperWhiteBox.styled';

const WrapperWhiteBox = ({
  className,
  children,
  icon,
  preTitle,
  title,
  extra,
  disabled,
  noFlexAuto,
  extraHeaderTitle,
  extraHeaderIcon,
}) => {
  const previousUrl = useGetPreviousUrl();
  const style = useMemo(() => {
    const defaultStyle = {
      width: '100%',
    };

    if (extraHeaderTitle) {
      defaultStyle.boxShadow = '0 0 10px rgba(0, 0, 0, 0.16)';
      defaultStyle.borderTopLeftRadius = '6px';
      defaultStyle.borderTopRightRadius = '6px';
    }
    return defaultStyle;
  }, [extraHeaderTitle]);

  return (
    <div style={style}>
      {extraHeaderTitle && (
        <ExtraHeader>
          <Link to={previousUrl}>
            <Space size="middle">
              <ArrowBack color="#5E696F" />
              {extraHeaderIcon}
              <HeaderThree>{extraHeaderTitle}</HeaderThree>
            </Space>
          </Link>
        </ExtraHeader>
      )}
      <Space className={className} direction="vertical" size="large">
        {(preTitle || icon || title) && (
          <Space direction="vertical" size="large">
            {preTitle}
            {(icon || title) && (
              <Row align="middle" className="header" gutter={[16, 16]} justify="space-between">
                <Col style={{ width: '370px' }}>
                  <Space size="middle">
                    {icon}
                    {title && <HeaderOne style={{ lineHeight: '1.4em' }}>{title}</HeaderOne>}
                  </Space>
                </Col>
                {!disabled && <Col flex={isMobile && !noFlexAuto ? 'auto' : 'none'}>{extra}</Col>}
              </Row>
            )}
          </Space>
        )}

        {!disabled && children}
      </Space>
    </div>
  );
};

export default styled(WrapperWhiteBox)`
  ${({ theme: { colors }, disabled, extraHeaderTitle }) => css`
    background-color: ${colors.white};
    ${!extraHeaderTitle && 'box-shadow: 0 0 10px rgba(0, 0, 0, 0.16)'};
    ${!extraHeaderTitle && 'border-top-right-radius: 6px'};
    ${!extraHeaderTitle && 'border-top-left-radius: 6px'};
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    width: 100%;
    position: relative;
    padding: 1.8rem ${isMobile ? '0.8rem' : '2.8rem'};

    .header {
      opacity: ${disabled ? '25%' : '100%'};
    }
  `}
`;
