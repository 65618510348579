import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Container, Note, Title } from './NoteHistoricTemplate.styled';

function textAreaAdjust(element) {
  element.style.height = '10px';
  element.style.height = `${element.scrollHeight + 1}px`;
}

const NoteHistoric = ({ noteValue = '' }) => {
  const note = React.useRef(null);
  const [t] = useTranslation();

  React.useEffect(() => {
    if (note.current) {
      note.current.value = noteValue;
      textAreaAdjust(note.current);
    }
  }, [noteValue]);

  return (
    <div>
      <Title>{t('steps-notes')}</Title>
      <Container>
        <Note
          disabled
          defaultValue={noteValue}
          placeholder={t('global-no-notes')}
          ref={note}
          onChange={() => {
            textAreaAdjust(note.current);
          }}
        />
      </Container>
    </div>
  );
};

export default styled(NoteHistoric)``;
