import { changeAntdTheme } from 'mini-dynamic-antd-theme';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { ThemeProvider } from 'styled-components';

import { useGetTenantInfo } from '../endpoints/user/useGetTenantInfo';
import theme, { overrideTheme } from '../style/theme';

const TenantContext = createContext();

const regex = /optimization.energy$/;

const TenantProvider = ({ children }) => {
  const [customTheme, setCustomTheme] = useState(theme);
  const [environmentVariables, setEnvironmentVariables] = useState(process.env);
  const [favicon, setFavicon] = useState();
  const [isDomainMA, setIsDomainMA] = useState(false);
  const [isLoginCredentialsNeeded, setIsLoginCredentialsNeeded] = useState(false);
  const [tenantName, setTenantName] = useState();
  const [siteTitle, setSiteTitle] = useState();
  const [marketType, setmarketType] = useState();

  const { mutate: getTenantInfo, isSuccess: getTenantInfoSucceed } = useGetTenantInfo();

  const domain = React.useMemo(() => {
    // let domainUrl = 'eni.yop.dev.optimization.energy';
    // let domainUrl = window.location.hostname;
    // For testing in localhost we can define a MA domain name
    let domainUrl =
      window.location.hostname === 'localhost' && process.env.REACT_APP_LOCALHOST_FORCED_DOMAIN
        ? process.env.REACT_APP_LOCALHOST_FORCED_DOMAIN
        : window.location.hostname;

    if (process.env.NODE_ENV === 'test') domainUrl = '';

    return domainUrl;
  }, []);

  const shouldGetTenantInfo = useMemo(() => domain.match(regex), [domain]);

  useEffect(() => {
    if (!shouldGetTenantInfo) {
      changeAntdTheme(theme.colors.baseColor);
      return;
    }

    getTenantInfo(
      { domain },
      {
        onSuccess(data) {
          setEnvironmentVariables((variables) => ({
            ...variables,
            ...data.env,
          }));
          setIsDomainMA(true);
          setIsLoginCredentialsNeeded(!!data?.loginWithCredentialsEnabled);
          setFavicon(data.favicon);
          setSiteTitle(data.siteTitle);
          setTenantName(data.tenant);
          setmarketType(data.fixationMarket);
          const { dashboardLogo, ...colors } = data.theme;

          setCustomTheme(() => overrideTheme(dashboardLogo, colors));
          changeAntdTheme(data.theme.baseColor);
        },
      },
    );
  }, [domain, getTenantInfo, shouldGetTenantInfo]);

  if (!getTenantInfoSucceed && shouldGetTenantInfo) {
    return null;
  }

  return (
    <ThemeProvider theme={customTheme}>
      <TenantContext.Provider
        value={{
          env: environmentVariables,
          tenantName,
          favicon,
          isDomainMA,
          isLoginCredentialsNeeded,
          siteTitle,
          marketType,
        }}
      >
        {children}
      </TenantContext.Provider>
    </ThemeProvider>
  );
};

function useTenant() {
  const context = useContext(TenantContext);
  if (context === undefined) {
    throw new Error('useTenant must be used within a TenantProvider');
  }
  return context;
}

export { TenantContext, TenantProvider, useTenant };
