import { useQuery } from 'react-query';

import { useApi } from '../../context/ApiContext';

export const useGetContractsProductCombined = (contractId) => {
  const client = useApi();

  return useQuery(['get-contracts-product-combined', contractId], () =>
    client.get(`/contracts/combinedProducts/${contractId}`).then((res) => res.data),
  );
};
