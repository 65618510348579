import startCase from 'lodash/startCase';
import moment from 'moment';

import { CONTRACT_FIXATION_GRANULARITY } from '../../constants';

const getFormattedPeriodDate = ({ isDesktop, granularity, date, periodMonths, format = 'MMMM YYYY' }) => {
  let firstDate;

  if (granularity === CONTRACT_FIXATION_GRANULARITY.MONTH || (periodMonths && periodMonths === 1)) {
    firstDate = startCase(moment(date).format(isDesktop ? 'MMMM YYYY' : 'MMM YYYY'));
  } else {
    firstDate = startCase(moment(date).format(format));
  }

  let secondDate;

  if (granularity === CONTRACT_FIXATION_GRANULARITY.QUARTER || (periodMonths && periodMonths !== 1)) {
    secondDate = moment(date)
      .add({ months: periodMonths - 1 || 2 })
      .format(format);
  }
  if (granularity === CONTRACT_FIXATION_GRANULARITY.YEAR) {
    secondDate = moment(date).add({ months: 11 }).format(format);
  }
  if (granularity === CONTRACT_FIXATION_GRANULARITY.SEMESTER) {
    secondDate = moment(date).add({ months: 5 }).format(format);
  }

  // General format if periodMonths is defined and greater than one month
  if (periodMonths > 1) {
    secondDate = moment(date)
      .add({ months: periodMonths - 1 })
      .format(format);
    return `${firstDate} - ${secondDate}`;
  }

  secondDate = startCase(secondDate);

  if (granularity === CONTRACT_FIXATION_GRANULARITY.MONTH || (periodMonths && periodMonths === 1)) {
    return firstDate;
  }
  if (granularity === CONTRACT_FIXATION_GRANULARITY.QUARTER || (periodMonths && periodMonths !== 1)) {
    return `${firstDate} - ${secondDate}`;
  }
  if ([CONTRACT_FIXATION_GRANULARITY.YEAR, CONTRACT_FIXATION_GRANULARITY.SEMESTER].includes(granularity)) {
    return `${firstDate} - ${secondDate}`;
  }
};

export default getFormattedPeriodDate;
