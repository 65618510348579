import React from 'react';
import { Helmet } from 'react-helmet';

import defaultFavicon from '../../assets/favicon.ico';
import { useTenant } from '../../context/TenantContext';

const ReactHelmet = () => {
  const { favicon, siteTitle } = useTenant();

  return (
    <Helmet>
      <link href={favicon || defaultFavicon} rel="icon" />
      <title>{siteTitle || 'YEM Optimization'}</title>
    </Helmet>
  );
};
export default ReactHelmet;
