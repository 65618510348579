import 'moment/locale/it';
import 'chartjs-adapter-moment';

import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Interaction,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js';
import startCase from 'lodash/startCase';
import moment from 'moment';
import React, { useContext, useMemo } from 'react';
import { Line as LineChartJs } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';

import Interpolate from '../../../../../../components/Chartjs/Line/plugin/Interpolate';
import blocsTooltip from '../../../../../../components/Chartjs/Line/Tooltip/blocsTooltip';

const Line = ({ data, fixationsUnit, hoveredDate, mwh, consumptionsUnit, showOptimizations, minBlock }) => {
  const [t] = useTranslation();
  const themeContext = useContext(ThemeContext);
  Interaction.modes.interpolate = Interpolate;
  const chart = React.useRef(null);
  const chartHovered = React.useRef(false);
  const iff = (condition, then, otherwise) => (condition ? then : otherwise);

  // Property name changed in backend
  data?.forEach((item) => {
    item.consumption = item.amount;
  });

  // Create new blocks by dividing blocks month by month
  const newFixations = [];
  data?.forEach((fixation) => {
    if (!fixation?.volumeByMonth?.length || !fixation?.totalVolume) {
      // Volumes not defined, use previous mode
      newFixations.push(fixation);
    } else {
      // Volumes defined
      // Create a new fixation pour each month
      fixation.volumeByMonth.forEach((monthVolume) => {
        const f = { ...fixation };
        f.id = `${fixation.id}-${monthVolume.periodStart}`;
        f.originalId = fixation.id;
        f.dateStart = monthVolume.periodStart;
        f.dateEnd = moment(f.dateStart).endOf('month').format('YYYY-MM-DD');
        f.consumption = monthVolume.volume;
        f.months = 1;
        newFixations.push(f);
      });
    }
  });
  data = newFixations;

  data = data.sort((a, b) => {
    if (a.type === b.type) {
      return 0;
    }
    if (a.type !== 'optimization') {
      return -1;
    }
    return 1;
  });

  const monthsData = useMemo(() => {
    const monthsData = [];
    let minDate;
    let maxDate;
    data?.forEach((item) => {
      let resultDate = [];
      if (item.dateStart < minDate || minDate === undefined) {
        minDate = item.dateStart;
      }
      if (item.dateEnd > maxDate || maxDate === undefined) {
        maxDate = item.dateEnd;
      }
      for (let i = item.dateStart; i <= item.dateEnd; i = moment(i).add(1, 'day').format('YYYY-MM-DD')) {
        resultDate = [...(resultDate || []), { consumption: item.consumption, day: moment(i).format('YYYY-MM-DD') }];
      }
      monthsData.push({
        date: item.date,
        type: item.type,
        data: resultDate,
        id: item.id,
      });
    });
    return { monthsData, minDate, maxDate };
  }, [data]);

  React.useEffect(() => {
    if (chart.current) {
      let colorBackground;
      chart.current.config._config.data.datasets.forEach((item) => {
        const blocs = data?.find((a) => a.id === item.label);
        if (blocs) {
          if (item.label.startsWith(hoveredDate)) {
            colorBackground =
              blocs.type === 'optimization' ? iff(blocs.display === true, '#ff62ff', '#f7f0f7') : '#5c95ff';
          } else {
            colorBackground =
              blocs.type === 'optimization' ? iff(blocs.display === true, '#fdcefd', '#f7f0f7') : '#9dbbf6';
          }
          item.backgroundColor = colorBackground;
        }
      });
      chart.current.update();

      // Hide tooltip when a block is selected from the optimizations/fixations list
      if (chart?.current?.canvas) {
        blocsTooltip({ chart: chart.current, tooltip: { opacity: 0 }, minBlock });
      }
    }
  }, [chart, data, hoveredDate, minBlock]);

  React.useEffect(() => {
    if (chart.current) {
      const colorBackground = showOptimizations ? '#ff62ff' : '#5c95ff';
      chart.current.config._config.options.scales.yConsumptions.ticks.color = colorBackground;
      chart.current.config._config.options.scales.yConsumptions.title.color = colorBackground;
      chart.current.update();
    }
  }, [chart, showOptimizations]);

  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale, Filler);

  Interaction.modes.myCustomModeBlocs = (chart, e, options, useFinalPosition) => {
    const newChart = { ...chart };
    newChart._sortedMetasets = chart._sortedMetasets.filter((meta) => meta.label === 'MWH-value');
    newChart.isPointInArea = chart.isPointInArea;
    newChart.getSortedVisibleDatasetMetas = () => {
      return newChart._sortedMetasets;
    };
    return Interaction.modes.point(newChart, e, options, useFinalPosition);
  };

  const options = React.useMemo(() => {
    if (!t) {
      return {};
    }
    return {
      showTooltips: false,
      responsive: true,
      datasetFill: true,
      draggable: true,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
          mode: 'myCustomModeBlocs',
          intersect: false,
          axis: 'xy',
          callbacks: {
            label: (context) => {
              const { y } = context.dataset.data[context.dataIndex];
              return `${y} ${t(`global-unit-${consumptionsUnit}`)}`;
            },
            title: (context) => {
              const { x } = context[0].dataset.data[context[0].dataIndex];
              return startCase(moment(x).format('MMMM YYYY'));
            },
          },
        },
      },
      onHover: (event, elements, chart) => {
        const yVal = chart.scales.yConsumptions.getValueForPixel(event.y);
        const index = chart.scales.x.getValueForPixel(event.x);
        const posibleBlocs = data?.filter((item) => {
          return (
            item.dateStart <= moment(index).format('YYYY-MM-DD') && item.dateEnd >= moment(index).format('YYYY-MM-DD')
          );
        });
        let totalConsumption = 0;
        let ActualBloc;
        for (let i = 0; i < posibleBlocs.length; i += 1) {
          totalConsumption += Number(posibleBlocs[i].consumption);
          if (totalConsumption > yVal) {
            ActualBloc = posibleBlocs[i];
            break;
          }
        }
        if (!ActualBloc?.date) {
          blocsTooltip({ chart, tooltip: { opacity: 0 }, minBlock });
        }
        if (ActualBloc?.date || chartHovered.current) {
          let colorBackground;
          chartHovered.current = false;
          let selectedTr;
          chart.config._config.data.datasets.forEach((item) => {
            const blocs = data?.find((a) => a.id === item.label);
            if (blocs) {
              const tableId = blocs.originalId;
              if (ActualBloc?.id === item.label) {
                chartHovered.current = true;
                const tr = document.querySelector(`tr[data-row-key="${tableId}"]`);
                if (tr) {
                  // Keep selected row reference as it can be cleared by another block (not selected) with same table id
                  selectedTr = tr;
                  // tr.style.backgroundColor = 'lightgray';
                }
                if (elements.length === 0) {
                  blocsTooltip({ chart, tooltip: { ActualBloc, event, t, fixationsUnit, consumptionsUnit, minBlock } });
                } else {
                  blocsTooltip({ chart, tooltip: { opacity: 0 }, minBlock });
                }
                colorBackground =
                  blocs.type === 'optimization' ? iff(blocs.display === true, '#ff62ff', '#f7f0f7') : '#5c95ff';
              } else {
                const tr = document.querySelector(`tr[data-row-key="${tableId}"]`);
                if (tr) {
                  tr.style.backgroundColor = 'white';
                }
                colorBackground =
                  blocs.type === 'optimization' ? iff(blocs.display === true, '#fdcefd', '#f7f0f7') : '#9dbbf6';
              }
              item.backgroundColor = colorBackground;
            }
          });
          if (selectedTr) {
            selectedTr.style.backgroundColor = 'lightgray';
          }
          chart.update();
        }
      },
      scales: {
        x: {
          type: 'time',
          adapters: {
            date: {
              locale: 'en',
            },
          },
          time: {
            unit: 'month',
            tooltipFormat: 'MMMM YYYY',
          },
          display: true,
          grid: {
            display: true,
            z: 1,
          },
          ticks: {
            callback: (value) => {
              const removeMonth = ['02', '03', '05', '06', '08', '09', '11', '12'];
              const date = moment(value).format('MM/YY');
              const month = moment(value).format('MM');
              if (removeMonth.includes(month)) {
                return '';
              }
              return date;
            },
          },
        },
        y: {
          min: 0,
          display: false,
          grid: {
            display: true,
            z: 1,
          },
          title: {
            display: true,
            text: `${t(`global-unit-${fixationsUnit}`)}`,
            color: '#ff62ff',
          },
          ticks: {
            color: '#ff62ff',
          },
        },
        yConsumptions: {
          type: 'linear',
          min: 0,
          position: 'left',
          display: true,
          grid: {
            display: true,
            z: 1,
            drawOnChartArea: false,
          },
          title: {
            display: true,
            text: `${t(`global-unit-${consumptionsUnit}`)}`,
          },
        },
      },
    };
  }, [consumptionsUnit, data, fixationsUnit, minBlock, t]);

  const mydata = React.useMemo(() => {
    if (!themeContext) {
      return [];
    }
    const dataset = [];
    const monthsValues = {};
    mwh?.forEach((item) => {
      const middleMonth = moment(item.date).daysInMonth() / 2;
      dataset.push({
        label: 'CMWH',
        data: [
          {
            x: moment(item.date).startOf('month').format('YYYY-MM-DD'),
            y: item.consumption,
          },
          {
            x: moment(item.date).add(1, 'month').startOf('month').format('YYYY-MM-DD'),
            y: item.consumption,
          },
        ],
        pointRadius: 0,
        borderColor: '#000000',
        pointBorderColor: '#00000000',
        borderWidth: 1,
        pointBorderWidth: 0,
        pointHoverBackgroundColor: '#00000000',
        fill: 'false',
        yAxisID: 'yConsumptions',
      });
      dataset.push({
        label: 'MWH-value',
        data: [
          {
            x: moment(item.date).startOf('month').add(middleMonth, 'day').format('YYYY-MM-DD'),
            y: item.consumption,
          },
        ],
        pointRadius: 3,
        borderColor: '#000000',
        pointBorderColor: '#000000',
        borderWidth: 1,
        pointBorderWidth: 0.5,
        pointHoverBackgroundColor: '#00000000',
        fill: 'false',
        yAxisID: 'yConsumptions',
      });
    });

    monthsData.monthsData.forEach((item) => {
      const DatasetData = [];
      let valueConsumption = 0;
      item.data.forEach((i) => {
        monthsValues[i.day] = (monthsValues[i.day] || 0) + Number(i.consumption);
        if (valueConsumption !== monthsValues[i.day] || i.day === item.data[item.data.length - 1].day) {
          valueConsumption = monthsValues[i.day];
          DatasetData.push({
            x: moment(i.day).format('YYYY-MM-DD'),
            y: monthsValues[i.day],
          });
          DatasetData.push({
            x: moment(i.day).add(1, 'day').format('YYYY-MM-DD'),
            y: monthsValues[i.day],
          });
        }
      });
      const colorBackground =
        item.type === 'optimization' ? iff(item.display === true, '#fdcefd', '#f7f0f7') : '#9dbbf6';
      dataset.push({
        label: item.id,
        data: DatasetData,
        backgroundColor: colorBackground,
        pointRadius: 0,
        borderColor: '#00000000',
        pointBorderColor: '#00000000',
        borderWidth: 1,
        pointBorderWidth: 0.1,
        pointHoverBackgroundColor: '#00000000',
        fill: 'origin',
        stepped: true,
        yAxisID: 'yConsumptions',
      });
    });
    return { datasets: dataset };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthsData.monthsData]);

  return <LineChartJs data={mydata} options={options} ref={chart} style={{ maxHeight: '200px', display: 'unset' }} />;
};

export default Line;
