import { Space, Switch as SwitchAnt } from 'antd';
import omit from 'lodash/omit';
import React from 'react';

import { Label } from '../../../style/utils';

const Switch = React.forwardRef(({ labelValue, ...input }, ref) => (
  <Space size="middle">
    <SwitchAnt {...omit(input, ['error'])} checked={input.value || input.checked} ref={ref} />
    {labelValue && <Label>{labelValue}</Label>}
  </Space>
));

Switch.displayName = 'Switch';

export default Switch;
