import { Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';

import Button from '../../../../components/Button';
import ArrowRight from '../../../../components/icons/ArrowRight';
import KamMultiple from '../../../../components/icons/KamMultipleIcon';
import Table from '../../../../components/Table';
import WrapperWhiteBox from '../../../../components/WrapperWhiteBox';
import { useGetGroupList } from '../../../../endpoints/groups/useGetGroupList';
import { Navigation } from '../../../../navigation';
import history from '../../../../routes/history';
import { TextColored, TextRegular } from '../../../../style/utils';

const GroupList = () => {
  const [t] = useTranslation();
  const { data: groupList, isLoading: isGroupListLoading } = useGetGroupList();
  const themeContext = React.useContext(ThemeContext);

  const dataSource = React.useMemo(() => {
    if (!groupList) return [];

    return groupList
      .map((item) => ({
        ...item,
        key: item.id,
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [groupList]);

  const header = React.useMemo(() => {
    if (!groupList) {
      return [];
    }

    return [
      {
        dataIndex: 'name',
        title: 'name',
        key: 'name',
        width: '15%',
        render: (name) => <TextColored>{name}</TextColored>,
      },
      {
        dataIndex: 'kamCount',
        title: 'name',
        width: '84%',
        render: (kamCount) => (
          <TextRegular>
            {t('multi-access-group-member-count')} {kamCount}
          </TextRegular>
        ),
      },
      {
        title: 'action',
        key: 'action',
        width: '1%',
        render: () => <ArrowRight color={themeContext.colors.baseColor} />,
      },
    ];
  }, [groupList, t, themeContext.colors.baseColor]);

  const extra = React.useMemo(
    () => (
      <Row>
        <Col flex="auto">
          <Button onClick={() => history.push(Navigation.GROUP_CREATE)}>{t('multi-access-group-add-btn')}</Button>
        </Col>
      </Row>
    ),
    [t],
  );

  if (isGroupListLoading) {
    return null;
  }

  return (
    <WrapperWhiteBox extra={extra} icon={<KamMultiple />} title={t('multi-access-group-title')}>
      {groupList.length === 0 ? (
        <TextRegular>{t('multi-access-group-no-group')}</TextRegular>
      ) : (
        <Table
          columns={header}
          dataSource={dataSource}
          showHeader={false}
          onRow={(record) => ({
            onClick: () => {
              history.push(Navigation.GROUP_DETAILS.replace(':id', record.id));
            },
          })}
        />
      )}
    </WrapperWhiteBox>
  );
};

export default GroupList;
