import styled from 'styled-components';

export const Title = styled.div`
  font-weight: bold;
  padding: 0.625rem 0;
  font-size: 0.812rem;
  text-align: left;
`;

export const Note = styled.textarea`
  padding: 0.625rem 0.5rem 2.5rem 0.5rem;
  margin-bottom: 1rem;
  font-size: 0.812rem;
  text-align: left;
  width: 100%;
  border: none;
  resize: none;
  background: none;
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
`;
