import React from 'react';

function HistoricFixationOutdated() {
  return (
    <svg
      style={{ display: 'block' }}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="23.938"
      viewBox="0 0 20 23.938"
    >
      <g fill="#1a1818" transform="translate(-2287.359 -1876.615)">
        <path
          d="M2304.326 1883.38l2.032-2.57-1.241-.98-2.012 2.538a9.9 9.9 0 00-4.955-1.787v-2.381h1.57v-1.581H2295v1.581h1.57v2.384a10.007 10.007 0 107.758 2.8zm-6.967 15.247a8.039 8.039 0 118.038-8.038 8.038 8.038 0 01-8.038 8.038z"
          data-name="Tracé 250"
        />
        <path
          d="M2310.354 1913.357a1.921 1.921 0 112.217-3.137l.682.874 2.844 3.648a.275.275 0 01-.3.43l-4.388-1.464z"
          data-name="Tracé 251"
          transform="translate(-13.667 -20.889)"
        />
        <path
          d="M2318.6 1912.317a1.769 1.769 0 01.633.811z"
          data-name="Tracé 252"
          transform="translate(-20.054 -22.913)"
        />
      </g>
    </svg>
  );
}

export default HistoricFixationOutdated;
