import { Radio as RadioAnt, Space } from 'antd';
import React from 'react';
import styled, { css } from 'styled-components';

import { getHue } from '../../../style/utils';

const RadioButton = React.forwardRef(({ items, onChange, id, mobileView, className, ...input }, ref) => (
  <div className={className}>
    <RadioAnt.Group {...input} aria-label={id} ref={ref} onChange={(e) => onChange(e.target.value)}>
      <Space direction={mobileView ? 'vertical' : 'horizontal'} size="middle">
        {items.map(({ label, value, ...rest }) => (
          <RadioAnt key={value} value={value} {...rest}>
            {label}
          </RadioAnt>
        ))}
      </Space>
    </RadioAnt.Group>
  </div>
));

// RadioButton.displayName = 'RadioButton';

const RadioButtonStyled = styled(RadioButton)`
  ${({ theme: { colors }, disabled, color = colors.baseColor }) => {
    const hoverColor = `hsl(${getHue(color)}, 48%, 49%)`;

    const background = color;
    const fontColor = colors.black;
    const backgroundHover = hoverColor;
    const colorHover = colors.white;
    let disabledCss;

    if (disabled) {
      disabledCss = css`
        border: none;
        background: ${colors.gray};
        color: ${colors.white};

        &:hover {
          cursor: default;
          transform: unset;
          background: ${colors.gray};
        }
      `;
    }

    return css`
      .ant-radio-group {
        position: relative;
        align-items: center;
        border-radius: 1.5rem;
        background-color: white;
        color: ${fontColor};
        overflow: hidden;
        border: 0.5px solid rgba(53, 52, 52, 0.226);
      }

      .ant-radio-button-wrapper {
        position: relative;
        align-items: center;

        background-color: white;
        color: ${fontColor};
        overflow: hidden;
        border: none;
      }

      // .ant-radio-button-wrapper:hover {
      //   cursor: pointer;
      //   background: ${backgroundHover};
      //   color: ${colors.white};
      // }

      .ant-radio-button-wrapper:active {
        background-color: ${background};
        color: ${colorHover};
      }

      &:active {
        transform: none;
      }
      ${disabledCss}
    `;
  }}
`;

export default RadioButtonStyled;
