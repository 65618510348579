import { Space } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { ThemeContext } from 'styled-components';

import { useDeleteGroup } from '../../../endpoints/groups/useDeleteGroup';
import { Navigation } from '../../../navigation';
import { TextRegular } from '../../../style/utils';
import Button from '../../Button';
import Hr from '../../Hr';
import DeleteGroupIcon from '../../icons/DeleteGroupIcon';
import Modal from '../../Modal';
import SafeHTMLTranslate from '../../SafeHTMLTranslate';

const ModalDeleteGroup = ({ className, onClose, title, groupId, ...modalProps }) => {
  const [t] = useTranslation();
  const { mutate: deleteGroup, isLoading } = useDeleteGroup(groupId);
  const themeContext = React.useContext(ThemeContext);
  const history = useHistory();

  const handleDelete = React.useCallback(() => {
    deleteGroup(null, {
      onSuccess() {
        history.push(Navigation.GROUP_LIST);
      },
    });
  }, [deleteGroup, history]);

  return (
    <Modal
      className={className}
      icon={<DeleteGroupIcon />}
      size="large"
      title={title}
      onCancel={onClose}
      {...modalProps}
    >
      <Space direction="vertical" size="large">
        <TextRegular>
          <SafeHTMLTranslate template="modal-delete-multi-access-group-description" />
        </TextRegular>

        <Hr />

        <Space size="middle">
          <Button variant="outlined" onClick={onClose}>
            {t('global-cancel')}
          </Button>
          <Button isLoading={isLoading} style={{ background: themeContext.colors.orange }} onClick={handleDelete}>
            {t('modal-delete-multi-access-group-valid-btn')}
          </Button>
        </Space>
      </Space>
    </Modal>
  );
};

export default ModalDeleteGroup;
