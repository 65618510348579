import styled from 'styled-components';

export const Date = styled.div`
  color: #fff;
  font-weight: bold;
  font-size: 0.75rem;
`;

export const EventName = styled.div`
  color: black;
  font-weight: bold;
  font-size: 1rem;
`;

export const Description = styled.div`
  font-size: 0.875rem;
`;
