import 'moment/locale/it';
import 'chartjs-adapter-moment';

import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Interaction,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import React, { useContext, useMemo } from 'react';
import { Line as LineChartJs } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';

import Interpolate from './plugin/Interpolate';

const HIGH_TREND = 'high';
const LOW_TREND = 'low';

const Line = ({ history = [], trends = [], energyPriceUnit, productInfo, firstDate, lastDate }) => {
  Interaction.modes.interpolate = Interpolate;
  const trendsFromToday = trends.filter((t) => !moment(t.date).isBefore(moment()));
  const [t] = useTranslation();
  const themeContext = useContext(ThemeContext);
  const dataHistory = useMemo(() => sortBy(history?.map((item) => item) || [], (item) => item.date), [history]);

  const trendsAdjusted = useMemo(() => {
    // Find last history point and use it as first trend point
    const lastHistoryPoint = dataHistory && dataHistory.length > 1 ? dataHistory[dataHistory.length - 1] : undefined;
    const firstTrendPointArray = lastHistoryPoint
      ? [
          {
            date: lastHistoryPoint.date,
            value: lastHistoryPoint.value,
            min: lastHistoryPoint.value,
            max: lastHistoryPoint.value,
          },
        ]
      : [];
    return [...firstTrendPointArray, ...trendsFromToday];
  }, [trendsFromToday, dataHistory]);

  const dataTrends = useMemo(
    () => sortBy(trendsAdjusted.map((item) => item) || [], (item) => item.date),
    [trendsAdjusted],
  );

  const convertedDataHistory = React.useMemo(
    () =>
      dataHistory.map((item) => ({
        x: moment(item.date).format('YYYY-MM-DD'),
        y: item.value,
      })),
    [dataHistory],
  );
  const convertedDataTrends = React.useMemo(
    () =>
      dataTrends.map((item) => ({
        x: moment(item.date).format('YYYY-MM-DD'),
        y: item.value,
      })),
    [dataTrends],
  );

  const convertedDataTrendsMin = React.useMemo(
    () =>
      dataTrends.map((item) => ({
        x: moment(item.date).format('YYYY-MM-DD'),
        y: item.min,
      })),
    [dataTrends],
  );

  const convertedDataTrendsMax = React.useMemo(
    () =>
      dataTrends.map((item) => ({
        x: moment(item.date).format('YYYY-MM-DD'),
        y: item.max,
      })),
    [dataTrends],
  );

  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale, Filler);

  const options = React.useMemo(() => {
    if (!t || !energyPriceUnit) {
      return {};
    }
    return {
      showTooltips: false,
      responsive: true,
      datasetFill: true,
      draggable: true,
      interaction: {
        mode: 'nearest',
        intersect: false,
        axis: 'x',
        callbacks: {
          label(data) {
            const { dataset } = data;
            const unit = t(`global-energy-${energyPriceUnit}`);

            if (dataset.label === 'history') {
              return `${t('market-tendencies-value-closing-price')} ${productInfo}: ${
                dataset.data[data.dataIndex]?.y
              } ${unit}`;
            }
            if (dataset.label === 'trends') {
              return `${t('market-tendencies-value-average-price')} ${productInfo}: ${
                dataset.data[data.dataIndex]?.y
              } ${unit}`;
            }
            if (dataset.label === HIGH_TREND) {
              return `${t('market-tendencies-max')}: ${dataset.data[data.dataIndex]?.y} ${unit}`;
            }
            if (dataset.label === LOW_TREND) {
              return `${t('market-tendencies-min')}: ${dataset.data[data.dataIndex]?.y} ${unit}`;
            }

            return '';
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          type: 'time',
          min: moment(firstDate).format('YYYY-MM-DD'),
          max: moment(lastDate).format('YYYY-MM-DD'),
          adapters: {
            date: {
              locale: 'en',
            },
          },
          time: {
            unit: 'year',
            tooltipFormat: 'dddd, MMMM D, YYYY',
          },
          display: true,
        },
        y: {
          display: true,
          title: {
            display: true,
            text: t(`global-energy-${energyPriceUnit}`),
            font: {
              size: 12,
              style: 'normal',
              lineHeight: 1.2,
            },
          },
        },
      },
    };
  }, [t, productInfo, firstDate, lastDate, energyPriceUnit]);

  const data = React.useMemo(() => {
    if (
      !convertedDataHistory ||
      !convertedDataTrends ||
      !convertedDataTrendsMin ||
      !convertedDataTrendsMax ||
      !themeContext
    ) {
      return [];
    }
    const dataset = [
      {
        label: 'history',
        data: convertedDataHistory,
        backgroundColor: '#88888833',
        pointRadius: 0.1,
        borderColor: '#444',
        pointBorderColor: '#444',
        borderWidth: 1,
        pointBorderWidth: 0.1,
        pointHoverBackgroundColor: '#444',
        fill: 'start',
      },
      {
        label: 'history hidden',
        data: convertedDataHistory,
        backgroundColor: '#88888833',
        fill: 'end',
        pointRadius: 0.1,
        borderColor: '#444',
        borderWidth: 1,
        pointBorderColor: '#444',
        pointHoverBackgroundColor: '#444',
      },
      {
        label: 'trends',
        data: convertedDataTrends,
        tension: 0.5,
        pointRadius: 0.1,
        fill: false,
        borderWidth: 2,
        borderColor: themeContext.colors.lineGraph,
        backgroundColor: themeContext.colors.lineGraph,
      },
      {
        label: HIGH_TREND,
        data: convertedDataTrendsMax,
        tension: 0.5,
        pointRadius: 0,
        borderWidth: 1,
        borderColor: themeContext.colors.lineGraphBackground,
        backgroundColor: themeContext.colors.lineGraphBackground,
        fill: {
          target: '+1',
          above: themeContext.colors.lineGraphBackground,
        },
      },
      {
        label: LOW_TREND,
        data: convertedDataTrendsMin,
        tension: 0.5,
        pointRadius: 0,
        borderWidth: 1,
        borderColor: themeContext.colors.lineGraphBackground,
        backgroundColor: themeContext.colors.lineGraphBackground,
        fill: false,
      },
    ];
    return { datasets: dataset };
  }, [convertedDataHistory, convertedDataTrends, convertedDataTrendsMax, convertedDataTrendsMin, themeContext]);

  return <LineChartJs data={data} options={options} style={{ maxHeight: '300px', display: 'unset' }} />;
};

export default Line;
