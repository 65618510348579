import { Radio as RadioAnt, Space } from 'antd';
import React from 'react';
import styled, { css } from 'styled-components';

import { TextRegular } from '../../../style/utils';

const Radio = React.forwardRef(({ items, onChange, id, mobileView, className, ...input }, ref) => (
  <div className={className}>
    <RadioAnt.Group {...input} aria-label={id} ref={ref} onChange={(e) => onChange(e.target.value)}>
      <Space direction={mobileView ? 'vertical' : 'horizontal'} size="middle">
        {items.map(({ label, value, ...rest }) => (
          <RadioAnt key={value} value={value} {...rest}>
            <TextRegular>{label}</TextRegular>
          </RadioAnt>
        ))}
      </Space>
    </RadioAnt.Group>
  </div>
));

Radio.displayName = 'Radio';

export default styled(Radio)`
  ${({ error }) => css`
    .ant-radio:hover .ant-radio-inner {
      ${error && 'border: 1px solid red'};
    }
    .ant-radio.ant-radio-disabled .ant-radio-inner {
      ${error && 'border: 1px solid #d9d9d9'};
    }
  `}
`;
