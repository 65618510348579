import i18next from 'i18next';

export { getContractYearTabs } from './fixations';
export { getContractFixationsControls } from './fixations';
export { getContractFixationsPeriods } from './fixations';
export { getDefaultProductsControls } from './fixations';
export { default as uuid } from './uuid';
export { initialContractValidatePeriod } from './validateContractPeriod';
export { initialContractValidateQuantity } from './ValidateContractQuantity';
export { default as useWindowSize } from './window';

export const callAll =
  (...fns) =>
  (...args) =>
    fns.forEach((fn) => fn && fn(...args));

export const getNumberFormattedLanguage = (number) => new Intl.NumberFormat(i18next.language).format(number);

export const sortByString = (a, b) => a.toLowerCase().localeCompare(b.toLowerCase());
