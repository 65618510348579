import React from 'react';

function HistoricStoploss() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24.005}
    height={21.479}
    style={{marginTop: "5px"}}
  >
    <g data-name="Groupe 3437" fill="#17181b">
      <path
        data-name="Trac\xE9 370"
        d="M21.419 21.479H2.583a2.585 2.585 0 0 1-2.585-2.585 2.585 2.585 0 0 1 .346-1.293L9.761 1.293a2.586 2.586 0 0 1 3.532-.946 2.585 2.585 0 0 1 .946.946l9.417 16.308a2.585 2.585 0 0 1-.945 3.531 2.586 2.586 0 0 1-1.293.347ZM12.002 1.6a.968.968 0 0 0-.855.495L1.73 18.403a.992.992 0 0 0 .373 1.352 1 1 0 0 0 .482.129h18.834a.987.987 0 0 0 .987-.988.993.993 0 0 0-.132-.493L12.857 2.095a.969.969 0 0 0-.854-.5Z"
      />
      <path
        data-name="Rectangle 1227"
        d="M12.115 6.209a.948.948 0 0 1 1 .884v5.844a.947.947 0 0 1-1 .883.946.946 0 0 1-1-.883V7.093a.947.947 0 0 1 1-.884Z"
      />
      <path
        data-name="Rectangle 1228"
        d="M12.115 15.325a1.153 1.153 0 0 1 1.153 1.152 1.153 1.153 0 0 1-1.153 1.153 1.153 1.153 0 0 1-1.153-1.153 1.152 1.152 0 0 1 1.153-1.152Z"
      />
    </g>
  </svg>
  );
}

export default HistoricStoploss;
