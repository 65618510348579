import React from 'react';
import styled, { css } from 'styled-components';

import InvisibleButton from '../InvisibleButton';

const MenuBurger = ({ className, onClick, isActive }) => (
  <InvisibleButton className={className} onClick={onClick}>
    <div className={`menu-btn ${isActive ? 'open' : ''}`}>
      <div className="menu-btn__burger" />
    </div>
  </InvisibleButton>
);

export default styled(MenuBurger)`
  ${({ theme: { colors }, isActive }) => css`
    position: fixed;
    right: 25px;
    top: 10px;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: white;
    box-shadow: 3px 3px 10px #e1e1e1;
    height: 40px;
    width: 40px;

    ${isActive &&
    `
      box-shadow: none;
    `}

    .menu-btn {
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
    }

    .menu-btn__burger {
      width: 20px;
      height: 3px;
      background: ${colors.baseColor};
      border-radius: 50px;
      transition: all 0.2s ease-in-out;
    }

    .menu-btn__burger::before,
    .menu-btn__burger::after {
      content: '';
      position: absolute;
      width: 20px;
      height: 3px;
      background: ${colors.baseColor};
      border-radius: 50px;
      transition: all 0.2s ease-in-out;
    }

    .menu-btn__burger::before {
      transform: translateY(-8px) translateX(-10px);
    }

    .menu-btn__burger::after {
      transform: translateY(8px) translateX(-10px);
    }

    .menu-btn.open .menu-btn__burger {
      transform: translateX(-50px);
      background: transparent;
    }

    .menu-btn.open .menu-btn__burger::before {
      transform: rotate(45deg) translate(35px, -35px);
      background: ${colors.baseColor};
    }

    .menu-btn.open .menu-btn__burger::after {
      transform: rotate(-45deg) translate(35px, 35px);
      background: ${colors.baseColor};
    }
  `}
`;
