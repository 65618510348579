import i18next from 'i18next';
import * as yup from 'yup';

import { isValidNumber, transformStringToNumber } from '../input';

export const yupNumberTransform = yup
  .number()
  .transform((value, originalValue, context) => {
    if (context.isType(value)) return value;
    if (originalValue === null) return undefined;

    return isValidNumber(originalValue) ? transformStringToNumber(originalValue) : originalValue;
  })
  .required(i18next.t('yup-validation-number-required'))
  .typeError('yup-validation-number-incorrect');

export const yupNumberTransformPositive = yupNumberTransform.positive(i18next.t('yup-validation-number-positive'));
