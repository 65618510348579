import 'react-phone-input-2/lib/style.css';
import './i18n';
import 'antd/dist/antd.min.css';

import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

if (process.env.NODE_ENV === 'production') {
  LogRocket.init(process.env.REACT_APP_LOG_ROCKET);
  setupLogRocketReact(LogRocket);
}

const rootElement = document.getElementById('root');

ReactDOM.render(<App />, rootElement);
