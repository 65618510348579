/* eslint-disable */

import styled, { css } from 'styled-components';

const rgbToHsl = (r, g, b) => {
  r /= 255;
  g /= 255;
  b /= 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h;
  let s;
  const l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  return [h, s, l];
};

export const getHue = (colorHex) => {
  const r = parseInt(colorHex.substr(1, 2), 16);
  const g = parseInt(colorHex.substr(3, 2), 16);
  const b = parseInt(colorHex.substr(5, 2), 16);

  const [hue] = rgbToHsl(r, g, b);

  return hue * 360;
};

export const Error = styled.div`
  ${({ theme: { colors, fontSize } }) => css`
    font-size: ${fontSize.extraSmall};
    color: ${colors.red};
    white-space: break-spaces;
  `}
`;

export const TextColored = styled.div`
  ${({ theme: { colors, fontSize }, disabled, isSelected }) => css`
    font-size: ${fontSize.small};
    color: ${disabled ? colors.gray : colors.baseColor};
    font-weight: bold;

    ${isSelected &&
    css`
      border-bottom: 2px solid ${colors.baseColor};
    `}
    ${isSelected === false &&
    css`
      color: ${colors.grayDark};
    `}
  `}
`;

export const BlueStyle = styled.div`
  ${({ theme: { colors, fontSize } }) => css`
    font-size: ${fontSize.small};
    color: ${colors.blue};
    font-weight: bold;
  `}
`;

export const HeaderOne = styled.div`
  ${({ theme: { colors, fontSize } }) => css`
    font-size: ${fontSize.header};
    color: ${colors.black};
    font-family: 'Fira Sans', serif;
    font-weight: 600;
    line-height: 2rem;
  `}
`;

export const Title = styled.div`
  ${({ theme: { colors, fontSize } }) => css`
    font-size: ${fontSize.small};
    color: ${colors.gray};
    font-family: 'Fira Sans', serif;
    text-transform: uppercase;
    font-weight: 400;
  `}
`;

export const HeaderTwo = styled.div`
  ${({ theme: { colors, fontSize } }) => css`
    font-size: ${fontSize.smallHeader};
    color: ${colors.black};
    font-family: 'Fira Sans', serif;
    font-weight: 600;
  `}
`;

export const HeaderThree = styled.div`
  ${({ theme: { colors, fontSize } }) => css`
    font-size: ${fontSize.extraSmallHeader};
    color: ${colors.black};
    font-family: 'Fira Sans', serif;
    font-weight: 600;
  `}
`;

export const TextBeige = styled.div`
  ${({ theme: { colors, fontSize } }) => css`
    font-size: ${fontSize.key};
    color: ${colors.beige};
    font-family: 'Fira Sans', serif;
    font-weight: 600;
  `}
`;

export const TextRegular = styled.div`
  ${({ theme: { colors, fontSize, breakpoints }, color = colors.grayDark, textAlign }) => css`
    font-size: ${fontSize.small};
    color: ${color};

    ${textAlign && `text-align: ${textAlign}`};
    
    @media (min-width: ${breakpoints.lg}) {
      font-size: ${fontSize.regular};
    }
  `}
`;

export const TextRegularBold = styled.div`
  ${({ theme: { colors, fontSize } }) => css`
    font-size: ${fontSize.regular};
    color: ${colors.grayDark};
    font-weight: bold;
  `}
`;

export const TextSmall = styled.div`
  ${({ theme: { colors, fontSize } }) => css`
    font-size: ${fontSize.small};
    color: ${colors.grayDark};
  `}
`;

export const TextItalic = styled.div`
  ${({ theme: { colors, fontSize } }) => css`
    font-size: ${fontSize.small};
    color: ${colors.grayDark};
    font-style: italic;
  `}
`;

export const TextItalicBold = styled.div`
  ${({ theme: { colors, fontSize } }) => css`
    font-size: ${fontSize.small};
    color: ${colors.grayDark};
    font-weight: bold;
    font-style: italic;
  `}
`;

export const TextSmallExtra = styled.div`
  ${({ theme: { colors, fontSize } }) => css`
    font-size: ${fontSize.extraSmall};
    color: ${colors.grayDark};
  `}
`;

export const TextBoldSmallExtra = styled.div`
  ${({ theme: { colors, fontSize } }) => css`
    font-size: ${fontSize.extraSmall};
    color: ${colors.grayDark};
    font-weight: bold;
  `}
`;

export const TextWarning = styled.div`
  ${({ theme: { colors, fontSize } }) => css`
    font-size: ${fontSize.regular};
    color: ${colors.orange};
    font-weight: bold;
  `}
`;

export const Label = styled.label`
  ${({ theme: { colors, fontSize } }) => css`
    font-size: ${fontSize.regular};
    color: ${colors.black};
    font-weight: 600;
  `}
`;

export const TextGrey = styled.div`
  ${({ theme: { colors, fontSize } }) => css`
    font-size: ${fontSize.small};
    color: ${colors.gray};
    font-weight: 500;
  `}
`;

export const GrayHeader = styled.div`
  ${({ theme: { colors } }) => css`
    background: ${colors.grayLight};
  `}
`;

export const RectangleInformation = styled.div`
  ${({ theme: { colors } }) =>
    css`
      background: ${colors.beige};
      padding: 1.8rem;
    `}
`;
