import React from 'react';

const ValidationOFFIcon = () => (
  <svg
    style={{ display: 'block' }}
    data-testid="validation-off-icon"
    height="28"
    viewBox="0 0 28 28"
    width="28"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="picto-validation-markettrend-OFF" transform="translate(-1144 -595)">
      <circle
        cx="14"
        cy="14"
        data-name="Ellipse 50"
        fill="#fff"
        id="Ellipse_50"
        r="14"
        transform="translate(1144 595)"
      />
      <g data-name="Groupe 1527" id="Groupe_1527" transform="translate(1151.999 605.045) rotate(-2)">
        <g data-name="Groupe 1379" id="Groupe_1379" transform="translate(0 0)">
          <path
            d="M0,3.925,4.253,8.386,12.639,0"
            data-name="Tracé 167"
            fill="none"
            id="Tracé_167"
            stroke="#d5d2d2"
            strokeMiterlimit="10"
            strokeWidth="3"
            transform="translate(0 0)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default ValidationOFFIcon;
