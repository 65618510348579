/* eslint-disable no-continue */
import { Interaction } from 'chart.js';

export default (chart, e, options) => {
  const items = [];
  for (let datasetIndex = 0; datasetIndex < chart.data.datasets.length; datasetIndex += 1) {
    // check for interpolate setting
    if (!chart.data.datasets[datasetIndex].interpolate) {
      continue;
    }

    const meta = chart.getDatasetMeta(datasetIndex);
    // do not interpolate hidden charts
    if (meta.hidden) {
      continue;
    }

    const xScale = chart.scales[meta.xAxisID];
    const yScale = chart.scales[meta.yAxisID];

    const xValue = xScale.getValueForPixel(e.x);

    if (xValue > xScale.max || xValue < xScale.min) {
      continue;
    }

    const { data } = chart.data.datasets[datasetIndex];

    const index = data.findIndex((o) => {
      return o.x >= xValue;
    });

    if (index === -1) {
      continue;
    }

    // linear interpolate value
    const prev = data[index - 1];
    const next = data[index];
    let interpolatedValue;
    if (prev && next) {
      const slope = (next.y - prev.y) / (next.x - prev.x);
      interpolatedValue = prev.y + (xValue - prev.x) * slope;
    }

    if (chart.data.datasets[datasetIndex].steppedLine && prev) {
      interpolatedValue = prev.y;
    }

    if (isNaN(interpolatedValue)) {
      continue;
    }

    const yPosition = yScale.getPixelForValue(interpolatedValue);

    // do not interpolate values outside of the axis limits
    if (isNaN(yPosition)) {
      continue;
    }

    // create a 'fake' event point

    const fakePoint = {
      hasValue() {
        return true;
      },
      tooltipPosition() {
        return this._model;
      },
      _model: { x: e.x, y: yPosition },
      skip: false,
      stop: false,
      x: xValue,
      y: interpolatedValue,
    };

    items.push({ datasetIndex, element: fakePoint, index: 0 });
  }

  // add other, not interpolated, items
  const xItems = Interaction.modes.x(chart, e, options);
  for (let index = 0; index < xItems.length; index += 1) {
    const item = xItems[index];
    if (!chart.data.datasets[item.datasetIndex].interpolate) {
      items.push(item);
    }
  }

  return items;
};
