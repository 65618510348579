import * as React from 'react';

const FilterIcon = (props) => (
  <svg style={{ display: 'block' }} xmlns="http://www.w3.org/2000/svg" width={14.728} height={15} {...props}>
    <g stroke={props.stroke ||'#5b6970'}>
      <g data-name="Group 2261">
        <path data-name="Line 345" fill="none" d="M0 2.5h14.728" />
        <g data-name="Ellipse 50" transform="translate(2.5)" fill="#fff">
          <circle cx={2.5} cy={2.5} r={2.5} stroke="none" />
          <circle cx={2.5} cy={2.5} r={2} fill="none" />
        </g>
      </g>
      <g data-name="Group 2263">
        <path data-name="Line 348" fill="none" d="M0 12.592h14.728" />
        <g data-name="Ellipse 54" transform="translate(2.5 10)" fill="#fff">
          <circle cx={2.5} cy={2.5} r={2.5} stroke="none" />
          <circle cx={2.5} cy={2.5} r={2} fill="none" />
        </g>
      </g>
      <g data-name="Group 2264">
        <path data-name="Line 347" fill="none" d="M0 7.546h14.728" />
        <g data-name="Ellipse 53" transform="translate(7.5 5)" fill="#fff">
          <circle cx={2.5} cy={2.5} r={2.5} stroke="none" />
          <circle cx={2.5} cy={2.5} r={2} fill="none" />
        </g>
      </g>
    </g>
  </svg>
);

export default FilterIcon;
