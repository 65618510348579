import * as React from 'react';

const Spain = (props) => (
  <svg height={15} style={{ display: 'block' }} width={23.077} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M0 0h23.077v15H0z" fill="#ce0025" />
    <path d="M0 3.751h23.077v7.499H0z" fill="#ffcd2d" />
  </svg>
);

export default Spain;
