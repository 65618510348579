import { Editor } from '@tinymce/tinymce-react';
import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const RichEditorExampleNew = React.forwardRef(({ onChange, ...props }, _ref) => {
  const handleEditorChange = (content) => {
    onChange(content);
  };

  return (
    <Editor
      apiKey="imfn5uunfp6p4f1b2glc0xamituzigplvnovsc7fnoxkehd1"
      init={{
        height: 300,
        plugins: ['link'],
      }}
      {...props}
      onEditorChange={handleEditorChange}
    />
  );
});

RichEditorExampleNew.displayName = 'RichEditorExample';

export default RichEditorExampleNew;
