import React from 'react';

import { useUserInfo } from '../context/UserInfoContext';

const useFreeLicenseOver = () => {
  const { userInfo } = useUserInfo();

  const isFreeTialExpired = React.useMemo(
    () => userInfo.products.follow.freeTrialExpired,
    [userInfo.products.follow.freeTrialExpired],
  );

  const isFreeTrialBlockedForDomain = React.useMemo(
    () => userInfo.products.follow.freeTrialBlockedForDomain,
    [userInfo.products.follow.freeTrialBlockedForDomain],
  );

  const isSubscriptionExpired = React.useMemo(
    () => userInfo.products.follow.subscriptionExpired,
    [userInfo.products.follow.subscriptionExpired],
  );

  return { isLicenseOver: isFreeTialExpired || isFreeTrialBlockedForDomain || isSubscriptionExpired };
};

export default useFreeLicenseOver;
