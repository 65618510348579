import React, { useContext } from 'react';
import styled, { css } from 'styled-components';

export const LayoutScroll = styled.div`
  ${({ theme: { breakpoints, colors } }) => css`
    width: 100%;
    position: relative;
    padding-bottom: 40px;
    background: ${colors.whiteDark};
    min-height: calc(100vh);

    @media (min-width: ${breakpoints.lg}) {
      top: 80px;
      min-height: calc(100vh - 80px);
    }
  `}
`;

const ScrollContext = React.createContext(undefined);

const ScrollProvider = ({ children }) => {
  const [scrollRef, setScrollRef] = React.useState();

  return (
    <LayoutScroll ref={setScrollRef}>
      {scrollRef && <ScrollContext.Provider value={scrollRef}>{children}</ScrollContext.Provider>}
    </LayoutScroll>
  );
};

function useScroll() {
  const context = useContext(ScrollContext);
  if (context === undefined) {
    throw new Error('useScroll must be used within a ScrollProvider');
  }
  return context;
}

export { ScrollContext, ScrollProvider, useScroll };
