import { DatePicker } from 'antd';
import moment from 'moment';
import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import useDesktop from '../../../hooks/useDesktop';
import { callAll } from '../../../utils';

const { RangePicker: RangePickerAnt } = DatePicker;

const RangePicker = React.forwardRef(({ className, ...propsRangePicker }, ref) => {
  const formatDate = useMemo(() => 'LL', []);
  const isDesktop = useDesktop();
  const [dateSelected, setDateSelected] = React.useState(null);

  React.useEffect(() => {
    if (dateSelected) {
      const pickerRangeNode = document.querySelector('.ant-picker-range').childNodes;
      const date = moment(moment(dateSelected));
      const arrDate = [];

      const startDate = pickerRangeNode[0].childNodes[0].value;
      const endDate = pickerRangeNode[2].childNodes[0].value;
      const startSelected = pickerRangeNode[0].classList.contains('ant-picker-input-active');
      const endSelected = pickerRangeNode[2].classList.contains('ant-picker-input-active');

      if (startSelected) {
        arrDate.push(date);
        if (endDate) arrDate.push(moment(endDate, 'MMM YYYY'));
        callAll(propsRangePicker.onChange, propsRangePicker.customOnChange)(arrDate);
      }

      if (endSelected) {
        if (startDate) arrDate.push(moment(startDate, 'MMM YYYY'));
        arrDate.push(date);
        callAll(propsRangePicker.onChange, propsRangePicker.customOnChange)(arrDate);
      }
      setDateSelected(null);
    }
  }, [propsRangePicker, propsRangePicker.onChange, dateSelected]);

  propsRangePicker.onChange = callAll(propsRangePicker.onChange, propsRangePicker.customOnChange);

  return (
    <div className={className}>
      <RangePickerAnt format={formatDate} size={isDesktop ? 'large' : 'middle'} {...propsRangePicker} ref={ref} />
    </div>
  );
});

RangePicker.displayName = 'RangePicker';

export default styled(RangePicker)`
  ${({ theme: { colors, fontSize }, error }) => css`
    .ant-picker-disabled {
      background: #f5f5f5 !important;
      border-color: #d9d9d9;
      cursor: not-allowed;

      .ant-picker-input > input {
        color: rgba(0, 0, 0, 0.25) !important;
      }

      &:hover {
        border-color: #d9d9d9;
      }
    }

    .ant-picker-focused {
      ${error && 'box-shadow: 0 0 0 2px #f1d8d8'};
    }

    .ant-picker-range .ant-picker-active-bar {
      ${error && 'border: 1px solid red'};
    }

    .ant-picker {
      background: ${colors.whiteDark};
      width: 100%;

      &,
      &:hover {
        ${error && 'border: 1px solid red'};
      }

      .ant-picker-input > input {
        color: ${colors.grayDark};
        font-size: ${fontSize.regular};
      }
    }
  `}
`;
