import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const BoldButton = styled(Link)`
  ${({ theme: { colors, fontSize } }) => css`
    color: ${colors.baseColor};
    font-weight: bold;
    font-size: ${fontSize.small};

    :hover {
      cursor: pointer;
    }
  `}
`;

export const SubTitle = styled.div`
  ${({ theme: { colors, fontSize } }) => css`
    color: ${colors.grayDark};
    font-size: ${fontSize.small};
  `}
`;

export const Title = styled.div`
  ${({ theme: { colors, fontSize }, isOutdated = false }) => css`
    color: ${isOutdated ? colors.orange : colors.grayDark};
    font-weight: bold;
    font-size: ${fontSize.small};
  `}
`;

export const LastName = styled.div`
  ${({ theme: { fontSize } }) => css`
    color: #202124;
    font-size: ${fontSize.extraSmallHeader};
    font-family: 'Fira Sans', serif;
  `}
`;

export const Society = styled.div`
  ${({ theme: { colors, fontSize } }) => css`
    color: ${colors.grayDark};
    font-size: ${fontSize.small};
  `}
`;

export const WrapperEnterprise = styled.button`
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  outline: none;

  &:hover {
    cursor: pointer;
  }
`;
