import { useMutation } from 'react-query';

import { useApi } from '../../context/ApiContext';
import { useTenant } from '../../context/TenantContext';

export const useCheckUsername = () => {
  const client = useApi();
  const { env } = useTenant();

  return useMutation((username) =>
    client.post(`${env.REACT_APP_BACKEND_PORTAL_URL}/users/checkUsername`, { username }).then((res) => res.data),
  );
};
