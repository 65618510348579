import { Col, Row, Space } from 'antd';
import React from 'react';

import useDesktop from '../../hooks/useDesktop';
import { Error, Label, TextRegular } from '../../style/utils';
import { callAll } from '../../utils';
import { getErrorMessage } from '../../utils/input';
import SafeHTMLTranslate from '../SafeHTMLTranslate/SafeHTMLTranslate';
// eslint-disable-next-line import/no-cycle
import Tag from '../Tag';
import RichEditorExample from '../Wysiwyg/WysiwygNew';
import Checkbox from './Checkbox';
import DatePicker from './DatePicker';
import Number from './Number';
import Phone from './Phone';
import Radio from './Radio';
import RadioButton from './RadioButton';
import RangePicker from './RangePicker';
import Select from './Select';
import SelectMultiple from './SelectMultiple';
import Switch from './Switch';
import Text from './Text';
import TextArea from './TextArea';

const Input = React.forwardRef(
  (
    {
      label,
      labelDescription,
      children,
      error,
      sizeInput,
      onBlur,
      onChange,
      customOnBlur,
      customOnChange,
      displayError = true,
      ...props
    },
    ref,
  ) => {
    const isDesktop = useDesktop();
    const isWysiwyg = !!children.type.displayName.match(/RichEditorExample/i);
    const isSelect = !!children.type.displayName.match(/select/i);

    const properties = {
      ref,
      size: isDesktop ? 'large' : 'default',
      onChange: callAll(onChange, customOnChange),
      onBlur: callAll(onBlur, customOnBlur),
      error: !!error,
      id: label,
      ...props,
    };

    // Test purpose for <Select />
    if (isSelect) {
      properties.labelInput = label || props.name;
    }

    // `id` works with htmlFor, otherwise we use data-testid
    if (label && !isWysiwyg) properties.id = label;
    else properties['data-testid'] = props.name;

    if (!sizeInput) {
      sizeInput = {
        span: 24,
      };
    }

    return (
      <Space direction="vertical">
        {(label || labelDescription) && (
          <Space direction="vertical" size={4}>
            {label && <Label htmlFor={label}>{label}</Label>}
            {labelDescription && (
              <TextRegular>
                <SafeHTMLTranslate template={labelDescription} />
              </TextRegular>
            )}
          </Space>
        )}

        <Space direction="vertical">
          <Row>
            <Col {...sizeInput}>{React.cloneElement(children, properties)}</Col>
          </Row>

          {error && displayError && <Error data-testid="input-error">{getErrorMessage(error, 'text')}</Error>}
        </Space>
      </Space>
    );
  },
);

Input.Text = React.forwardRef((props, ref) => (
  <Input {...props} ref={ref}>
    <Text />
  </Input>
));

Input.Number = React.forwardRef((props, ref) => (
  <Input {...props} ref={ref}>
    <Number />
  </Input>
));

Input.Select = React.forwardRef((props, ref) => (
  <Input {...props} ref={ref}>
    <Select />
  </Input>
));

Input.SelectMultiple = React.forwardRef((props, ref) => (
  <Input {...props} ref={ref}>
    <SelectMultiple />
  </Input>
));

Input.Radio = React.forwardRef((props, ref) => (
  <Input {...props} ref={ref}>
    <Radio />
  </Input>
));

Input.RadioButton = React.forwardRef((props, ref) => (
  <Input {...props} ref={ref}>
    <RadioButton />
  </Input>
));

Input.TextArea = React.forwardRef((props, ref) => (
  <Input {...props} ref={ref}>
    <TextArea />
  </Input>
));

Input.RangePicker = React.forwardRef((props, ref) => (
  <Input {...props} ref={ref}>
    <RangePicker />
  </Input>
));

Input.DatePicker = React.forwardRef((props, ref) => (
  <Input {...props} ref={ref}>
    <DatePicker />
  </Input>
));

Input.Switch = React.forwardRef((props, ref) => (
  <Input {...props} ref={ref}>
    <Switch />
  </Input>
));

Input.Phone = React.forwardRef((props, ref) => (
  <Input {...props} ref={ref}>
    <Phone />
  </Input>
));

Input.Tag = React.forwardRef((props, ref) => (
  <Input {...props} ref={ref}>
    <Tag />
  </Input>
));

Input.Checkbox = React.forwardRef((props, ref) => (
  <Input {...props} ref={ref}>
    <Checkbox />
  </Input>
));

Input.Wysiwyg = React.forwardRef((props, ref) => (
  <Input {...props} ref={ref}>
    <RichEditorExample />
  </Input>
));

export default Input;
