const mtUserState = {
  country: '',
  name: '',
  energy: '',
  trends: [],
  date: '',
  time: '',
  description: '',
};

export default mtUserState;
