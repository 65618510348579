import { Col, Row, Space } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ThemeContext } from 'styled-components';

import Button from '../../../../components/Button';
import ButtonLink from '../../../../components/ButtonLink';
import Hr from '../../../../components/Hr';
import KamIcon from '../../../../components/icons/KamIcon';
import ModalDeleteMultiAccess from '../../../../components/Modals/ModalDeleteMultiAccess';
import ModalSimple from '../../../../components/Modals/ModalSimple';
import ModalTransfersAllAccount from '../../../../components/Modals/ModalTransfersAllAccount';
import WrapperWhiteBox from '../../../../components/WrapperWhiteBox/WrapperWhiteBox';
import { useGetCanDeleteUser } from '../../../../endpoints/multiaccess/useGetCanDeleteUser';
import { useGetMultiAccessKamInfo } from '../../../../endpoints/multiaccess/useGetMultiAccessKamInfo';
import { useGetMultiAccessUserDetail } from '../../../../endpoints/multiaccess/useGetMultiAccssUserDetail';
import useCookie from '../../../../hooks/useCookie';
import useGetFollowApp from '../../../../hooks/useGetFollowApp';
import { Navigation } from '../../../../navigation';
import history from '../../../../routes/history';
import { Label, TextRegular, TextRegularBold } from '../../../../style/utils';

const MultiAccessKamDetails = () => {
  const themeContext = React.useContext(ThemeContext);
  const [t] = useTranslation();
  const { getCookie, setCookie } = useCookie();
  const followAppUrl = useGetFollowApp();
  const [showModalDelete, setShowModalDelete] = React.useState(false);
  const [showModalChangePassword, setShowModalChangePassword] = React.useState(false);
  const [showModalTransfersAllClientAccount, setShowModalTransfersAllClientAccount] = React.useState(false);
  const { userId } = useParams();
  const {
    data: userDetails,
    isLoading: userDetailsLoading,
    isError: userDetailsError,
  } = useGetMultiAccessUserDetail(userId);
  const { data: canDeleteUser } = useGetCanDeleteUser(userId);
  const { data: kamInfo, isLoading: kamInfoLoading, isError: kamInfoError } = useGetMultiAccessKamInfo(userId);

  const columnLength = React.useMemo(() => {
    if (!userDetails) {
      return;
    }
    return userDetails.kamGroups.length === 0 ? 8 : 6;
  }, [userDetails]);

  const canDeleteKam = React.useMemo(() => {
    if (!kamInfo) {
      return null;
    }

    return !(kamInfo.totalContracts > 0 || kamInfo.totalClients > 0);
  }, [kamInfo]);

  const loginAsKam = React.useCallback(() => {
    if (userDetails && userDetails.user.mustChangePassword) {
      setShowModalChangePassword(true);
    } else {
      const cookieManager = getCookie('yem_jwt');
      setCookie('manager_yem_jwt', cookieManager);
      setCookie('yem_jwt', userDetails.kamJwtToken);
      window.location.assign(followAppUrl);
    }
  }, [followAppUrl, getCookie, setCookie, userDetails]);

  if (userDetailsLoading || userDetailsError || kamInfoLoading || kamInfoError) {
    return null;
  }

  return (
    <>
      <WrapperWhiteBox
        extra={
          <Row gutter={[8, 8]}>
            <Col flex="auto">
              <Button onClick={() => history.push(Navigation.MULTI_ACCESS_EDIT.replace(':userId', userId))}>
                {t('multi-access-list-modify-client')}
              </Button>
            </Col>
            {userDetails.managerCanLoginAsKam && (
              <Col flex="auto">
                <Button variant="secondary" onClick={loginAsKam}>
                  {t('multi-access-list-login-as-kam')}
                </Button>
              </Col>
            )}
          </Row>
        }
        icon={<KamIcon />}
        title={`${userDetails.user.firstName} ${userDetails.user.lastName}`}
      >
        <Space direction="vertical" size="large">
          <Hr />

          <Space direction="vertical">
            <Row gutter={[25, 20]}>
              {userDetails.kamGroups.length !== 0 && (
                <Col sm={columnLength} xs={24}>
                  <Space direction="vertical">
                    <Label>{t('multi-access-user-group-title')}</Label>
                    <Space direction="vertical" size={0}>
                      {userDetails.kamGroups.map((group) => (
                        <TextRegular key={group.id}>{group.name}</TextRegular>
                      ))}
                    </Space>
                  </Space>
                </Col>
              )}
              <Col sm={columnLength} xs={24}>
                <Space direction="vertical">
                  <Label>{t('multi-access-user-contact-title')}</Label>
                  <Space direction="vertical">
                    <TextRegularBold>{t('multi-access-user-contact-phone')}</TextRegularBold>
                    <TextRegular>{userDetails.user.phone}</TextRegular>
                    <TextRegularBold>{t('multi-access-user-contact-mail')}</TextRegularBold>
                    <TextRegular>{userDetails.user.username}</TextRegular>
                  </Space>
                </Space>
              </Col>
              <Col sm={columnLength} xs={24}>
                <Space direction="vertical">
                  <Label>{t('multi-access-user-contract-title')}</Label>
                  <TextRegular>{kamInfo.optimizedContracts}</TextRegular>
                </Space>
              </Col>
              <Col sm={columnLength} xs={24}>
                <Space direction="vertical">
                  <Label>{t('multi-access-monitoring-client-access')}</Label>
                  <div>
                    <TextRegular>{kamInfo.totalClients}</TextRegular>
                  </div>
                </Space>
              </Col>
            </Row>
          </Space>

          <Hr />

          <Space size="large">
            {!canDeleteUser?.canDelete && (
              <ButtonLink onClick={() => setShowModalTransfersAllClientAccount(true)}>
                {t('multi-access-transfers-all-account')}
              </ButtonLink>
            )}
            {canDeleteUser?.canDelete && (
              <ButtonLink color={themeContext.colors.orange} onClick={() => setShowModalDelete(true)}>
                {t('multi-access-delete-account')}
              </ButtonLink>
            )}
          </Space>
        </Space>
      </WrapperWhiteBox>

      {showModalDelete && (
        <ModalDeleteMultiAccess
          canDelete={canDeleteKam}
          companyName={userDetails.user.company}
          firstName={userDetails.user.firstName}
          lastName={userDetails.user.lastName}
          userId={userDetails.user._id}
          onClose={() => setShowModalDelete(false)}
        />
      )}

      {showModalTransfersAllClientAccount && (
        <ModalTransfersAllAccount
          firstName={userDetails.user.firstName}
          lastName={userDetails.user.lastName}
          userId={userDetails.user._id}
          onClose={() => setShowModalTransfersAllClientAccount(false)}
        />
      )}

      {showModalChangePassword && (
        <ModalSimple
          content={t('multi-access-change-password-warning')}
          footer={
            <Space>
              <Button variant="secondary" onClick={() => setShowModalChangePassword(false)}>
                {t('global-cancel')}
              </Button>
            </Space>
          }
          size="small"
          title={t('multi-access-change-password-title')}
          onCancel={() => setShowModalChangePassword(false)}
        />
      )}
    </>
  );
};

export default MultiAccessKamDetails;
