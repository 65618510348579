import React, { useCallback, useContext } from 'react';
import { useQueryClient } from 'react-query';

import { useUpdateTranslations } from '../endpoints/language/useUpdateTranslations';
import { useGetUserInfo } from '../endpoints/user/useGetUserInfo';

const UserInfoContext = React.createContext(undefined);

const UserInfoProvider = ({ children }) => {
  const queryClient = useQueryClient();

  const { data: userInfo } = useGetUserInfo();
  const { mutate: saveTranslations } = useUpdateTranslations();

  React.useEffect(() => {
    if (userInfo?.admin) {
      saveTranslations();
    }
  }, [saveTranslations, userInfo?.admin]);

  const setUserInfo = useCallback(
    (newUser) =>
      queryClient.setQueryData('get-user-info', (oldData) => ({
        ...oldData,
        ...newUser,
      })),
    [queryClient],
  );

  if (!userInfo) {
    return null;
  }

  return <UserInfoContext.Provider value={{ userInfo, setUserInfo }}>{children}</UserInfoContext.Provider>;
};

function useUserInfo() {
  const context = useContext(UserInfoContext);
  if (context === undefined) {
    throw new Error('useUserInfo must be used within a UserInfoProvider');
  }
  return context;
}

export { UserInfoContext, UserInfoProvider, useUserInfo };
