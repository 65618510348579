import { Col, Row, Space } from 'antd';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import styled, { ThemeContext } from 'styled-components';

import { useGetKamListForManager } from '../../../endpoints/multiaccess/useGetKamListForManager';
import { useTransfersAllAccount } from '../../../endpoints/multiaccess/useTransfersAllAccount';
import { Navigation } from '../../../navigation';
import { TextRegular } from '../../../style/utils';
import Button from '../../Button';
import Hr from '../../Hr';
import TransfersAccountIcon from '../../icons/TransfersAccountIcon';
import Input from '../../Input';
import Modal from '../../Modal';

const ModalTransfersAllAccount = ({ className, onClose, userId, firstName, lastName }) => {
  const [t] = useTranslation();
  const themeContext = React.useContext(ThemeContext);
  const history = useHistory();
  const { mutate: transfersAccount, isLoading } = useTransfersAllAccount();
  const { data, isLoading: getKamLoading } = useGetKamListForManager();
  const { register, control, handleSubmit, errors, watch } = useForm();
  const { kam } = watch(['kam']);

  React.useEffect(() => {
    register({ name: 'kam' });
  }, [register]);

  const onSubmit = React.useCallback(
    (values) => {
      transfersAccount(
        { currentKamId: userId, newKamId: values.kam },
        {
          onSuccess() {
            history.push(Navigation.MULTI_ACCESS);
          },
        },
      );
    },
    [history, transfersAccount, userId],
  );

  return (
    <Modal
      className={className}
      icon={<TransfersAccountIcon />}
      size="large"
      title={`${firstName} ${lastName}`}
      onCancel={onClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Space direction="vertical" size="large">
          <TextRegular>{t('modal-transfert-all-account-description-info')}</TextRegular>

          <Hr />

          <Row>
            <Col span={24}>
              <Controller
                as={Input.Select}
                control={control}
                error={errors.kam}
                items={(data || [])
                  .filter((item) => item.id !== userId)
                  .map((item) => ({
                    label: `${item.lastName} ${item.firstName}`,
                    value: item.id,
                    key: item.id,
                  }))}
                label={t('modal-transfert-all-account-select')}
                loading={getKamLoading}
                name="kam"
                style={{ width: '300px' }}
              />
            </Col>
          </Row>

          <Hr />

          <Space size="middle">
            <Button variant="outlined" onClick={onClose}>
              {t('global-close')}
            </Button>
            <Button color={themeContext.colors.orange} disabled={!kam} isLoading={isLoading} type="submit">
              {t('global-transfert')}
            </Button>
          </Space>
        </Space>
      </form>
    </Modal>
  );
};

export default styled(ModalTransfersAllAccount)``;
