import { message } from 'antd';
import { useMutation } from 'react-query';

import { useApi } from '../../context/ApiContext';
import en from '../../lang/en.json';

export const useUpdateTranslations = () => {
  const client = useApi();

  return useMutation(
    () =>
      client
        .post(`${process.env.REACT_APP_BACKEND_PORTAL_URL}/config/admin/app-resources/follow`, { en })
        .then((res) => res.data),
    {
      onError() {
        message.error('Request failed to update the translation');
      },
    },
  );
};
