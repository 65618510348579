import styled from 'styled-components';

export const Title = styled.div`
  background: rgb(240, 239, 239);
  font-weight: bold;
  padding: 0.625rem;
  font-size: 0.812rem;
  text-align: center;
  break-inside: avoid;
  print-color-adjust: exact;
`;
