import { useTenant } from '../context/TenantContext';

const useGetFollowApp = () => {
  const { env } = useTenant();

  return env.NODE_ENV === 'production'
    ? `${window.location.protocol}//${window.location.host}`
    : `http://${window.location.hostname}:3001`;
};

export default useGetFollowApp;
