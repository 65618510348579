import { Col, Row, Space } from 'antd';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Hr from '../../../../../../components/Hr';
import Input from '../../../../../../components/Input';
import SafeHTMLTranslate from '../../../../../../components/SafeHTMLTranslate';
import { TextRegular } from '../../../../../../style/utils';
import { isBoolean } from '../../../../../../utils/user';

const Damo = () => {
  const [t] = useTranslation();
  const { control, register, errors } = useFormContext();

  React.useEffect(() => {
    // check before if isCreateForm
    register(
      { name: 'multiaccess.isDailyAccount' },
      { validate: (value) => (!isBoolean(value) ? 'global-field-required' : null) },
    );
  }, [register]);

  const radioItems = React.useMemo(
    () => [
      { key: 0, value: true, label: t('global-yes') },
      { key: 1, value: false, label: t('global-no') },
    ],
    [t],
  );

  return (
    <Space direction="vertical" size="large">
      {/* check before if isCreateForm */}
      <>
        <Hr />

        <Row gutter={[0, 20]}>
          <Col span={24}>
            <Controller
              as={Input.Radio}
              control={control}
              error={errors?.multiaccess?.isDailyAccount}
              items={radioItems}
              label={t('multi-access-edit-demo-step-1')}
              name="multiaccess.isDailyAccount"
            />
          </Col>
          <Col>
            <TextRegular>
              <SafeHTMLTranslate template="multi-access-edit-demo-text-step-1-info" />
            </TextRegular>
          </Col>
        </Row>

        <Hr />
      </>
    </Space>
  );
};

export default Damo;
