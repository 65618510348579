/**
 * Test if the start and end of contract date are valid
 * In case of error, return an error tag
 *
 * @param quantityResult
 * @param quantityValue
 * @returns {{valid: boolean, error: string}}
 */
export function initialContractValidateQuantity(quantityResult, quantityValue) {
  // Init
  let valid = true;
  let error = '';

  if (valid) {
    if (quantityValue < quantityResult) {
      valid = false;
      // error = `${CONTACT_PERIOD_VALIDATION_ERROR.periodTooShort} ${quantityResult}`;
      error = `Consumption value must be higher or equal than ${quantityResult}`;
    } else {
      valid = true;
    }
  }

  return {
    valid,
    error,
  };
}
