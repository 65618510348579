import styled, { css } from 'styled-components';

export const HeaderBar = styled.div`
  ${({ theme: { breakpoints, colors } }) => css`
    padding: 0 20px;
    position: fixed;
    background: ${colors.header};
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    z-index: 100;
    height: 80px;
    width: 100%;

    .active-link > label {
      color: ${colors.headerSmallLinkActive};
    }

    @media (max-width: ${breakpoints.xs}) {
      height: 60px;
    }
  `}
`;

export const WrapperUserMenu = styled.div`
  ${({ theme: { breakpoints } }) => css`
    display: inline-block;

    > div > div > button {
      padding: 0;
    }
    @media (max-width: ${breakpoints.sm}) {
      > div > div > div > div > div {
        margin-left: -7px;
      }
    }
  `}
`;

export const Logo = styled.div`
  height: 100%;
  margin-right: 2rem;

  button,
  button > img {
    height: 100%;
  }
`;

export const MenuBtn = styled.div`
  width: 80px;
  height: 80px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border: 3px solid #fff;
`;

export const BurgerMenu = styled.div`
  ${({ theme: { colors } }) => css`
    color: ${colors.headerIcon};
    font-size: 1.5rem;
    border: 1px solid red;
    display: inline-block;
    position: fixed;
    padding-top: 20px;
    padding-left: 25px;
    padding-right: 25px;
    //background: blue;
    right: 0;
  `}
`;

export const DesktopContainer = styled.div`
  height: 80px;
  display: flex;
  align-items: center;
`;

export const MobileContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    text-align: left;
  }
`;

export const Slider = styled.div`
  ${({ show, theme: { colors } }) => css`
    position: fixed;
    background: white;
    box-shadow: -4px 2px 3px 0 rgba(0, 0, 0, 0.04);
    width: 250px;
    right: ${show ? '0px' : '-250px'};
    transition: all 0.5s ease-in-out;
    padding: 2rem;
    height: 100%;
    z-index: 99;

    label {
      a {
        color: ${colors.headerLinkInactive};

        &.active-link {
          color: ${colors.headerSmallLinkActive};
        }
      }
    }
  `}
`;

export const WrapperLink = styled.div`
  ${({ theme: { colors } }) => css`
    display: flex;
    flex-grow: 1;
    justify-content: space-between;

    label > a,
    button > label {
      color: ${colors.headerLinkInactive};
      transition: 0.25s all;

      &.active-link,
      &:hover {
        color: ${colors.headerLinkActive};
      }
    }
  `}
`;
