import React from 'react';
import { useQueryClient } from 'react-query';

import { useUserInfo } from '../../../context/UserInfoContext';
import { isKam } from '../../../utils/user';

export default () => {
  const queryClient = useQueryClient();
  const { userInfo } = useUserInfo();
  const kamOrClientId = location.pathname.split('/')[2];

  const allKamOrClient = queryClient.getQueryData('get-multi-access-users');

  if (!allKamOrClient) return null;

  const kamOrClient = allKamOrClient.list.find((c) => c.id === kamOrClientId);

  if (!kamOrClient) return null;
  return <span>{isKam(userInfo.role) ? kamOrClient.company : `${kamOrClient.firstName} ${kamOrClient.lastName}`}</span>;
};
