import { Select as SelectAnt } from 'antd';
import omit from 'lodash/omit';
import React from 'react';
import styled, { css } from 'styled-components';

const { Option } = SelectAnt;

const Select = React.forwardRef(({ className, items, labelInput, ...input }, ref) => (
  <div className={className}>
    <SelectAnt {...omit(input, ['error'])} ref={ref}>
      {items.map(({ label, value, ...optionProps }) => (
        <Option key={value} title={`${labelInput}-${value}`} value={value} {...optionProps}>
          {label}
        </Option>
      ))}
    </SelectAnt>
  </div>
));

Select.displayName = 'Select';

export default styled(Select)`
  ${({ theme: { colors, fontSize }, error }) => css`
    && {
      .ant-select {
        width: 100%;
      }
      .ant-select-selector {
        background: ${colors.whiteDark};
        color: ${colors.grayDark};
        font-size: ${fontSize.regular};
      }

      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        ${error && 'border: 1px solid red'};
      }

      .ant-select-focused .ant-select-selection,
      .ant-select-selection:focus,
      .ant-select-selection:active,
      .ant-select-selection:hover,
      .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
        ${error && 'border-color: red'};
        ${error && 'box-shadow: 0 0 0 2px #f1d8d8'};
      }
    }
  `}
`;
