import { message } from 'antd';
import { useMutation, useQueryClient } from 'react-query';

import { useApi } from '../../context/ApiContext';
import { useTenant } from '../../context/TenantContext';

export const useCreateMultiAccessUser = () => {
  const client = useApi();
  const queryClient = useQueryClient();
  const { env } = useTenant();

  return useMutation(
    (values) =>
      client.post(`${env.REACT_APP_BACKEND_PORTAL_URL}/users/multi-access/create`, values).then((res) => res.data),
    {
      onSuccess() {
        queryClient.invalidateQueries('get-multi-access-users');
      },
      onError() {
        message.error('Request failed to create the user');
      },
    },
  );
};
