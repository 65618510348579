import React from 'react';

function MarketTrendPowerIcon() {
  return (
    <svg
      style={{ display: 'block' }}
      xmlns="http://www.w3.org/2000/svg"
      width="12.503"
      height="19.105"
      viewBox="0 0 12.503 19.105"
      data-testid="test-icon-power"
    >
      <path
        fill="#de6811"
        d="M4611.71 1165.75l-10.076 8.141a.476.476 0 01-.738-.552l3.069-7.471a.469.469 0 00-.3-.635l-3.937-1.166a.472.472 0 01-.16-.825l10.479-8.244a.476.476 0 01.728.568l-3.386 7.568a.474.474 0 00.294.65l3.865 1.141a.476.476 0 01.162.825z"
        data-name="picto-market trend electricite"
        transform="translate(-4599.383 -1154.894)"
      />
    </svg>
  );
}

export default MarketTrendPowerIcon;
