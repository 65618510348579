import { Col, Row, Space } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonLink from '../../../../components/ButtonLink/ButtonLink';
import Collapse from '../../../../components/Collapse';
import ModalChangeConsumption from '../../../../components/Modals/ModalChangeConsumption';
import { CONTRACT_FIXATION_GRANULARITY } from '../../../../constants';
import { Label, TextRegular } from '../../../../style/utils';
import { getNumberFormattedLanguage } from '../../../../utils';
import { getCorrectValueFixation } from '../../../../utils/fixations';
import { formatNumber } from '../../../../utils/input';

const ContractDetailsInfoContract = ({ contract, open = false, history = false }) => {
  const [t] = useTranslation();
  const [showModalChangeConsumption, setShowModalChangeConsumption] = useState(false);

  const granularity = React.useMemo(() => {
    if (!contract) return '';
    if (contract.isPercent) {
      return t(`global-${contract.fixationGranularity}`);
    }
    // if (contract.isBlocks && !contract.mode) {
    //   let result = '';
    //   Object.values(CONTRACT_FIXATION_GRANULARITY).forEach((g) => {
    //     if (contract?.blocksSettings?.productsConfig[g]?.productsAvailable.length) {
    //       if (result.length) result += ', ';
    //       result += t(`global-${g}`);
    //       result += ' (';
    //       contract.blocksSettings.productsConfig[g].productsAvailable.forEach((product, index) => {
    //         if (index) result += ' ';
    //         result += product;
    //       });
    //       result += ')';
    //     }
    //   });
    //   return result;
    // }
    let result = '';
    Object.values(CONTRACT_FIXATION_GRANULARITY).forEach((g) => {
      if (contract?.periodProducts[g]?.length) {
        if (result.length) result += ', ';
        result += t(`global-${g}`);
        result += ' (';
        contract.periodProducts[g].forEach((product, index) => {
          if (index) result += ' ';
          result += product;
        });
        result += ')';
      }
    });
    return result;
  }, [contract, t]);

  const informationContract = React.useMemo(() => {
    if (!contract) return [];

    let infoIndex = '';
    if (contract.indexCount >= 2) {
      infoIndex = ` - ${t('contract-detail-indexes')} ${contract.indexCount}`;
    }

    const infos = [
      {
        title: t('global-energy'),
        data: [
          t(`country-${contract?.country}`),
          `${t(`global-${contract?.energy}`)}${infoIndex}`,
          contract?.code ? `${t('form-contract-code-label')} ${contract?.code} ` : ``,
        ],
      },
      {
        title: t('global-energy_delivery'),
        data: [
          `${t('contract-detail-start')} ${moment(contract.start).format('MMM YYYY')}`,
          `${t('contract-detail-end')} ${moment(contract.end).format('MMM YYYY')}`,
        ],
      },
      {
        title: t('contract-detail-condition-fixation'),
        data: [
          granularity,
          contract.isPercent
            ? `${t('global-minimum')} ${getNumberFormattedLanguage(getCorrectValueFixation(contract.minimumFixation))}%`
            : undefined,
        ],
      },
    ];

    if (contract?.objectives?.consumption?.totalAmount > 0) {
      infos.push({
        title: t('contract-detail-objectives-consumption'),
        data: [
          `${contract.objectives.consumption.totalAmount} ${t(`global-energy-${contract.energyUnit}`)}`,
          <ButtonLink key="consumption" name="consumption" onClick={() => setShowModalChangeConsumption(true)}>
            {t('global-change')}
          </ButtonLink>,
        ],
      });
    }

    if (contract?.blocksSettings?.totalConsumption > 0) {
      infos.push({
        title: t('contract-detail-objectives-consumption'),
        data: [
          `${formatNumber(contract.blocksSettings.totalConsumption)} ${t(`global-energy-${contract.energyUnit}`)}`,
        ],
      });
    }
    return infos;
  }, [contract, t, granularity]);

  const valueXS = history ? 6 : 24;

  return (
    <>
      <Collapse
        collapseProps={{
          defaultActiveKey: open ? ['0'] : [],
          collapsible: open ? 'disabled' : '',
        }}
        panelProps={{
          key: 0,
          header: t('contract-detail-contract-information-title'),
          showArrow: !open,
        }}
      >
        <Row className="rowHistoric" gutter={[18, 25]}>
          {informationContract.map((item) => (
            <Col key={item.title} sm={6} xs={valueXS}>
              <Space direction="vertical">
                <Label>{item.title}</Label>
                <Space direction="vertical" size={0}>
                  {item.data.map((info) => {
                    if (typeof info === 'string') {
                      return <TextRegular key={info}>{info}</TextRegular>;
                    }

                    return info;
                  })}
                </Space>
              </Space>
            </Col>
          ))}
        </Row>
        <br />
      </Collapse>

      {showModalChangeConsumption && (
        <ModalChangeConsumption contract={contract} onCancel={() => setShowModalChangeConsumption(false)} />
      )}
    </>
  );
};

export default ContractDetailsInfoContract;
