import i18next from 'i18next';
import { isEmpty } from 'lodash';
import React from 'react';

import SafeHTMLTranslate from '../components/SafeHTMLTranslate';
import { Error } from '../style/utils';

export const transformErrorsToList = (errors, ...omittedKey) => {
  const alreadyIncluded = [];

  if (isEmpty(errors)) {
    return null;
  }

  const recursiveFn = (error) => {
    return Object.keys(error).reduce((acc, key) => {
      const message = error[key];

      if (key === 'type' || omittedKey.includes(key)) {
        return acc;
      }
      if (Array.isArray(message)) {
        message.forEach((item) => {
          acc += recursiveFn(item);
        });
      } else if (typeof message !== 'string') {
        acc += recursiveFn(message);
      } else if (!alreadyIncluded.includes(message)) {
        acc += `- ${i18next.t(message)}\n`;
        alreadyIncluded.push(message);
      }

      return acc;
    }, '');
  };

  const errorFormatted = recursiveFn(errors).slice(0, -1);

  return (
    <Error>
      <SafeHTMLTranslate textBrut={errorFormatted} />
    </Error>
  );
};
