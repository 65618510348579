import { message } from 'antd';
import { useMutation, useQueryClient } from 'react-query';

import { useApi } from '../../context/ApiContext';

export const useDeleteMultiAccessUser = () => {
  const client = useApi();
  const queryClient = useQueryClient();

  return useMutation((payload) => client.post('/multiaccess/user/deleteUser', payload).then((res) => res.data), {
    onMutate({ id }) {
      const listUsers = queryClient.getQueryData('get-multi-access-users');
      const newListUsers = {
        ...listUsers,
        list: listUsers.list.filter((user) => user.id !== id),
      };

      queryClient.setQueryData('get-multi-access-users', newListUsers);
      return { listUsers };
    },
    onError(error, _, context) {
      if (context) queryClient.setQueryData('get-multi-access-users', context.listUsers);

      message.error('Request failed to delete the user');
    },
  });
};
