import { Input } from 'antd';
import omit from 'lodash/omit';
import React from 'react';
import styled, { css } from 'styled-components';

const { TextArea: TextAreaAnt } = Input;

const TextArea = React.forwardRef(({ className, ...inputTextArea }, ref) => (
  <TextAreaAnt
    className={className}
    data-testid={`text-area-${inputTextArea.name}`}
    ref={ref}
    style={{ width: '100%' }}
    {...omit(inputTextArea, ['error'])}
  />
));

TextArea.displayName = 'TextArea';

export default styled(TextArea)`
  ${({ theme: { fontSize, colors } }) => css`
    color: ${colors.grayDark};
    font-size: ${fontSize.regular};
  `}
`;
