import React from 'react';

import FixationPast from './FixationPastTemplate';

const FixationStateTemplate = ({ contractId, fixationsDetail }) => {
  return (
    <>
      {fixationsDetail &&
        fixationsDetail.map((fixation) => (
          <FixationPast history open contractId={contractId} fixationDetail={fixation} key={fixation.periodStart} />
        ))}
    </>
  );
};

export default FixationStateTemplate;
