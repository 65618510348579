import { Col, Row, Space } from 'antd';
import moment from 'moment';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import logoCompany from '../../../../assets/picto-compte-entreprise.svg';
import Button from '../../../../components/Button/Button';
import Collapse from '../../../../components/Collapse';
import WrapperWhiteBox from '../../../../components/WrapperWhiteBox/WrapperWhiteBox';
import { useGetMarketList } from '../../../../endpoints/multiaccess/useGetMarketList';
import { useUpdateMultiAccessUser } from '../../../../endpoints/multiaccess/useUpdateMultiAccessUser';
import useGetPortalApp from '../../../../hooks/useGetPortalApp';
import { Navigation } from '../../../../navigation';
import history from '../../../../routes/history';
import { HeaderOne, TextRegular } from '../../../../style/utils';
import { dateMarketIncludeToday, hasSomeClientMarketActivated } from '../../utils';
import FormStep1 from '../FormStep1';
import FormStep2 from '../FormStep2';

const MultiAccessClientEdit = ({ defaultValues, companyName }) => {
  const [t] = useTranslation();
  const portalAppUrl = useGetPortalApp();
  const { data: marketList } = useGetMarketList();
  const methods = useForm({
    defaultValues: {
      ...defaultValues,
      multiaccess: {
        ...defaultValues.multiaccess,
        clientMarkets: defaultValues.multiaccess?.clientMarkets?.map((market) => ({
          contracts: market.contracts || undefined,
          country: market.country,
          enabled: market.enabled || false,
          energy: market.energy,
          marketTrendsPartner: market.marketTrendsPartner || false,
          marketTrendsCompany: market.marketTrendsCompany || false,
          marketTrendsUser: market.marketTrendsUser || false,
          period: market.periodStart
            ? [moment(market.periodStart, 'YYYY-MM-DD'), moment(market.periodEnd, 'YYYY-MM-DD')]
            : [],
        })),
      },
    },
  });
  const { userId } = useParams();
  const { mutate: updateClient, isLoading } = useUpdateMultiAccessUser(userId);

  const backToDetails = React.useCallback(() => {
    history.push(Navigation.MULTI_ACCESS_DETAILS.replace(':userId', userId));
  }, [userId]);

  const marketListFormatted = React.useMemo(() => {
    if (!marketList) {
      return [];
    }

    return marketList.map((ml) => {
      let existingMarket;

      if (defaultValues.multiaccess.clientMarkets)
        existingMarket = defaultValues.multiaccess.clientMarkets.find(
          (cl) => cl.country === ml.country && cl.energy === ml.energy,
        );

      if (existingMarket) return { ...existingMarket, hasCompanyMarketTrend: ml.hasCompanyMarketTrend };

      return ml;
    });
  }, [defaultValues.multiaccess.clientMarkets, marketList]);

  const onSubmit = React.useCallback(
    (data) => {
      if (data.multiaccess.clientCanLogin && !hasSomeClientMarketActivated(data.multiaccess.clientMarkets)) {
        methods.setError('multiaccess.clientMarkets', { message: 'register-client-marker-error' });
      } else if (data.multiaccess.clientCanLogin && !dateMarketIncludeToday(data.multiaccess.clientMarkets)) {
        methods.setError('multiaccess.clientMarkets', { message: 'register-client-market-no-include-today-error' });
      } else {
        let multiaccess;

        if (data.multiaccess.clientCanLogin) {
          multiaccess = {
            ...defaultValues.multiaccess,
            ...data.multiaccess,
            // Extract `period`, to not sent it in the payload
            clientMarkets: data.multiaccess?.clientMarkets?.map(({ period, ...market }) => ({
              ...market,
              periodStart: market.enabled ? moment(period[0]).format('YYYY-MM-DD') : undefined,
              periodEnd: market.enabled ? moment(period[1]).format('YYYY-MM-DD') : undefined,
            })),
            clientCanLogin: true,
          };
        } else {
          multiaccess = {
            ...data.multiaccess,
            ...defaultValues.multiaccess,
            clientMarkets: defaultValues.multiaccess?.clientMarkets?.map((market) => ({
              ...market,
              periodStart: market.enabled ? market.periodStart : undefined,
              periodEnd: market.enabled ? market.periodEnd : undefined,
            })),
            clientCanLogin: false,
          };
        }

        multiaccess.clientReference = data.multiaccess.clientReference ? data.multiaccess.clientReference : null;

        updateClient(
          {
            ...data,
            phone: data.phone.replace(/\+*/, '+'),
            confirmEmailUrl: `${portalAppUrl}/confirmEmail`,
            multiaccess,
          },
          {
            onSuccess() {
              backToDetails();
            },
          },
        );
      }
    },
    [backToDetails, defaultValues.multiaccess, methods, portalAppUrl, updateClient],
  );

  return (
    <WrapperWhiteBox>
      <Space direction="vertical" size="middle">
        <Space size="middle">
          <img alt="logo" src={logoCompany} />
          <HeaderOne>{companyName}</HeaderOne>
        </Space>

        <TextRegular>{t('multi-access-edit-client-account')}</TextRegular>

        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Space direction="vertical" size="large">
              <Row gutter={[0, 25]}>
                <Col span={24}>
                  <Collapse
                    collapseProps={{ defaultActiveKey: [0] }}
                    panelProps={{ key: 0, header: t('multi-access-user-information-title') }}
                  >
                    <FormStep1 />
                  </Collapse>
                </Col>
                <Col span={24}>
                  <Collapse
                    collapseProps={{ defaultActiveKey: [0] }}
                    panelProps={{ key: 0, header: t('multi-access-contract-optimization-title') }}
                  >
                    <FormStep2 marketList={marketListFormatted} />
                  </Collapse>
                </Col>
              </Row>

              <Space size="middle">
                <Button variant="secondary" onClick={() => backToDetails()}>
                  {t('global-cancel')}
                </Button>
                <Button isLoading={isLoading} type="submit">
                  {t('global-modify')}
                </Button>
              </Space>
            </Space>
          </form>
        </FormProvider>
      </Space>
    </WrapperWhiteBox>
  );
};

export default MultiAccessClientEdit;
