import React from 'react';

function HistoricMarketTrendSwitchIcon() {
  return (
    <svg
      style={{ display: 'block' }}
      height="16.171"
      viewBox="0 0 16.648 16.171"
      width="16.648"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="#202124">
        <path d="M0 0H4.251V16.171H0z" data-name="Rectangle 909" />
        <path d="M0 0H4.251V7.772H0z" data-name="Rectangle 910" transform="translate(6.263 8.399)" />
        <path d="M0 0H4.251V11.856H0z" data-name="Rectangle 911" transform="translate(12.397 4.315)" />
      </g>
    </svg>
  );
}

export default HistoricMarketTrendSwitchIcon;
