import { Col, Row, Space } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { ThemeContext } from 'styled-components';

import Button from '../../../../components/Button/Button';
import Hr from '../../../../components/Hr';
import ArrowRight from '../../../../components/icons/ArrowRight';
import CompanyIcon from '../../../../components/icons/CompanyIcon';
import FilterIcon from '../../../../components/icons/FilterIcon';
import Search from '../../../../components/Input/Search';
import Table from '../../../../components/Table';
import WrapperWhiteBox from '../../../../components/WrapperWhiteBox';
import { useGetContractCount } from '../../../../endpoints/multiaccess/useGetContractCount';
import { Navigation } from '../../../../navigation';
import { TextColored } from '../../../../style/utils';
import { sortByString } from '../../../../utils';

const MultiAccessClientList = () => {
  const [valueSearch, setValueSearch] = React.useState('');
  const [t] = useTranslation();
  const themeContext = React.useContext(ThemeContext);
  const history = useHistory();
  const queryClient = useQueryClient();
  const usersList = queryClient.getQueryData('get-multi-access-users');
  const { data: contractsCount, isFetching: isContractCountFetching } = useGetContractCount();

  const header = React.useMemo(() => {
    if (!usersList) {
      return [];
    }

    return [
      {
        title: t('multi-access-client-list-reference'),
        dataIndex: 'multiaccess',
        render: (multiaccess) => multiaccess.clientReference,
      },
      {
        title: t('multi-access-client-list-company'),
        dataIndex: 'company',
        sorter: (a, b) => sortByString(a.company, b.company),
        defaultSortOrder: 'ascend',
        render: (company) => <TextColored>{company}</TextColored>,
      },
      {
        title: t('multi-access-client-list-name'),
        key: t('multi-access-client-list-name'),
        render: (record) => (
          <div>
            {record.firstName} {record.lastName}
          </div>
        ),
      },
      {
        title: t('multi-access-client-list-access'),
        dataIndex: 'clientCanLogin',
        align: 'center',
        sorter: (a, b) => {
          if (a.clientCanLogin === b.clientCanLogin) return 0;
          return a.clientCanLogin ? -1 : 1;
        },
        render: (clientCanLogin) => t(`multi-access-list-client-can-login-${clientCanLogin ? 'true' : 'false'}`),
      },
      {
        title: t('multi-access-client-list-manage'),
        dataIndex: 'multiaccess',
        sorter: (a, b) => sortByString(a.multiaccess.contractsManagedBy, b.multiaccess.contractsManagedBy),
        render: (multiaccess) => t(`global-user-${multiaccess.contractsManagedBy}`),
      },
      {
        title: t('multi-access-client-list-current-contract'),
        dataIndex: 'contractCount',
        align: 'center',
      },
      {
        align: 'right',
        render: () => <ArrowRight color={themeContext.colors.baseColor} />,
      },
    ];
  }, [t, themeContext.colors.baseColor, usersList]);

  const dataSource = React.useMemo(() => {
    if (!usersList || isContractCountFetching) return [];

    return usersList.list
      .map((item) => {
        const findContractCount = contractsCount.find((contract) => contract.userId === item.id);

        return {
          ...item,
          key: item.id,
          name: `${item.firstName} ${item.lastName}`,
          contractCount: findContractCount?.contracts || 0,
          clientCanLogin: item.multiaccess.clientCanLogin,
        };
      })
      .filter((item) => {
        if (!valueSearch) return true;

        return [item.company, item.name].some((value) => value.match(new RegExp(valueSearch, 'gmi')));
      });
  }, [contractsCount, isContractCountFetching, usersList, valueSearch]);

  const handleClickSearch = React.useCallback(
    (e) => {
      setValueSearch(e.target.value);
    },
    [setValueSearch],
  );

  return (
    <WrapperWhiteBox
      extra={
        <Button
          disabled={!usersList?.canCreateClientAccount ?? true}
          onClick={() => history.push(Navigation.MULTI_ACCESS_CREATE)}
        >
          {t('multi-access-list-add-client')}
        </Button>
      }
      icon={<CompanyIcon />}
      title={t('multi-access-list-title')}
    >
      <Space direction="vertical" size="large">
        <Hr />

        <Row align="middle">
          <Col sm={1} xs={3}>
            <FilterIcon />
          </Col>
          <Col sm={7} xs={21}>
            <Search onChange={handleClickSearch} />
          </Col>
        </Row>

        <div>
          <Hr />
          <Table
            columns={header}
            dataSource={dataSource}
            loading={isContractCountFetching}
            onRow={(record) => ({
              onClick: () => {
                history.push(Navigation.MULTI_ACCESS_DETAILS.replace(':userId', record.id));
              },
            })}
          />
        </div>
      </Space>
    </WrapperWhiteBox>
  );
};

export default MultiAccessClientList;
