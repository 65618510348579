import { useRouteMatch } from 'react-router';

const useGetPreviousUrl = () => {
  const { url } = useRouteMatch();
  const arr = url.split('/');
  arr.pop();

  return arr.join('/');
};

export default useGetPreviousUrl;
