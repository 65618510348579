import * as React from 'react';

const Belgium = (props) => (
  <svg height={15.1} style={{ display: 'block' }} width={23.15} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M0 0h23.15v15.1H0Z" data-name="Trac\xE9 346" fill="#ef3340" />
    <path d="M0 0h15.433v15.1H0Z" data-name="Trac\xE9 347" fill="#fdda25" />
    <path d="M0 0h7.717v15.1H0Z" data-name="Trac\xE9 348" />
  </svg>
);

export default Belgium;
