import React from 'react';
import styled, { css } from 'styled-components';

const Hr = ({ className }) => <div className={className} />;

export default styled(Hr)`
  ${({ theme: { colors } }) => css`
    height: 1px;
    width: 100%;
    border-top: 1px solid ${colors.gray};
  `}
`;
