import React from 'react';
import { useTranslation } from 'react-i18next';

import Wrapper from '../Wrapper';

const WarningBox = ({ title, description }) => {
  const [t] = useTranslation();

  return (
    <Wrapper alert={t('global-alert')} title={title}>
      {description}
    </Wrapper>
  );
};

export default WarningBox;
