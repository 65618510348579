import { message } from 'antd';
import { useQuery } from 'react-query';

import { useApi } from '../../context/ApiContext';

export const useGetCanDeleteUser = (userId) => {
  const client = useApi();

  return useQuery(
    'can-delete-user',
    () => client.get(`/multiaccess/user/canDeleteUser/${userId}`).then((res) => res.data),
    {
      onError() {
        message.error('Request failed to check if the user can be deleted');
      },
    },
  );
};
