import { Space } from 'antd';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { useGetKamListForKam } from '../../../endpoints/multiaccess/useGetKamListForKam';
import { useTransfersAccount } from '../../../endpoints/multiaccess/useTransfersAccount';
import { Navigation } from '../../../navigation';
import { TextRegular } from '../../../style/utils';
import Button from '../../Button';
import Hr from '../../Hr';
import TransfersAccountIcon from '../../icons/TransfersAccountIcon';
import Input from '../../Input';
import Modal from '../../Modal';

const ModalTransfersAccount = ({ className, title, onClose, userId, ...modalProps }) => {
  const [t] = useTranslation();
  const { mutate: transfersAccount, isLoading } = useTransfersAccount();
  const { data } = useGetKamListForKam();
  const { register, control, handleSubmit, errors } = useForm();
  const history = useHistory();

  React.useEffect(() => {
    register({ name: 'kam' }, { required: true });
  }, [register]);

  const onSubmit = React.useCallback(
    (values) => {
      transfersAccount(
        { clientId: userId, newKamId: values.kam },
        {
          onSuccess() {
            history.push(Navigation.MULTI_ACCESS);
          },
        },
      );
    },
    [history, transfersAccount, userId],
  );

  return (
    <Modal
      className={className}
      size="large"
      onCancel={onClose}
      {...modalProps}
      icon={<TransfersAccountIcon />}
      title={title}
    >
      <Space direction="vertical" size="large">
        <TextRegular>{t('modal-transfert-account-description-info')}</TextRegular>

        <Hr />

        <form onSubmit={handleSubmit(onSubmit)}>
          <Space direction="vertical" size="large">
            <Controller
              as={Input.Select}
              control={control}
              error={errors.kam}
              items={(data || []).map((item) => ({
                label: `${item.lastName} ${item.firstName}`,
                value: item.id,
                key: item.id,
              }))}
              label={t('modal-transfert-account-select-label')}
              name="kam"
              sizeInput={{
                xs: 24,
                md: 8,
              }}
            />

            <Hr />

            <Space size="middle">
              <Button variant="outlined" onClick={onClose}>
                {t('global-close')}
              </Button>
              <Button isLoading={isLoading} type="submit">
                {t('global-transfert')}
              </Button>
            </Space>
          </Space>
        </form>
      </Space>
    </Modal>
  );
};

export default styled(ModalTransfersAccount)``;
