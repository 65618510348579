import * as React from 'react';

const Italy = (props) => (
  <svg height={15.047} style={{ display: 'block' }} width={23.148} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M0 0h7.717v15.047H0z" fill="#009930" />
    <path d="M7.717 0h7.715v15.047H7.717z" fill="#fff" />
    <path d="M15.432 0h7.717v15.047h-7.717z" fill="#c1343b" />
  </svg>
);

export default Italy;
