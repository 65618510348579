import React from 'react';

const ValidationONIcon = ({ color }) => (
  <svg
    style={{ display: 'block' }}
    data-testid="validation-on-icon"
    height={28}
    viewBox="0 0 28 28"
    width={28}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(-1183 -450)">
      <circle cx={14} cy={14} data-name="Ellipse 50" fill={color} r={14} transform="translate(1183 450)" />
      <g data-name="Groupe 1527">
        <g data-name="Groupe 1379">
          <path
            d="M1191.136 463.968l4.406 4.31 8.088-8.674"
            data-name="Trac\xE9 167"
            fill="none"
            stroke="#fff"
            strokeMiterlimit={10}
            strokeWidth={3}
          />
        </g>
      </g>
    </g>
  </svg>
);

export default ValidationONIcon;
