import qs from 'qs';
import { useCallback } from 'react';

import useGetPortalApp from './useGetPortalApp';

const useRedirectToPortal = () => {
  const portalAppUrl = useGetPortalApp();

  const redirectToPortal = useCallback(
    (route = '/', queryParams) => {
      const params = qs.stringify({ ...queryParams });
      const url = `${portalAppUrl}${route}${params ? `?${params}` : ''}`;

      return {
        url,
        go: () => window.location.assign(url),
      };
    },
    [portalAppUrl],
  );

  return { redirectToPortal };
};

export default useRedirectToPortal;
