import { InputNumber as InputNumberAnt } from 'antd';
import React from 'react';
import styled, { css } from 'styled-components';

import useDesktop from '../../../hooks/useDesktop';
import { callAll } from '../../../utils';
import { WrapperInputText } from './InputNumber.styled';

const InputNumber = React.forwardRef(({ className, customOnChange, ...input }, ref) => {
  const isDesktop = useDesktop();

  return (
    <WrapperInputText className={className} ref={ref}>
      <InputNumberAnt
        onChange={callAll(customOnChange, input.onChange)}
        {...input}
        size={isDesktop ? 'large' : 'middle'}
      />
    </WrapperInputText>
  );
});

export default styled(InputNumber)`
  ${({ theme: { colors, fontSize }, error }) => css`
    .ant-input-number {
      min-width: 70px;
      background: ${colors.whiteDark};
      color: ${colors.grayDark};
      font-size: ${fontSize.regular};

      ${error && 'border: 1px solid red'};

      &:focus,
      &:hover {
        ${error && 'border: 1px solid red'};
        ${error && 'box-shadow: 0 0 0 2px #f1d8d8'};
      }
    }

    .ant-input-number-focused {
      ${error && 'box-shadow: 0 0 0 2px #f1d8d8'};
    }
  `}
`;
