import React from 'react';

const MiniClockIcon = (props) => (
  <svg style={{ display: 'block' }} xmlns="http://www.w3.org/2000/svg" width={13.975} height={13.746} {...props}>
    <g data-name="Groupe 2298">
      <g data-name="Groupe 2299">
        <g data-name="Groupe 2298">
          <ellipse
            data-name="Ellipse 47"
            cx={6.388}
            cy={6.273}
            rx={6.388}
            ry={6.273}
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeWidth={1.2}
            transform="translate(.6 .6)"
          />
        </g>
      </g>
      <path
        data-name="Trac\xE9 333"
        d="M9.7 8.24L6.988 6.874V2.299"
        fill="none"
        stroke="#fff"
        strokeLinejoin="round"
        strokeWidth={1.2}
      />
    </g>
  </svg>
);

export default MiniClockIcon;
