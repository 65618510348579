import { useMutation, useQueryClient } from 'react-query';

import { useApi } from '../../context/ApiContext';

export const useUpdateConsumption = (contractId) => {
  const client = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    (payload) => client.post(`/objectives/consumption/${contractId}`, payload).then((res) => res.data),
    {
      onSuccess() {
        queryClient.invalidateQueries(['get-contracts', contractId]);
        queryClient.invalidateQueries(['get-contracts-product-combined', contractId]);
        queryClient.invalidateQueries(['get-contract-detail-chart', contractId]);
        queryClient.removeQueries(['get-consumption', contractId]);
      },
    },
  );
};
