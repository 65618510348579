import React from 'react';

function HistoricNewContractIcon() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13.362}
    height={17.898}
    style={{marginTop: "5px"}}
  >
    <g data-name="Groupe 3416-2">
      <path
        data-name="Trac\xE9 368"
        d="M9.322 0H0v17.9h13.362V4.535Zm1.268 14.711H2.644v-1.552h7.946Zm0-3.176H2.644V9.987h7.946Zm0-3.176H2.644V6.808h7.946Z"
        fill="#17181b"
      />
    </g>
  </svg>
  );
}

export default HistoricNewContractIcon;
