import { yupResolver } from '@hookform/resolvers';
import { Space } from 'antd';
import { useStateMachine } from 'little-state-machine';
import moment from 'moment';
import React, { FC, useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import getFormattedPeriodDate from '../../../../../../components/FormatPeriodDate/getFormattedPeriodDate';
import Input from '../../../../../../components/Input';
import Table from '../../../../../../components/Table';
import { updateNewObjectivesState } from '../../../../../../storeForm/updateState';
import { yupNumberTransform } from '../../../../../../utils/yup';

type Props = {
  fixationGranularity: string;
  footer: React.ReactElement;
  indexLabels: string[];
  nextStep?: () => void;
  updateStateForm: (data: any) => void;
  periods: string[] | { periodStart: string; amounts: number[] }[];
  energyUnit: string;
  indexCount: number;
};

const ObjectivesIndexTable: FC<Props> = ({
  updateStateForm,
  fixationGranularity,
  indexLabels,
  periods,
  nextStep,
  energyUnit,
  indexCount,
  footer,
}) => {
  const [t] = useTranslation();
  const { state } = useStateMachine({ updateNewObjectivesState });

  const fieldsSchema = yup.object().shape({
    amountsByPeriod: yup.array().of(
      yup.object().shape({
        periodStart: yup.string(),
        amounts: yup.array().of(yupNumberTransform),
      }),
    ),
  });

  const { control, errors, handleSubmit, register } = useForm({
    resolver: yupResolver(fieldsSchema),
  });
  const { fields, append } = useFieldArray({
    control,
    name: 'amountsByPeriod',
    keyName: 'amountsByPeriodId',
  });

  useEffect(() => {
    periods
      .sort((a, b) => {
        if (typeof a === 'string') return (moment(a).format('YYYYMMDD') as any) - (moment(b).format('YYYYMMDD') as any);
        return (moment(a.periodStart).format('YYYYMMDD') as any) - (moment(b.periodStart).format('YYYYMMDD') as any);
      })
      .forEach((period, index) => {
        append(
          {
            periodStart: typeof period === 'string' ? period : period.periodStart,
            amounts:
              typeof period === 'string'
                ? state.objectives.consumption.amountsByPeriod[index]?.amounts
                : period.amounts,
          },
          false,
        );
      });
  }, [append, indexCount, periods, state.objectives.consumption.amountsByPeriod]);

  const headers = React.useMemo(() => {
    const columns = [
      {
        title: t('objectives-index-table-table-period'),
        width: 190,
        render(record, _, index) {
          return (
            <>
              {getFormattedPeriodDate({
                format: 'MMM YYYY',
                date: record.periodStart,
                granularity: fixationGranularity,
              })}

              <input
                defaultValue={record.periodStart}
                name={`amountsByPeriod[${index}].periodStart`}
                ref={register()}
                type="hidden"
              />
            </>
          );
        },
      },
    ];
    if (indexCount === 1) {
      columns.push({
        title: t('objectives-index-table-table-quotation'),
        width: 80,
        render(record, _, index) {
          return (
            <Controller
              as={Input.Text}
              control={control}
              defaultValue={record?.amounts?.[0]}
              displayError={false}
              error={errors?.amountsByPeriod?.[index]?.amounts?.[0]}
              name={`amountsByPeriod[${index}].amounts[0]`}
              placeholder={t(`global-energy-${energyUnit}`)}
              width={80}
            />
          );
        },
      });
    } else {
      indexLabels.forEach((title, indexLabel) => {
        columns.push({
          title,
          width: 90,
          render(record, _, index) {
            return (
              <Controller
                as={Input.Text}
                control={control}
                defaultValue={record?.amounts?.[indexLabel]}
                displayError={false}
                error={errors?.amountsByPeriod?.[index]?.amounts?.[indexLabel]}
                name={`amountsByPeriod[${index}].amounts[${indexLabel}]`}
                placeholder={t(`global-energy-${energyUnit}`)}
                width={90}
              />
            );
          },
        });
      });
    }

    return columns;
  }, [t, indexCount, fixationGranularity, register, errors?.amountsByPeriod, control, energyUnit, indexLabels]);

  const onSubmit = React.useCallback(
    (values) => {
      updateStateForm({
        consumption: {
          amountsByPeriod: values.amountsByPeriod,
        },
        contract: {
          indexLabels,
          indexCount,
          contractIsMultiIndex: indexCount > 1,
        },
      });
      if (nextStep) nextStep();
    },
    [indexCount, indexLabels, nextStep, updateStateForm],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Space direction="vertical" size="middle">
        <Table
          extraColumn
          noBgHover
          columns={headers}
          dataSource={fields}
          errors={errors}
          isClickable={false}
          pagination={false}
          rowKey="periodStart"
        />
        {footer}
      </Space>
    </form>
  );
};

export default ObjectivesIndexTable;
