import React from 'react';

function MarketTrendGasIcon() {
  return (
    <svg
      style={{ display: 'block' }}
      xmlns="http://www.w3.org/2000/svg"
      width="11.695"
      height="18.683"
      viewBox="0 0 11.695 18.683"
      data-testid="test-icon-gas"
    >
      <g data-name="picto-market trend gaz" transform="translate(-1089 -1392)">
        <g data-name="Groupe 2169" transform="translate(1089 1392)">
          <path
            fill="#fff"
            d="M4422.571 1214.507a2.731 2.731 0 01-1.153 2.162.377.377 0 01-.1.057 4.1 4.1 0 01-3.648.135 2.735 2.735 0 01-1.47-2.354c0-1.538 3.186-7.307 3.186-7.307s3.185 5.769 3.185 7.307z"
            data-name="Tracé 335"
            transform="translate(-4413.541 -1198.541)"
          />
          <path
            fill="#de6811"
            d="M4422.777 1203.959a5.249 5.249 0 01-4.132 4.9 2.727 2.727 0 001.471-2.352c0-1.539-3.187-7.308-3.187-7.308s-3.187 5.768-3.187 7.308a2.728 2.728 0 001.47 2.352 5.25 5.25 0 01-4.131-4.9c0-2.828 5.848-13.421 5.848-13.421s5.848 10.593 5.848 13.421z"
            data-name="Tracé 85"
            transform="translate(-4411.082 -1190.538)"
          />
        </g>
      </g>
    </svg>
  );
}

export default MarketTrendGasIcon;
