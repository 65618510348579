import { Col, Row, Space } from 'antd';
import moment from 'moment';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { TextBoldSmallExtra, TextRegularBold } from '../../style/utils';
import { getTodayDate } from '../../utils/fixations';
import { getFlag } from '../../utils/flag';
import MarketTrendGasIcon from '../icons/MarketTrendGasIcon';
import MarketTrendPowerIcon from '../icons/MarketTrendPowerIcon';
import Input from '../Input';
import { WrapperCountry } from '../Modals/ModalMarketTrend/ItemMarketTrend/ItemMarketTrend';

const MarketItem = ({
  className,
  name,
  control,
  errors,
  setValue,
  country,
  energy,
  register,
  getValues,
  clearErrors,
  field,
  data,
}) => {
  const [t] = useTranslation();
  const enabled = getValues(`${name}.enabled`);
  const dateFormat = 'YYYY/MM/DD';

  React.useEffect(() => {
    const enabled = getValues(`${name}.enabled`);
    if (!enabled) return undefined;
    setValue(`${name}.marketTrendsPartner`, true);
  }, [enabled, name, setValue, getValues]);

  React.useEffect(() => {
    register(`${name}.period`, {
      required: enabled,
      validate: (value) => (!enabled ? undefined : value.length > 0),
    });
    register(`${name}.marketTrendsPartner`);
    // register(`${name}.marketTrendsCompany`, { validate: validationCheckboxes });
    // register(`${name}.marketTrendsUser`);
  }, [enabled, name, register]);

  React.useEffect(() => {
    register(`${name}.energy`);
    register(`${name}.country`);
    register(`${name}.contracts`, { required: !!enabled });
  }, [enabled, name, register]);

  React.useEffect(() => {
    setValue(`${name}.energy`, energy);
    setValue(`${name}.country`, country);
  }, [country, energy, name, setValue]);

  return (
    <Space className={className} direction="vertical" size="middle">
      <Row gutter={[0, 16]} justify="space-between">
        <Col>
          <Controller
            as={Input.Switch}
            control={control}
            customOnChange={(value) => {
              if (!value) {
                clearErrors([
                  `${name}.period`,
                  `${name}.contracts`,
                  `${name}.marketTrendsPartner`,
                  `${name}.marketTrendsCompany`,
                ]);
                setValue(`${name}.period`, undefined);
                setValue(`${name}.marketTrendsPartner`, true);
                // setValue(`${name}.marketTrendsCompany`, false);
                // setValue(`${name}.marketTrendsUser`, false);
                setValue(`${name}.contracts`, undefined);
              } else {
                clearErrors(['multiaccess.clientMarkets']);
              }
            }}
            // defaultChecked
            defaultValue={data.length === 1 ? true : field.enabled}
            name={`${name}.enabled`}
          />
        </Col>
        <Col span={24}>
          <Space size="middle">
            <Space>
              <WrapperCountry>{getFlag(country)}</WrapperCountry>
              <TextRegularBold>{t(`country-${country}`)}</TextRegularBold>
            </Space>
            <Space>
              {energy === 'gas' ? <MarketTrendGasIcon /> : <MarketTrendPowerIcon />}
              <TextRegularBold>{t(`global-${energy}`)}</TextRegularBold>
            </Space>
          </Space>
        </Col>
      </Row>
      <TextBoldSmallExtra>{t('multi-access-period-end')}</TextBoldSmallExtra>
      <Controller
        as={Input.RangePicker}
        control={control}
        customOnChange={() => {
          clearErrors(['multiaccess.clientMarkets']);
        }}
        defaultValue={field?.period?.length !== 0 ? field?.period : [moment(getTodayDate(), dateFormat)]}
        disabled={!enabled}
        error={errors?.period}
        format="DD/MM/YYYY"
        name={`${name}.period`}
      />

      <Controller
        as={Input.Number}
        control={control}
        defaultValue={field.contracts ? field?.contracts : 1}
        error={errors?.contracts}
        label={<TextRegularBold>{t('market-item-number-contracts')}</TextRegularBold>}
        min={1}
        name={`${name}.contracts`}
      />
    </Space>
  );
};

export default styled(MarketItem)`
  padding: 1.5rem;
  border: 1px solid #ced5db;
`;
