import { Breadcrumb as ANTBreadcrumbs } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Navigation } from '../../navigation';
import { getHue, TextColored } from '../../style/utils';
import ContractDetails from './CustomBreadcrumb/ContractDetails';
import GroupDetails from './CustomBreadcrumb/GroupDetails';
import KamClientDetails from './CustomBreadcrumb/KamClientDetails';
import MarketTrendDetails from './CustomBreadcrumb/MarketTrendDetails';
import MultiAccessListBD from './CustomBreadcrumb/MultiAccessListBD';
import ProductDetailsBD from './CustomBreadcrumb/ProductDetailsBD';

const CustomTextColored = styled(TextColored)`
  ${({ theme: { colors, fontSize }, disabled }) => {
    const hoverColor = `hsl(${getHue(colors.baseColor)}, 48%, 49%)`;

    return css`
      font-size: ${fontSize.extraSmall};
      color: ${disabled ? colors.grayDark : colors.baseColor};
      display: inline-block;

      :hover {
        text-decoration: ${disabled ? 'none' : 'underline'};
        color: ${disabled ? colors.grayDark : hoverColor};
      }
    `;
  }}
`;

const routes = [
  { path: Navigation.CONTRACTS, breadcrumb: 'breadcrumb-contract' },
  { path: Navigation.CONTRACT_NEW, breadcrumb: 'breadcrumb-contract-new' },
  { path: Navigation.HISTORIC, breadcrumb: 'breadcrumb-contract-historic' },
  { path: Navigation.FIXATION, breadcrumb: 'breadcrumb-fixations' },
  { path: Navigation.BLOCKFIXATION, breadcrumb: 'breadcrumb-fixations' },
  { path: Navigation.OBJECTIVES, breadcrumb: 'breadcrumb-objectives' },
  { path: Navigation.CONTRACTS_DETAILS, breadcrumb: ContractDetails },
  { path: Navigation.PRODUCT_DETAILS, breadcrumb: ProductDetailsBD },
  { path: Navigation.MARKET_TREND, breadcrumb: 'breadcrumb-mt' },
  { path: Navigation.MARKET_TREND_NEW, breadcrumb: 'breadcrumb-mt-new' },
  { path: Navigation.MARKET_TREND_BACKTESTING, breadcrumb: 'breadcrumb-mt-backtesting' },
  { path: Navigation.MARKET_TREND_DETAILS, breadcrumb: MarketTrendDetails },
  { path: Navigation.HELP, breadcrumb: 'breadcrumb-help' },
  { path: Navigation.BUDGET, breadcrumb: 'breadcrumb-budget' },
  { path: Navigation.DASHBOARD, breadcrumb: 'breadcrumb-dashboard' },
  { path: Navigation.GROUP_CREATE, breadcrumb: 'breadcrumb-group-create' },
  { path: Navigation.GROUP_LIST, breadcrumb: 'breadcrumb-group-list' },
  { path: Navigation.GROUP_DETAILS, breadcrumb: GroupDetails },
  { path: Navigation.MULTI_ACCESS_CREATE, breadcrumb: 'breadcrumb-create' },
  { path: Navigation.MULTI_ACCESS_EDIT, breadcrumb: 'breadcrumb-edit' },
  { path: Navigation.MULTI_ACCESS_DETAILS, breadcrumb: KamClientDetails },
  { path: Navigation.MULTI_ACCESS, breadcrumb: MultiAccessListBD },
];

const Breadcrumb = ({ className, breadcrumbs }) => {
  const [t] = useTranslation();

  return (
    <ANTBreadcrumbs aria-label="breadcrumb" className={className} separator=">">
      {breadcrumbs.map(({ match, breadcrumb }, index) => {
        const isComponent = breadcrumb.type !== 'span';
        const isLast = index === breadcrumbs.length - 1;
        let textToDisplay;

        if (isComponent) textToDisplay = breadcrumb;
        else textToDisplay = t(breadcrumb.props.children);

        return isLast ? (
          <ANTBreadcrumbs.Item key={match.url}>
            <CustomTextColored disabled>{textToDisplay}</CustomTextColored>
          </ANTBreadcrumbs.Item>
        ) : (
          <ANTBreadcrumbs.Item key={match.url}>
            <Link to={match.url}>
              <CustomTextColored>{textToDisplay}</CustomTextColored>
            </Link>
          </ANTBreadcrumbs.Item>
        );
      })}
    </ANTBreadcrumbs>
  );
};

export default styled(withBreadcrumbs(routes)(Breadcrumb))`
  width: 85%;
  padding: 20px 0;

  ol {
    margin: 0;
    padding: 0;
  }
`;
