import React from 'react';

const HistoricOptimizationDateUpcomingIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={26.333}
        height={26.667}
        viewBox="0 0 160 143"
        {...props}
    >
        <path d="M72.5 1.4c-1.6.7-4 2.4-5.1 3.7-1.2 1.3-16.7 27.6-34.5 58.4C2.5 116.1.6 119.7.3 125c-.5 7.2 2.4 12.4 8.6 15.7l4.4 2.3h66.1c62 0 66.3-.2 70.1-1.9 7.1-3.2 11.2-10.4 10-17.5-.4-2.8-10.6-21.4-31.2-57.1C91 1.7 92.6 4.4 87.9 2c-4.4-2.3-11-2.5-15.4-.6zm11.3 11.3c3.7 3.8 65.2 110.6 65.2 113.2 0 1.2-.9 3-2 4.1-2 2-3.3 2-67.3 2-63.8 0-65.4 0-67.2-2-.9-1-1.7-3-1.7-4.3-.1-3.5 63.4-112.9 66.3-114.4 3.3-1.7 3.7-1.7 6.7 1.4z" />
        <path d="M74.6 48.5C65.4 51.8 61 58.4 61 68.7c0 5.6-.2 6.3-2 6.3-1.9 0-2 .7-2 21v21h46V96c0-19.9-.1-21-1.9-21-1.7 0-1.9-.9-2.2-7.9-.4-7.1-.7-8.3-3.5-11.7-5.4-6.7-13.8-9.5-20.8-6.9zm11.4 8c4 2.1 6 6.3 6 13V75H67.8l.4-6.6c.4-6.2.7-6.9 4.1-10 4.2-3.8 8.7-4.5 13.7-1.9zm-3 38v6.5h-7v-5.8c0-3.2.3-6.2.7-6.5.3-.4 1.9-.7 3.5-.7 2.8 0 2.8 0 2.8 6.5z" />
    </svg>
);

export default HistoricOptimizationDateUpcomingIcon;
