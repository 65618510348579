import { DatePicker as DatePickerAnt } from 'antd';
import { DatePicker as DatePickerMobile } from 'antd-mobile';
import moment from 'moment';
import React, { useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { callAll } from '../../../utils/index';
import InvisibleButton from '../../InvisibleButton';

const DatePicker = React.forwardRef(({ className, ...datePickerProps }, ref) => {
  const formatDate = useMemo(() => 'LL', []);
  const [t] = useTranslation();
  const [visible, setVisible] = React.useState(false);
  const [dateSelected, setDateSelected] = React.useState(null);

  React.useEffect(() => {
    if (dateSelected) {
      const date = moment(moment(dateSelected));
      callAll(datePickerProps.onChange, datePickerProps.customOnChange)(date);
      setDateSelected(null);
    }
  }, [datePickerProps, datePickerProps.onChange, dateSelected]);

  const WrapperDatePicker = React.useMemo(() => {
    if (isMobile) {
      return (
        <InvisibleButton
          onClick={() => {
            setVisible(true);
          }}
        >
          <DatePickerAnt format={formatDate} {...datePickerProps} disabled ref={ref} />
        </InvisibleButton>
      );
    }
    return <DatePickerAnt format={formatDate} {...datePickerProps} ref={ref} />;
  }, [datePickerProps, formatDate, ref]);

  return (
    <div className={className}>
      {WrapperDatePicker}
      {isMobile && (
        <DatePickerMobile
          cancelText={t('date-picker-cancel')}
          confirmText={t('date-picker-save')}
          visible={visible}
          onClose={() => {
            setVisible(false);
          }}
          onConfirm={setDateSelected}
        />
      )}
    </div>
  );
});

DatePicker.displayName = 'DatePicker';

export default styled(DatePicker)`
  ${({ theme: { colors, fontSize }, error }) => css`
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    .ant-picker {
      ${error && 'border: 1px solid red'};
    }
    .ant-picker-focused {
      ${error && 'box-shadow: 0 0 0 2px #f1d8d8'};
      ${error && 'border-color: red'};
    }

    .ant-picker:hover {
      ${error && 'border-color: red'};
    }

    ${InvisibleButton} {
      width: 100%;
    }

    .ant-picker-input > input {
      line-height: 1.91;
    }

    .ant-picker-large {
      height: 40px;
    }

    .ant-picker {
      width: 100%;
      background: ${colors.whiteDark};

      .ant-picker-input > input[disabled] {
        color: unset;
      }
      .ant-picker-input > input {
        color: ${colors.grayDark};
        font-size: ${fontSize.regular};
      }
    }
  `}
`;
