import breakpoints from './breakpoints';
import colors from './colors';
import fontSize from './fontSize';

const theme = {
  breakpoints,
  colors,
  fontSize,
  logo: {
    dashboard: '/images/logo-yem.svg',
  },
};

export const overrideTheme = (customLogo, customColors) => ({
  ...theme,
  logo: {
    dashboard: customLogo,
  },
  colors: {
    ...theme.colors,
    ...customColors,
  },
});

export default theme;
