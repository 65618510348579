import axios from 'axios';
import Cookies from 'js-cookie';
import qs from 'qs';
import React, { useContext, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import useGetFollowApp from '../hooks/useGetFollowApp';
import useGetPortalApp from '../hooks/useGetPortalApp';
import { useTenant } from './TenantContext';

const ApiContext = React.createContext();

const ApiProvider = ({ children }) => {
  const portalAppUrl = useGetPortalApp();
  const followAppUrl = useGetFollowApp();
  const tokenName = process.env.REACT_APP_JWT_COOKIE || 'yem_jwt';
  const location = useLocation();
  const history = useHistory();
  const { token: tokenUrl } = qs.parse(history.location.search, { ignoreQueryPrefix: true });
  const token = useMemo(() => tokenUrl || Cookies.get(tokenName), [tokenName, tokenUrl]);
  const { env } = useTenant();

  const client = useMemo(() => {
    const instance = axios.create({ baseURL: env.REACT_APP_BACKEND_FOLLOW_URL });

    if (token) {
      instance.defaults.headers.common.Authorization = `Bearer ${token}`;
    }

    return instance;
  }, [env.REACT_APP_BACKEND_FOLLOW_URL, token]);

  if (!token && !location.pathname.includes('/template')) {
    window.location.assign(`${portalAppUrl}/login?callback=${followAppUrl}${location.pathname}`);
  }

  return <ApiContext.Provider value={client}>{children}</ApiContext.Provider>;
};

function useApi() {
  const context = useContext(ApiContext);
  if (context === undefined) {
    throw new Error('useApi must be used within a ApiProvider');
  }
  return context;
}

export { ApiContext, ApiProvider, useApi };
