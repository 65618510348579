import './utils/yup/setupYup';

import { createStore, StateMachineProvider } from 'little-state-machine';
import React, { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
// import { ReactQueryDevtools } from 'react-query/devtools';
import { Route, Switch } from 'react-router';
import { Router } from 'react-router-dom';
import Analytics from 'react-router-ga';

import { ApiProvider } from './context/ApiContext';
import { LocalProvider } from './context/LocalContext';
import { TenantProvider } from './context/TenantContext';
import { UserInfoProvider } from './context/UserInfoContext';
import Routes from './routes';
import history from './routes/history';
import mtUserState from './storeForm/mtUserState';
import multiAccessClientState from './storeForm/multiAccessClientState';
import newContractState from './storeForm/newContractState';
import objectivesState from './storeForm/objectivesState';
import GlobalStyle from './style/globalStyle';
import PageHistoricTemplate from './templates/Historic/PageHistoricTemplate';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const AnalyticsProvider = ({ children }) => {
  if (process.env.REACT_APP_ANALYTIC_ID && process.env.NODE_ENV === 'production') {
    return <Analytics id={process.env.REACT_APP_ANALYTIC_ID}>{children}</Analytics>;
  }
  return <>{children}</>;
};

createStore({
  mtUser: mtUserState,
  newContractForm: newContractState,
  multiAccessClient: multiAccessClientState,
  objectives: objectivesState,
});

// Clear all form when refreshing the page
window.onunload = () => {
  sessionStorage.clear();
};

const App = () => {
  React.useEffect(
    () => () => {
      sessionStorage.removeItem('travelInTime');
    },
    [],
  );

  return (
    <Router history={history}>
      <QueryClientProvider client={queryClient}>
        <TenantProvider>
          <ApiProvider>
            <GlobalStyle />

            <Suspense fallback=" ">
              {/* <ReactQueryDevtools /> */}

              <StateMachineProvider>
                <AnalyticsProvider>
                  <Switch>
                    <Route exact component={PageHistoricTemplate} path="/template/historic-page" />

                    <UserInfoProvider>
                      <LocalProvider>
                        <Routes />
                      </LocalProvider>
                    </UserInfoProvider>
                  </Switch>
                </AnalyticsProvider>
              </StateMachineProvider>
            </Suspense>
          </ApiProvider>
        </TenantProvider>
      </QueryClientProvider>
    </Router>
  );
};

export default App;
