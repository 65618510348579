import { Space } from 'antd';
import capitalize from 'lodash/capitalize';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Line from '../../../../components/Chartjs/Line';
import Collapse from '../../../../components/Collapse';
import SafeHTMLTranslate from '../../../../components/SafeHTMLTranslate/SafeHTMLTranslate';
import Table from '../../../../components/Table';
import { Label, TextRegular } from '../../../../style/utils';

const MarketTrendHistoricTemplate = ({
  history,
  trends,
  energyPriceUnit,
  header,
  description,
  hasGraph = true,
  trendtype,
  keyElem,
}) => {
  const [activeKey] = useState(keyElem);
  const [t] = useTranslation();

  const unit = useMemo(() => t(`global-energy-${energyPriceUnit}`), [t, energyPriceUnit]);

  const columnsNameTendency = useMemo(
    () => [
      t('market-trend-historic-date'),
      t('market-trend-historic-value', { unit }),
      t('market-trend-historic-min', { unit }),
      t('market-trend-historic-max', { unit }),
    ],
    [t, unit],
  );

  const widthTD = useMemo(() => [45, 18, 18, 18], []);

  const headerTableTendency = useMemo(
    () =>
      columnsNameTendency.map((text, index) => ({
        title: <div>{capitalize(text)}</div>,
        dataIndex: text,
        key: text,
        width: `${widthTD[index]}%`,
        align: index === 0 ? 'left' : 'center',
      })),
    [columnsNameTendency, widthTD],
  );

  const dataTableTendency = useMemo(
    () =>
      trends.map((item, index) => ({
        [columnsNameTendency[0]]: moment(item.date).format('dddd DD MMMM YYYY'),
        [columnsNameTendency[1]]: item.value,
        [columnsNameTendency[2]]: item.min,
        [columnsNameTendency[3]]: item.max,
        key: index,
      })),
    [columnsNameTendency, trends],
  );

  return (
    <Collapse collapseProps={{ activeKey }} panelProps={{ key: keyElem, header, showArrow: false }}>
      <div style={{ padding: '1rem' }}>
        <Space direction="vertical">
          {hasGraph && (
            <div style={{ width: '82%' }}>
              <Line energyPriceUnit={energyPriceUnit} history={history} trends={trends} />
            </div>
          )}
          <Space direction="vertical">
            <Label>{t('market-trend-historic-title')}</Label>
            <TextRegular>
              <SafeHTMLTranslate textBrut={description} />
            </TextRegular>
          </Space>
          {trendtype === 'user' && (
            <Table
              noBgHover
              columns={headerTableTendency}
              dataSource={dataTableTendency}
              isClickable={false}
              size="small"
            />
          )}
        </Space>
      </div>
    </Collapse>
  );
};

export default MarketTrendHistoricTemplate;
