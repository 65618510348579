import { message } from 'antd';
import { useMutation } from 'react-query';

import { useApi } from '../../context/ApiContext';

export const useTransfersAllAccount = () => {
  const client = useApi();

  return useMutation(
    (values) => client.post('/multiaccess/manager/transferKamClientsToAnotherKam', values).then((res) => res.data),
    {
      onError() {
        message.error('Request failed to transfers the account');
      },
    },
  );
};
