import { message } from 'antd';
import { useMutation, useQueryClient } from 'react-query';

import { useApi } from '../../context/ApiContext';
import { useTenant } from '../../context/TenantContext';
import useCookie from '../../hooks/useCookie';

export const useDeleteGroup = (groupId) => {
  const client = useApi();
  const queryClient = useQueryClient();
  const { getCookie, removeCookie } = useCookie();
  const { env } = useTenant();

  return useMutation(
    () =>
      client
        .delete(`${env.REACT_APP_BACKEND_PORTAL_URL}/users/multi-access/kamGroup/${groupId}`)
        .then((res) => res.data),
    {
      onSuccess() {
        queryClient.invalidateQueries('group-list');
        const groupIdCookie = getCookie('groupId');

        if (groupId === groupIdCookie) {
          removeCookie('groupId');
        }
      },
      onError() {
        message.error('Request failed to delete the group');
      },
    },
  );
};
