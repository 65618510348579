import React from 'react';

function ArrowRight({ color = '#000' }) {
  return (
    <svg
      style={{ display: 'inline-block' }}
      height="11"
      viewBox="0 0 7.036 11"
      width="7.036"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g clipPath="url(#clip-path)" data-name="Groupe 1758" transform="rotate(-90 5.5 5.5)">
          <path
            d="M10.232.767L5.5 5.499.768.767"
            data-name="Tracé 249"
            fill="none"
            stroke={color}
            strokeMiterlimit="10"
            strokeWidth="2"
          />
        </g>
      </g>
    </svg>
  );
}

export default ArrowRight;
