import { PlusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Col, Row, Space, Tag as TagAnt } from 'antd';
import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { regexEmail } from '../../constants';
// eslint-disable-next-line import/no-cycle
import Input from '../Input';
import InvisibleButton from '../InvisibleButton';

const Tag = forwardRef(({ setTags, tags, placeholder, disabled, className }, ref) => {
  const { register, unregister, control, errors, handleSubmit } = useForm();

  const hasSomeEmails = tags.length > 0;

  const [inputVisible, setInputVisible] = useState(!hasSomeEmails);
  const inputRef = useRef();

  useEffect(() => {
    if (inputVisible) {
      register(
        { name: 'email' },
        {
          validate: (value) => {
            if (value && !value.match(regexEmail)) {
              return 'form-notifications-email-error-invalid';
            }
            return true;
          },
        },
      );
    } else {
      unregister('email');
    }
  }, [hasSomeEmails, inputVisible, register, setTags, tags.length, unregister]);

  useEffect(() => {
    if (inputVisible && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputVisible]);

  const handleClose = useCallback(
    (removedTag) => {
      setTags((tags) => tags.filter((tag) => tag !== removedTag));
    },
    [setTags],
  );

  const showInput = useCallback(() => {
    setInputVisible(true);
  }, []);

  const forMap = useCallback(
    (tag) => {
      const tagElem = (
        <TagAnt
          closable={!disabled}
          onClose={(e) => {
            e.preventDefault();
            handleClose(tag);
          }}
        >
          {tag}
        </TagAnt>
      );
      return <Col key={tag}>{tagElem}</Col>;
    },
    [disabled, handleClose],
  );

  const onSubmit = useCallback(
    ({ email }) => {
      if (!email) {
        setInputVisible(false);
        return true;
      }

      setTags((tags) => (email && tags.indexOf(email) === -1 ? [...tags, email] : tags));
      setInputVisible(false);
      inputRef.current.blur();
    },
    [setTags],
  );

  return (
    <div className={className}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Space direction="vertical" size="middle">
          {((inputVisible && !disabled) || tags.length) > 0 && (
            <Row gutter={[4, 8]}>
              {tags.length > 0 && tags.map(forMap)}
              {inputVisible && !disabled && (
                <Row span={12}>
                  <Col>
                    <Controller
                      autoFocus
                      as={Input.Text}
                      control={control}
                      error={errors.email}
                      name="email"
                      ref={ref}
                      size="small"
                    />
                  </Col>
                  <Col>
                    <PlusCircleOutlined
                      style={{ fontSize: '1.62rem', marginRight: '15px', color: '#8b8282' }}
                      twoToneColor="#eb2f96"
                      onClick={handleSubmit(onSubmit)}
                    />
                  </Col>
                </Row>
              )}
            </Row>
          )}

          {!disabled && (
            <InvisibleButton
              disabled={inputVisible}
              onClick={() => {
                if (!inputVisible) {
                  showInput();
                }
              }}
            >
              <TagAnt className={inputVisible && 'disabled-tag-plus'}>
                <PlusOutlined /> {placeholder}
              </TagAnt>
            </InvisibleButton>
          )}
        </Space>
      </form>
    </div>
  );
});

Tag.displayName = 'Tag';

export default styled(Tag)`
  .disabled-tag-plus {
    cursor: not-allowed;
    opacity: 0.4;
  }
`;
