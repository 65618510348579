import i18next from 'i18next';
import React from 'react';

import { TextRegular } from '../style/utils';

export const isBoolean = (value) => typeof value === 'boolean';
export const isManager = (role) => role === 'manager';
export const isKam = (role) => role === 'kam';
export const isClient = (role) => role === 'client' || role === 'supplier';
export const isFreemium = (userInfo) => !userInfo.products || !userInfo.products?.follow?.subscriptionValid;
export const isMultiAccess = (userInfo) => !!userInfo.isMultiAccess;

export const getStatusContractMarket = (marketExpiresSoonDays, marketIsValid) => {
  if (marketIsValid) {
    return <TextRegular>{i18next.t('market-valid')}</TextRegular>;
  }

  return (
    <div style={{ color: '#DE6811' }}>
      {marketExpiresSoonDays > 0
        ? i18next.t('market-expire-soon', { marketExpiresSoonDays })
        : i18next.t('market-not-valid')}
    </div>
  );
};

export const getStatusContractManagedBy = (user) => i18next.t(`global-user-${user}`);
