const colors = {
  baseColor: '#65c97e',
  header: '#65c97e',
  headerSmallLinkActive: '#65c97e',
  headerLinkActive: '#FFFFFF',
  headerLinkInactive: '#202124',
  headerSmallLinkHover: '#65c97e',
  lineGraph: '#65c97e',
  lineGraphBackground: '#E8F7EC',
  headerIcon: '#FFFFFF',
  headerSmallIcon: '#202124',
  arrowBack: '#FFFFFF',
  alert: '#DE6811',

  green: '#65c97e',
  red: '#ff0000',
  blue: '#11A3DE',
  orange: '#DE6811',
  orangeHigh: '#D06E2E',
  black: '#202124',
  beige: '#CCB39B',
  grayDark: '#5B6970',
  gray: '#BDBEBF',
  grayLight: '#E6E7E8',
  whiteDark: '#FAFAFA',
  white: '#FFFFFF',
};

export default colors;
