import { createGlobalStyle, css } from 'styled-components';

export default createGlobalStyle`
  ${({ theme: { colors } }) => css`
    html,
    body,
    #root {
      height: 100%;
    }

    body {
      overscroll-behavior-y: none;
      font-family: 'Open Sans', serif;
    }

    *,
    *::before,
    *::after {
      box-sizing: border-box;
    }

    p {
      margin: 0;
    }

    a {
      color: ${colors.baseColor};
      display: inline-block;
    }

    ol {
      padding: 0 1.875rem;
      margin: 0.625rem 0;
    }

    #main-content {
      margin-top: 1.5em !important;
      padding-top: 0;
    }

    .ant-space-vertical {
      width: 100%;
    }

    .ant-space {
      display: flex;
    }

    // Paper-css START
    @page {
      size: A4;
      margin: auto;
    }
    // Paper-css END

    .sheet {
      box-shadow: unset;
    }

    // CUSTOM POPOVER ANT DESIGN
    .ant-popover-title,
    .ant-popover-inner-content {
      padding: 0;
    }

    .ant-popover-inner {
      padding: 33px 20px 26px;
      border-radius: 7px;
      width: 331px;
    }

    .ant-popover-inner-content {
    }

    .ant-tooltip-inner {
      borderradius: 3px;
      color: white;
      boxshadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
      background: rgba(0, 0, 0, 0.7);
    }

    .ant-popover-title {
      border-bottom: 1px solid #ced5db;
      padding-bottom: 15px;
    }
  `}
`;
