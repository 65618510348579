import { Col, Row, Space } from 'antd';
import moment from 'moment/moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Collapse from '../../../../components/Collapse';
import getFormattedPeriodDate from '../../../../components/FormatPeriodDate/getFormattedPeriodDate';
import Hr from '../../../../components/Hr';
import ModalChangeConsumption from '../../../../components/Modals/ModalChangeConsumption';
import Table from '../../../../components/Table/Table';
import { useGetStrategy } from '../../../../endpoints/contracts/useGetStrategy';
import { Label, TextRegular } from '../../../../style/utils';

const ContractDetailsInfoContractTemplate = ({ contract, optimizationSettings, isProductsFetching }) => {
  const [t] = useTranslation();
  const [showModalChangeConsumption, setShowModalChangeConsumption] = useState(false);

  const { data } = useGetStrategy(contract.id);

  const informationContract = React.useMemo(() => {
    if (!contract) return [];
    const infos = [
      {
        title: t('contract-detail-proportion-to-risk'),
        data: [t(`global-${optimizationSettings.contractDetail.strategy}`)],
      },
    ];

    if (optimizationSettings.contractDetail.optimizationSettings) {
      infos[0].title = [t(`global-risk_strategy`)];
      infos[0].data = [t(`global-risk_strategy`)];
      // infos[0].data = [t(`global-${optimizationSettings.contractDetail.optimizationSettings.globalStrategy}`)];

      infos[0].data = [
        optimizationSettings.contractDetail.optimizationSettings?.strategyIsDefinedPerPeriod
          ? t('contract-detail-per-period-proportion')
          : t(`global-${optimizationSettings.contractDetail.optimizationSettings?.globalStrategy}`),
      ];

      infos.push({
        title: t('contract-detail-advice-variable'),
        data: [
          optimizationSettings.contractDetail.optimizationSettings?.strategyIsDefinedPerPeriod
            ? t('contract-detail-per-period-advice')
            : t('contract-detail-advice-variable-number', {
                volumes: 100 - optimizationSettings.contractDetail.optimizationSettings.globalMaximumOptimization,
              }),
        ],
      });
    }

    return infos;
  }, [contract, optimizationSettings, t]);
  const headers = React.useMemo(() => {
    if (!optimizationSettings?.contractDetail?.optimizationSettings?.settingsByPeriod) return [];
    if (!data) return [];
    const columns = [
      {
        title: t('contract-kam-details-columns-optimization-period'),
        key: 'name',
        width: 190,
        render: (record) => {
          return (
            <span>
              {getFormattedPeriodDate({
                date: record.periodStart,
                granularity: data.contract.fixationGranularity,
                format: 'MMM YYYY',
              })}
            </span>
          );
        },
      },
      {
        title: t('contract-detail-proportion-to-risk'),
        key: 'proportion_to_risk',
        width: 320,
        render: (record) => <span>{t(`global-${record.strategy}`)}</span>,
      },
      {
        title: t('contract-detail-to-fix'),
        key: 'amountFixed',
        width: 140,
        render: (record) => <span>{record.maximumOptimization}%</span>,
      },
    ];

    return columns;
  }, [data, optimizationSettings?.contractDetail?.optimizationSettings?.settingsByPeriod, t]);

  const blocksHeaders = React.useMemo(() => {
    if (!optimizationSettings?.contractDetail?.blocksSettings?.consumptionByMonth) return [];
    if (!data) return [];
    const columns = [
      {
        title: t('form-consommations-period-label'),
        key: 'periodStart',
        dataIndex: 'periodStart',
        width: 120,
        render: (periodStart) => moment(periodStart).format('MMMM YYYY'),
      },
      {
        title: t('form-consommations-quantity-label'),
        key: 'quantity',
        dataIndex: 'quantity',
        width: 120,
        render: (quantity) => quantity,
      },
      {
        title: t('form-consommations-variable-label'),
        key: 'variable',
        dataIndex: 'variable',
        width: 120,
        render: (variable) => variable,
      },
      {
        title: t('form-strategy-pourcentage-variable-label'),
        key: 'percent',
        width: 120,
        render: (record) => {
          return (
            <>
              {record?.variable &&
                record?.variable <= record?.quantity &&
                `${Math.round((record?.variable / record?.quantity) * 100 * 10) / 10}%`}
              {record?.variable && record?.variable > record?.quantity && null}
            </>
          );
        },
      },
      {
        title: t('form-consommations-maxClic-label'),
        key: 'maxClic',
        dataIndex: 'maxClic',
        width: 120,
        render: (quantity) => quantity,
      },
    ];

    return columns;
  }, [data, optimizationSettings?.contractDetail?.blocksSettings?.consumptionByMonth, t]);

  // For blocks contract
  if (
    optimizationSettings?.contractDetail?.isBlocks &&
    optimizationSettings?.contractDetail?.blocksSettings?.consumptionByMonth
  ) {
    return (
      <>
        <Collapse
          collapseProps={{
            defaultActiveKey: ['0'],
            collapsible: 'disabled',
          }}
          panelProps={{
            key: 0,
            header: t('contract-detail-optimization_parameters'),
            showArrow: false,
          }}
        >
          <Row className="rowHistoric" gutter={[18, 25]}>
            {informationContract.map((item) => (
              <Col key={item.title} sm={6} xs={6}>
                <Space direction="vertical">
                  <Label>{item.title}</Label>
                  <Space direction="vertical" size={0}>
                    {item.data.map((info) => {
                      if (typeof info === 'string') {
                        return <TextRegular key={info}>{info}</TextRegular>;
                      }

                      return info;
                    })}
                  </Space>
                </Space>
              </Col>
            ))}
          </Row>
          <Space direction="vertical" size="middle">
            <div>
              <Hr />
              <Table
                noBgHover
                columns={blocksHeaders}
                dataSource={optimizationSettings.contractDetail.blocksSettings?.consumptionByMonth}
                isClickable={false}
                loading={isProductsFetching}
                name="blocks"
                pagination={false}
                rowKey="periodStart"
              />
              <br />
            </div>
          </Space>
        </Collapse>
      </>
    );
  }
  if (!optimizationSettings.contractDetail?.optimizationSettings?.strategyIsDefinedPerPeriod) {
    return (
      <>
        <Collapse
          collapseProps={{
            defaultActiveKey: ['0'],
            collapsible: 'disabled',
          }}
          panelProps={{
            key: 0,
            header: t('contract-detail-optimization_parameters'),
            showArrow: false,
          }}
        >
          <Row className="rowHistoric" gutter={[18, 25]}>
            {informationContract.map((item) => (
              <Col key={item.title} sm={6} xs={6}>
                <Space direction="vertical">
                  <Label>{item.title}</Label>
                  <Space direction="vertical" size={0}>
                    {item.data.map((info) => {
                      if (typeof info === 'string') {
                        return <TextRegular key={info}>{info}</TextRegular>;
                      }

                      return info;
                    })}
                  </Space>
                </Space>
              </Col>
            ))}
          </Row>
          <br />
        </Collapse>

        {showModalChangeConsumption && (
          <ModalChangeConsumption contract={contract} onCancel={() => setShowModalChangeConsumption(false)} />
        )}
      </>
    );
  }
  return (
    <Collapse
      collapseProps={{
        defaultActiveKey: ['0'],
        collapsible: 'disabled',
      }}
      panelProps={{
        key: 0,
        header: t('contract-detail-optimization_parameters'),
        showArrow: false,
      }}
    >
      <Space direction="vertical" size="middle">
        <div>
          <Hr />
          <Table
            noBgHover
            columns={headers}
            dataSource={optimizationSettings.contractDetail.optimizationSettings.settingsByPeriod}
            isClickable={false}
            loading={isProductsFetching}
            name="period"
            pagination={false}
            rowKey="productId"
          />
        </div>
      </Space>
      <br />
    </Collapse>
  );
};

export default ContractDetailsInfoContractTemplate;
