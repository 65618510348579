import * as React from 'react';

const Germany = (props) => (
  <svg height={15.297} style={{ display: 'block' }} width={23.534} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M0 10.198h23.534v5.099H0z" fill="#fc0" />
    <path d="M0 5.098h23.534v5.099H0z" fill="#c00" />
    <path d="M0 0h23.534v5.098H0z" />
  </svg>
);

export default Germany;
