import { Space } from 'antd';
import React from 'react';
import styled, { css } from 'styled-components';

import { getHue, TextColored } from '../../style/utils';
import ArrowRight from '../icons/ArrowRight';
import InvisibleButton from '../InvisibleButton';

const WrapperArrow = styled.div`
  ${({ isActive }) => {
    let rotate = '0deg';

    if (isActive) {
      rotate = '-90deg';
    } else if (isActive === false) {
      rotate = '90deg';
    }

    return css`
      transform: rotate(${rotate});
      transition: all 0.3s ease-out;
    `;
  }}
`;

const ButtonLink = ({ className, children, onClick, name, isActive, ...rest }) => (
  <InvisibleButton className={className} onClick={onClick} {...rest}>
    <TextColored data-testid={`button-link-${name}`}>
      <Space>
        <span>{children}</span>
        <WrapperArrow isActive={isActive}>
          <ArrowRight />
        </WrapperArrow>
      </Space>
    </TextColored>
  </InvisibleButton>
);

export default styled(ButtonLink)`
  ${({ theme: { colors, fontSize, breakpoints }, color = colors.baseColor }) => {
    const hoverColor = `hsl(${getHue(color)}, 48%, 49%)`;

    return css`
      && {
        > div {
          color: ${color};
        }

        svg > g > g > path {
          stroke: ${color};
        }

        > div:hover {
          color: ${hoverColor};

          svg > g > g > path {
            stroke: ${hoverColor};
          }
        }
        :hover {
          text-decoration: ${hoverColor} underline;
        }
      }

      ${TextColored} {
        font-size: ${fontSize.extraSmall};
        text-align: left;

        @media (min-width: ${breakpoints.lg}) {
          font-size: ${fontSize.regular};
        }
      }
    `;
  }}
`;
