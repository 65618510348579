import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      style={{ display: 'block' }}
      id="prefix__Calque_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      width={35}
      height={11}
      viewBox="0 0 155.4 50"
      xmlSpace="preserve"
      {...props}
    >
      <style>{'.prefix__st0{fill:#1d1d1b}'}</style>
      <path
        className="prefix__st0"
        d="M4.8.9c-1.2 0-2.3.4-3.2 1.2-2 1.8-2.1 4.8-.4 6.7L19 28.7v15.9c0 2.9 2.3 5.2 5.2 5.2s5.2-2.3 5.2-5.2V28.2L47 8.9c2-1.8 2.1-4.8.4-6.8s-4.8-2.1-6.8-.4c-.2.3-.4.5-.6.8L24 20 8.3 2.5C7.4 1.5 6.1.9 4.8.9zM144.7 14.6V45c-.1 2.6 1.9 4.8 4.5 4.9 2.6.1 4.8-1.9 4.9-4.5V6.9c0-3.2-2.6-5.8-5.8-5.8h-.5c-2.3 0-4.4.9-5.9 2.6l-14.4 15.8L113 3.6c-1.5-1.7-3.7-2.6-5.9-2.6h-.5c-3.2 0-5.8 2.6-5.8 5.8v38.1c-.1 2.6 1.9 4.8 4.5 4.9 2.6.1 4.8-1.9 4.9-4.5V14.6l14.2 15.5c.8.9 1.9 1.4 3.1 1.4 1.2 0 2.3-.5 3.1-1.4l14.1-15.5z"
      />
      <linearGradient
        id="prefix__SVGID_1_"
        gradientUnits="userSpaceOnUse"
        x1={87.463}
        y1={74.588}
        x2={54.062}
        y2={27.694}
        gradientTransform="matrix(1 0 0 -1 0 78)"
      >
        <stop offset={0.19} stopColor="#00a2e1" />
        <stop offset={1} stopColor="#6cd188" />
      </linearGradient>
      <path
        d="M72.1 0c-6.1.1-12 2.3-16.5 6.4-5.2 4.5-8.3 10.8-8.7 17.6-.5 13.9 10.2 25.5 24.1 26h.9c6.2.1 12.3-2 17.1-6 1-.8 1.5-2 1.4-3.3 0-1.3-.7-2.4-1.8-3.2l-.3-.3c-1.7-1.2-3.9-1.1-5.6.1-3.1 2.4-6.9 3.6-10.8 3.5-4.9 0-9.6-2.3-12.6-6.2-5.4-6.9-4.2-17 2.8-22.4 2-1.5 4.3-2.6 6.8-3.1 7.3-1.6 14.6 2.7 16.8 9.8l.4 1H73.4c-2.5 0-4.5 2-4.5 4.5s2 4.5 4.5 4.5h17.7c1.1.1 2.3-.3 3.1-1.1 1-1.1 1.4-2.9 1.3-5.4v-.7C94.2 9.6 85.7.7 74.2-.3c-.7.3-1.4.3-2.1.3z"
        fill="url(#prefix__SVGID_1_)"
      />
    </svg>
  );
}

export default SvgComponent;
