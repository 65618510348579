import { MenuOutlined } from '@ant-design/icons';
import { Col, Row, Space } from 'antd';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import styled, { css, ThemeContext } from 'styled-components';

import { TextRegular } from '../../../../style/utils';
import { getFlag } from '../../../../utils/flag';
import CursorOFF from '../../../icons/CursorOFF';
import MarketTrendGasIcon from '../../../icons/MarketTrendGasIcon';
import MarketTrendPowerIcon from '../../../icons/MarketTrendPowerIcon';
import ValidationOFFIcon from '../../../icons/ValidationOFFIcon';
import ValidationONIcon from '../../../icons/ValidationONIcon';
import InvisibleButton from '../../../InvisibleButton';

export const WrapperCountry = styled.div`
  width: 23px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
  }
`;

const IconOff = styled.div`
  ${({ canSelect }) => css`
    position: relative;

    & > :nth-child(2) {
      opacity: 0;
      display: none;
      z-index: 10;
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
    &:hover {
      & > :nth-child(2) {
        opacity: ${canSelect ? 0 : 1};
        display: ${!canSelect ? 'block' : 'auto'};
      }
    }
  `}
`;

const Container = styled.div`
  padding: 0.93rem;
  box-shadow: rgb(0 0 0 / 20%) 0 2px 7px 0;
  background: white;
`;

const ItemMarketTrend = ({ index, handleClickItem, canSelect, ...item }) => {
  const [t] = useTranslation();
  const themeContext = React.useContext(ThemeContext);

  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided) => (
        <Container {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
          <Row align="middle" gutter={16}>
            <Col>
              <MenuOutlined style={{ fontSize: '1.62rem', marginRight: '15px', color: '#D5D2D2' }} />
            </Col>
            <Col>
              <WrapperCountry>{getFlag(item.country)}</WrapperCountry>
            </Col>
            <Col span={5}>
              <Space align="center">
                <WrapperCountry>
                  {item.energy === 'gas' ? <MarketTrendGasIcon /> : <MarketTrendPowerIcon />}
                </WrapperCountry>
                <TextRegular>{t(`global-${item.energy}`)}</TextRegular>
              </Space>
            </Col>
            <Col flex="auto">
              <TextRegular>{item.name}</TextRegular>
            </Col>
            <Col>
              <InvisibleButton name="toggle" onClick={() => (canSelect || item.selected) && handleClickItem(item)}>
                {item.selected ? (
                  <ValidationONIcon color={themeContext.colors.baseColor} />
                ) : (
                  <IconOff canSelect={canSelect}>
                    <ValidationOFFIcon />
                    <CursorOFF />
                  </IconOff>
                )}
              </InvisibleButton>
            </Col>
          </Row>
        </Container>
      )}
    </Draggable>
  );
};

export default ItemMarketTrend;
