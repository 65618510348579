import { useQueryClient } from 'react-query';

export default ({ location }) => {
  const queryClient = useQueryClient();
  const marketTrendId = location.pathname.split('/')[2];

  const allMT = queryClient.getQueryData(['get-market-trends']);

  if (!allMT) return null;

  const mt = allMT.find((mt) => mt._id === marketTrendId);

  if (mt) return mt.name;
};
