import React from 'react';

import Belgium from '../components/icons/country-flag/Belgium';
import France from '../components/icons/country-flag/France';
import Germany from '../components/icons/country-flag/Germany';
import Italy from '../components/icons/country-flag/Italy';
import Mexico from '../components/icons/country-flag/Mexico';
import Spain from '../components/icons/country-flag/Spain';

export const getFlag = (country) => {
  if (country === 'BE') return <Belgium />;
  if (country === 'FR') return <France />;
  if (country === 'DE') return <Germany />;
  if (country === 'IT') return <Italy />;
  if (country === 'ES') return <Spain />;
  if (country === 'MX') return <Mexico />;

  throw new Error(`Unknown country: ${country}`);
};

export const getCountryCode = (country) => {
  if (country === 'fr') return 'fr';
  if (country === 'it') return 'it';
  if (country === 'es') return 'es';
  if (country === 'be') return 'be';

  return 'gb';
};
