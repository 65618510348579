import { Checkbox as CheckboxAnt } from 'antd';
import React from 'react';

import { TextRegular } from '../../../style/utils';

const Checkbox = React.forwardRef(({ value, text, ...input }, ref) => (
  <CheckboxAnt
    {...input}
    checked={value}
    data-testid="checkbox"
    ref={ref}
    onChange={(event) => {
      const newValue = event.target.checked;
      input.onChange(newValue);
    }}
    onClick={(event) => {
      event.stopPropagation();
    }}
  >
    <TextRegular>{text}</TextRegular>
  </CheckboxAnt>
));

Checkbox.displayName = 'Checkbox';

export default Checkbox;
