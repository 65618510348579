import './paper.css';

import { ConfigProvider, Space } from 'antd';
import i18next from 'i18next';
import moment from 'moment';
import qs from 'qs';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import LogoYemHistoric from '../../assets/logo-YEM-template.png';
import { LANGUAGE_CONFIG } from '../../config/app-config';
import { useGetHistoric } from '../../endpoints/historic/useGetHistoric';
import EventTemplate from './EventTemplate';
import { BottomInfo, ContractName, DetailContract, TitleMiddlePage, Wrapper } from './PageHistoricTemplate.styled';

const PageHistoricTemplate = ({ className }) => {
  const [t] = useTranslation();
  const [localAnt, setLocalAnt] = useState(false);
  const history = useHistory();
  const { id, hasGraph, hasPie, eventGroup, filterDateStart, filterDateEnd, local } = qs.parse(
    history.location.search,
    { ignoreQueryPrefix: true },
  );

  const paramsRequest = React.useMemo(() => {
    const filterValues = {
      filterDateStart,
      filterDateEnd,
      eventGroup,
    };

    return qs.stringify({
      media: 'pdf',
      page: 1,
      language: local,
      limit: 10000,
      contract: id,
      ...filterValues,
    });
  }, [filterDateEnd, filterDateStart, eventGroup, id, local]);

  const { refetch: getHistoric, data } = useGetHistoric(paramsRequest);

  const [activeKey] = useState(
    Array(1000)
      .join(0)
      .split(0)
      .map((v, i) => i),
  );

  useEffect(() => {
    if (local === 'en') {
      setLocalAnt(false);
    } else if (LANGUAGE_CONFIG.includes(local)) {
      import(`antd/es/locale/${local}_${local.toUpperCase()}.js`).then((res) => {
        setLocalAnt(res.default);
        import(`moment/locale/${local}`).then(() => {
          moment.locale(local);
        });
      });
    }
  }, [local]);

  useEffect(() => {
    i18next.changeLanguage(local);
    moment.locale(local);
  }, [local]);

  const filterInput = useMemo(
    () => ({ eventGroup, filterDateStart, filterDateEnd }),
    [filterDateEnd, filterDateStart, eventGroup],
  );

  useEffect(() => {
    getHistoric({
      page: 1,
      language: local,
      limit: 10000,
      contract: id,
      ...filterInput,
    });
  }, [filterInput, getHistoric, id, local]);

  const correctFormatFilterDate = useMemo(() => {
    const start = moment(filterDateStart || data?.contract?.createdAt).format('DD/MM/YYYY');
    const end = moment(filterDateEnd).format('DD/MM/YYYY');

    return `${start} ${t('global-to')} ${end}`;
  }, [filterDateStart, data?.contract?.createdAt, filterDateEnd, t]);

  if (!data) {
    return null;
  }

  return (
    <body className="A4">
      <ConfigProvider locale={localAnt}>
        <Wrapper className={`${className} height-min`}>
          <Space align="flex-end" justify="space-between">
            <div className="wrapper-logo">
              <img alt="" src={LogoYemHistoric} />
            </div>
          </Space>
          <Space direction="vertical">
            <TitleMiddlePage>{t('template-historic-page-title')}</TitleMiddlePage>
            <Space direction="vertical">
              <DetailContract>
                {t('template-historic-page-creation-date')}
                <div>{moment().format('DD/MM/YYYY - HH:mm')}</div>
              </DetailContract>
              <DetailContract>
                {t('template-historic-page-filter-date')}
                <div>{correctFormatFilterDate}</div>
              </DetailContract>
              <DetailContract>
                {t('template-historic-page-event-group')}
                <div>{t(`page-historic-event-${eventGroup}`)}</div>
              </DetailContract>
            </Space>
          </Space>
          <Space direction="vertical">
            <ContractName>
              Contract
              {data.contract.name}
            </ContractName>
            <BottomInfo>
              {t('global-energy')}:<span>{t(`global-${data.contract.energy}`)}</span>
            </BottomInfo>
            <BottomInfo>
              {t('global-energy_delivery')}:{' '}
              <span>
                <Moment format="MMM YYYY">{data.contract.start}</Moment> {t('global-to')}{' '}
                <Moment format="MMM YYYY">{data.contract.end}</Moment>
              </span>
            </BottomInfo>
            <BottomInfo>
              {t('global-optimization_period')}:{t(`global-${data.contract.fixationGranularity}`)}
            </BottomInfo>
          </Space>
        </Wrapper>
        <EventTemplate
          activeKey={activeKey}
          contract={data.contract}
          energyPriceUnit={data.contract.energyPriceUnit}
          hasGraph={hasGraph === 'true'}
          hasPie={hasPie === 'true'}
          history={data.marketHistory.history.data}
          optimizationHistory={data.optimizationHistory}
        />
      </ConfigProvider>
    </body>
  );
};

export default styled(PageHistoricTemplate)`
  .wrapper-logo {
    width: 223px;

    img {
      width: 100%;
    }
  }
`;
