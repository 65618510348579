import * as React from 'react';

const DeleteGroupIcon = (props) => (
  <svg
    id="Calque_1"
    xmlns="http://www.w3.org/2000/svg"
    width={'25.3'}
    height={'25.3'}
    viewBox="0 0 25.3 23.2"
    style={{
      enableBackground: 'new 0 0 25.3 23.2',
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>{'.st0{fill:none;stroke:#000;stroke-width:1.2;stroke-linecap:round;stroke-linejoin:round}'}</style>
    <g id="picto-KAM-plus" transform="translate(.6 .6)">
      <g id="picto-KAM">
        <g id="Groupe_1568">
          <path
            id="Trac\xE9_218"
            className="st0"
            d="M22.5 21.4c-.2-2.2-.8-4.3-1.9-6.3-.5-.8-1.1-1.4-1.9-1.9-1.9-1.2-8-1-9.8 0-1.3.9-2.3 2.1-2.7 3.6-.5 1.5-.9 3-1.1 4.5h17.4zM17 6.9c0 1.7-1.4 3.1-3.1 3.1-1.7 0-3.1-1.4-3.1-3.1 0-1.7 1.4-3.1 3.1-3.1h0c1.7 0 3.1 1.4 3.1 3.1z"
          />
          <path id="Ligne_231" className="st0" d="M10.4 21.4v-4.8" />
          <path id="Ligne_232" className="st0" d="M17.3 21.4v-4.8" />
        </g>
      </g>
      <g id="picto-KAM_1_">
        <g id="Groupe_1568_1_">
          <path
            id="Trac\xE9_218_1_"
            className="st0"
            d="M7.7 14.3c-1.8-.1-3.4.1-4.3.6-.6.4-1.1.9-1.5 1.5-.8 1.5-1.3 3.2-1.5 5h4.8M6.9 7.2h0c1.3 0 2.3 1.1 2.3 2.3 0 1.3-1.1 2.3-2.3 2.3-1.3 0-2.3-1.1-2.3-2.3 0-1.3 1.1-2.3 2.3-2.3z"
          />
        </g>
      </g>
      <path id="Ligne_322" className="st0" d="m23.2 1-4.4 4.3" />
      <path id="Ligne_323" className="st0" d="M23.2 5.3 18.8 1" />
    </g>
  </svg>
);

export default DeleteGroupIcon;
