import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type Props = {
  className?: string;
  template: string;
  textBrut?: string;
  name?: string;
  variables?: Record<string, string | number>;
};

const SafeHTMLTranslate = forwardRef<any, Props>(
  ({ className, template, textBrut = '', name, variables, ...props }, ref) => {
    const [t] = useTranslation();
    const text = template ? t(template, variables) : textBrut;

    return (
      <div className={className} data-testid={`safe-translate-${name}`}>
        <div
          ref={ref}
          // eslint-disable-next-line
          dangerouslySetInnerHTML={{
            __html: text.includes('[html]') ? text.substring(6) : text,
          }}
          {...props}
        />
      </div>
    );
  },
);

export default styled(SafeHTMLTranslate)`
  white-space: pre-wrap;
`;
