import { message } from 'antd';
import { useQuery } from 'react-query';

import { useApi } from '../../context/ApiContext';
import { useTenant } from '../../context/TenantContext';
import { Navigation } from '../../navigation';
import history from '../../routes/history';

export const useGetMultiAccessUserDetail = (userId) => {
  const client = useApi();
  const { env } = useTenant();

  return useQuery(
    ['get-multi-access-users', userId],
    () => client.get(`${env.REACT_APP_BACKEND_PORTAL_URL}/users/multi-access/${userId}`).then((res) => res.data),
    {
      onError() {
        message.error('Cannot get this user');
        history.push(Navigation.MULTI_ACCESS);
      },
    },
  );
};
