import React from 'react';

function HistoricFixationUpdateIcon() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={11.971}
    height={17.959}
    style={{ marginTop: "5px"}}
    >
    <defs>
      <clipPath id="a">
        <path
          data-name="Rectangle 1242"
          transform="translate(-1958.011 900.045)"
          fill="none"
          d="M0 0h11.971v17.959H0z"
        />
      </clipPath>
    </defs>
    <g data-name="Groupe 3439">
      <g
        data-name="Groupe 3438"
        clipPath="url(#a)"
        transform="translate(1958.011 -900.045)"
      >
        <g data-name="Groupe 3424">
          <g data-name="Groupe 1537-4">
            <path
              data-name="Trac\xE9 245-2"
              d="M-1946.927 907.245v-2.21a4.991 4.991 0 0 0-4.99-4.991 4.991 4.991 0 0 0-4.99 4.991v2.21h-1.1V918h11.972v-10.755Zm-4.236 6.71h-1.724v-3.363h1.722Zm2.348-6.71h-6.205v-2.21a3.1 3.1 0 0 1 3.1-3.1 3.1 3.1 0 0 1 3.1 3.1Z"
              fill="#17181b"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
  );
}

export default HistoricFixationUpdateIcon;
