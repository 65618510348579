import { message } from 'antd';
import { useQuery } from 'react-query';

import { useApi } from '../../context/ApiContext';

export const useGetMultiAccessKamInfo = (userId) => {
  const client = useApi();

  return useQuery(
    ['get-multi-access-kam-info', userId],
    () => client.get(`/multiaccess/manager/kamInfo/${userId}`).then((res) => res.data),
    {
      onError() {
        message.error('Cannot get kam info');
      },
    },
  );
};
