import startCase from 'lodash/startCase';
import moment from 'moment';

import useDesktop from '../../hooks/useDesktop';

/*
  This will transform the date from
  Thursday, May 28, 2020 11:57 AM
  to
  Thursday May 28 2020
*/
const FormatDate = ({ date, isHistory }) => {
  const isDesktop = useDesktop();

  if (!isHistory) {
    if (!isDesktop) {
      return moment(date).format('DD/MM/YYYY');
    }
    return startCase(moment(date).format('LLLL').replace(moment(date).format('LT'), ''));
  }
  if (!isDesktop) {
    return `${moment(date).format('DD/MM/YYYY')} ${moment(date).format('LT')}`;
  }
  return `${startCase(moment(date).format('LLLL').replace(moment(date).format('LT'), ''))} - ${moment(date).format(
    'LT',
  )}`;
};

export default FormatDate;
