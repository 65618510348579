import { Col, Row, Space } from 'antd';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import Button from '../../../../components/Button/Button';
import Hr from '../../../../components/Hr';
import KamMultiple from '../../../../components/icons/KamMultipleIcon';
import Input from '../../../../components/Input';
import WrapperWhiteBox from '../../../../components/WrapperWhiteBox';
import { useCreateGroup } from '../../../../endpoints/groups/useCreateGroup';
import { useGetGroupKam } from '../../../../endpoints/groups/useGetGroupKam';
import { Navigation } from '../../../../navigation';
import history from '../../../../routes/history';
import { Label, TextRegular } from '../../../../style/utils';
import GroupItem from '../GroupItem';

const INITIAL_SELECT_VALUE = '-';

const GroupCreate = () => {
  const [t] = useTranslation();
  const queryClient = useQueryClient();
  const allGroups = queryClient.getQueryData('group-list');
  const [member, setMember] = React.useState(INITIAL_SELECT_VALUE);
  const { register, control, handleSubmit, setValue, watch, errors } = useForm({
    defaultValues: {
      name: '',
      kamIds: [],
    },
  });
  const { kamIds } = watch(['kamIds']);
  const { data: groupKam, isLoading } = useGetGroupKam();
  const { mutate: createGroup, isLoading: isCreateGroupLoading } = useCreateGroup(true);

  const allGroupsName = React.useMemo(() => allGroups.map((group) => group.name), [allGroups]);

  React.useEffect(() => {
    register(
      { name: 'name' },
      {
        required: true,
        validate: (value) => {
          if (allGroupsName.includes(value)) {
            return 'error-name-group-already-taken';
          }
          return undefined;
        },
      },
    );
    register({ name: 'kamIds' });
  }, [allGroupsName, register]);

  const listKam = React.useMemo(() => {
    if (!groupKam) {
      return [];
    }

    return groupKam
      .filter((item) => !kamIds.includes(item.id))
      .map((item) => ({
        key: item.id,
        value: item.id,
        label: item.name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [groupKam, kamIds]);

  const handleAdd = React.useCallback(() => {
    setValue('kamIds', [...kamIds, member]);
    setMember(INITIAL_SELECT_VALUE);
  }, [kamIds, member, setValue]);

  const handleChangeMember = React.useCallback((member) => {
    setMember(member);
  }, []);

  const handleDeleteMember = React.useCallback(
    (id) => {
      const newKamIds = [...kamIds];
      const memberIndex = newKamIds.findIndex((kamId) => kamId === id);

      newKamIds.splice(memberIndex, 1);
      setValue('kamIds', newKamIds);
    },
    [kamIds, setValue],
  );

  const onSubmit = React.useCallback(
    (data) => {
      createGroup(data, {
        onSuccess() {
          history.push(Navigation.GROUP_LIST);
        },
      });
    },
    [createGroup],
  );

  return (
    <WrapperWhiteBox icon={<KamMultiple />} title={t('multi-access-group-create-title')}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Space direction="vertical" size="large">
          <TextRegular>{t('multi-access-group-create-description')}</TextRegular>
          <Hr />
          <Row>
            <Col md={8} xs={24}>
              <Controller
                as={Input.Text}
                control={control}
                error={errors.name}
                label={t('multi-access-group-create-input-name')}
                name="name"
              />
            </Col>
          </Row>
          <Hr />
          <Space direction="vertical" size="large">
            <Row align="bottom" gutter={[16, 16]}>
              <Col md={8} xs={24}>
                <Input.Select
                  items={listKam}
                  label={t('multi-access-group-create-input-members')}
                  loading={isLoading}
                  value={member}
                  onChange={handleChangeMember}
                />
              </Col>
              <Col>
                <Button disabled={member === INITIAL_SELECT_VALUE} onClick={handleAdd}>
                  {t('global-add')}
                </Button>
              </Col>
            </Row>

            <Hr noBottom noTop />

            {kamIds.length !== 0 && (
              <>
                <Label>{t('multi-access-group-list-members')}</Label>

                <Row gutter={24}>
                  {kamIds
                    .map((id) => groupKam.find((kam) => kam.id === id))
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((member) => (
                      <Col key={member.id} md={12} xs={24}>
                        <GroupItem id={member.id} name={member.name} onDelete={handleDeleteMember} />
                      </Col>
                    ))}
                </Row>
              </>
            )}
          </Space>
          {kamIds.length > 0 && <Hr />}
          <Space size="middle">
            <Button variant="secondary" onClick={() => history.goBack()}>
              {t('global-close')}
            </Button>
            <Button isLoading={isCreateGroupLoading} type="submit">
              {t('multi-access-group-create-valid')}
            </Button>
          </Space>
        </Space>
      </form>
    </WrapperWhiteBox>
  );
};

export default GroupCreate;
