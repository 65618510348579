const newContractState = {
  // Client
  client: '',
  // Contract
  contract_name: '',
  contract_code: '',
  contract_energy: '',
  contract_country: '',
  contract_power_multi_index: false,
  contract_power_indexes: [],
  contract_power_multi_select: 2,
  contract_granularity: '',
  contract_delivery_start: '',
  contract_delivery_end: '',
  contract_delivery: [],
  contract_fixation: '',
  contract_market_type: '',
  contract_market_index: '',
  contract_puissance_select: '',
  contract_size_click: '',
  contract_type_fixation: '',
  contract_mode_period: 'absolue',
  contract_fixation_multi: {
    month: false,
    quarter: false,
    semester: false,
    summer_winter: false,
    year: false,
    gasyear: false,
  },
  productsConfig: {
    month: {},
    quarter: {},
    semester: {},
    summer_winter: {},
    year: {},
    gasyear: {},
  },
  periodProducts: {
    month: [],
    quarter: [],
    semester: [],
    summer_winter: [],
    year: [],
    gasyear: [],
  },
  minBlockSizeByPeriod: {
    month: [],
    quarter: [],
    semester: [],
    summer_winter: [],
    year: [],
    gasyear: [],
  },
  // consumptions
  consumptions: {},
  consumptionsPeriod: [{}],
  // MarketTrend
  market_trend: '',
  // Fixations
  fixations_already: '',
  fixations: [],
  blockFixations: [],
  dataFixations: [],
  // Strategy
  optimizationVariable: {},
  strategy_risk: '',
  strategy_type: '',
  maximumOptimization: '',
  consumptionPercent: '',
  consumptionVariable: '',
  strategy_fixation: '',
  variablePercent: '',
  // Notifications
  notifications_email_checked: false,
  notifications_email: '',
  notifications_phone_checked: false,
  notifications_phone: '',
  //
  productInfo: '',
  marketBut: 'CAL',
  indexContract: 'psv',
  //
  isTenantBlock: false,
};

export default newContractState;
