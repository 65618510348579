import { useQuery } from 'react-query';

import { useApi } from '../../context/ApiContext';
import { useTenant } from '../../context/TenantContext';

export const useGetGroupKam = () => {
  const client = useApi();
  const { env } = useTenant();

  return useQuery('group-kam', () =>
    client.get(`${env.REACT_APP_BACKEND_PORTAL_URL}/users/multi-access/kamGroup/kams`).then((res) => res.data),
  );
};
