import * as React from 'react';

const CursorOFF = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} {...props}>
    <path data-name="Path 330" d="M14 0A14 14 0 1 1 0 14 14 14 0 0 1 14 0Z" fill="#de6811" />
    <path
      data-name="Path 329"
      d="M14.001 3.995a10.007 10.007 0 1 0 10.007 10.007A10.015 10.015 0 0 0 14.001 3.995ZM6.414 14.002a7.583 7.583 0 0 1 12.29-5.948L8.052 18.706a7.6 7.6 0 0 1-1.638-4.704ZM14 21.588a7.5 7.5 0 0 1-4.156-1.251l10.5-10.491a7.582 7.582 0 0 1-6.343 11.742Z"
      fill="#fff"
    />
  </svg>
);

export default CursorOFF;
