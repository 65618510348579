import { useQuery } from 'react-query';

import { useApi } from '../../context/ApiContext';
import { useUserInfo } from '../../context/UserInfoContext';
import { isClient, isKam } from '../../utils/user';

export const useGetContracts = () => {
  const client = useApi();
  const { userInfo } = useUserInfo();

  return useQuery('get-contracts', () => client.get('/contracts').then((res) => res.data), {
    enabled: isClient(userInfo.role) || isKam(userInfo.role),
  });
};
