import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import FormatPriodDateBloc from '../../../../../../components/FormatPriodDateBloc';
// import BadgeAlertIcon from '../../../../../../components/BadgeAlertIcon/BadgeAlertIcon';
import Table from '../../../../../../components/Table/Table';
import useCookie from '../../../../../../hooks/useCookie';
import { TextSmallExtra } from '../../../../../../style/utils';
import { formatNumber } from '../../../../../../utils/input';

const TablePerDate = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  data = [],
  fixationsUnit,
  handleHover,
  consumptionsUnit,
  showTypeColumn,
  contractIndex,
  contractMarketId,
  showOptimizations,
  minBlock,
}) => {
  const [t] = useTranslation();
  const history = useHistory();
  const { setCookie, removeCookie } = useCookie();
  const unitPrice = consumptionsUnit === 'mmbtu' ? 'mmbtu' : 'mwh';

  const columnsByDate = React.useMemo(() => {
    const columns = [
      {
        title: t('contract-detail-blocks-list-column-fixation-date'),
        key: 'date',
        width: 200,
        render({ date }) {
          return moment(date).format('DD/MM/YYYY');
          // return <FormatDate date={date} />;
        },
      },
      {
        title: t('contract-detail-blocks-list-column-period'),
        key: 'optimizations',
        align: 'center',
        render(record) {
          return <FormatPriodDateBloc date={record.dateStart} format="MMM YYYY" periodMonths={record.months} />;
        },
      },
      {
        title: t('contract-detail-blocks-list-column-amount'),
        key: 'amount',
        align: 'center',
        render(record) {
          const value = Math.round((record.amount + Number.EPSILON) * 100) / 100;
          return `${Math.round((value / minBlock) * 100) / 100} Clip (${formatNumber(value)} ${t(
            `global-unit-${fixationsUnit}`,
          )})`;
        },
      },
    ];

    if (showOptimizations) {
      columns.push(
        {
          title: t('contract-detail-blocks-list-column-volume'),
          key: 'volume',
          align: 'center',
          render(record) {
            return `${formatNumber(Math.round(record.totalVolume * 100) / 100)} ${t(
              `global-unit-${consumptionsUnit}`,
            )}`;
          },
        },
        {
          title: t('contract-detail-blocks-list-column-pricelevel'),
          key: 'pricelevel',
          render(record) {
            if (record.priceLevel)
              return `${formatNumber(Math.round((record.priceLevel + Number.EPSILON) * 100) / 100)} 
          €/${t(`global-unit-${unitPrice}`)}`;
            return (
              <>
                <TextSmallExtra>{t(`contract-price-unavailable-1`)}</TextSmallExtra>
              </>
            );
          },
        },
        {
          key: 'info',
          render(record) {
            if (record.product !== 'null') {
              return (
                <>
                  <Tooltip placement="right" title={t('contract-detail-blocks-list-column-more-info')}>
                    <QuestionCircleOutlined
                      onClick={() => {
                        setCookie('marketIndex', contractIndex);
                        setCookie('marketProductInfo', record.product.toUpperCase());
                        if (record.product.slice(0, -2) === 'C') setCookie('marketProduct', 'CAL');
                        else setCookie('marketProduct', record.product.slice(0, -2));
                        history.push(`/market-trend/${contractMarketId}`);
                      }}
                    />
                  </Tooltip>
                </>
              );
            }
            return (
              <>
                <Tooltip placement="right" title={t('contract-detail-blocks-list-column-more-info')}>
                  <QuestionCircleOutlined
                    onClick={() => {
                      removeCookie('marketIndex');
                      removeCookie('marketProduct');
                      removeCookie('marketProductInfo');
                      history.push(`/market-trend/${contractMarketId}`);
                    }}
                  />
                </Tooltip>
              </>
            );
          },
        },
      );
    }

    if (!showOptimizations) {
      columns.push(
        {
          title: t('contract-detail-blocks-list-column-volume-switched'),
          key: 'volume',
          align: 'center',
          render(record) {
            return `${formatNumber(Math.round(record.totalVolume * 100) / 100)} ${t(
              `global-unit-${consumptionsUnit}`,
            )}`;
          },
        },
        {
          title: t('contract-detail-blocks-list-column-price'),
          key: 'pricelevel',
          render(record) {
            return `${formatNumber(Math.round((record.price + Number.EPSILON) * 1000) / 1000)} 
          ${t(`global-unit-price-${consumptionsUnit}`)}`;
          },
        },
      );
    }

    if (showTypeColumn) {
      columns.push({
        title: t('contract-detail-blocks-list-column-type'),
        key: 'type',
        render(record) {
          return `${t(
            record.type === 'optimization'
              ? 'contract-detail-blocks-list-type-optimization'
              : 'contract-detail-blocks-list-type-fixation',
          )}`;
        },
      });
    }
    return columns;
  }, [
    t,
    fixationsUnit,
    consumptionsUnit,
    showTypeColumn,
    showOptimizations,
    contractIndex,
    contractMarketId,
    history,
    unitPrice,
    setCookie,
    removeCookie,
    minBlock,
  ]);

  function clearSelection() {
    data.forEach((record) => {
      document.querySelector(`tr[data-row-key="${record.id}"]`).style.backgroundColor = 'white';
    });
  }

  return (
    <Table
      noBgHover
      columns={columnsByDate}
      dataSource={data}
      isClickable={false}
      name="date"
      pagination={false}
      rowKey="id"
      onRow={(record) => ({
        onMouseEnter: () => {
          // Select block
          handleHover(record.id);
          // Clear previously selected rows
          clearSelection();
          // Select row
          document.querySelector(`tr[data-row-key="${record.id}"]`).style.backgroundColor = 'lightgrey';
        },
        onMouseLeave: () => {
          // Unselect block
          handleHover(null);
          // Clear previously selected rows
          clearSelection();
        },
        onTouchStart: () => {
          // Select block
          handleHover(record.id);
          // Clear previously selected rows
          clearSelection();
          // Select row
          document.querySelector(`tr[data-row-key="${record.id}"]`).style.backgroundColor = 'lightgrey';
        },
        onTouchEnd: () => {
          // Unselect block
          handleHover(null);
          // Clear previously selected rows
          clearSelection();
        },
      })}
    />
  );
};

export default TablePerDate;
