import React from 'react';

function HistoricMarketTrendUpdateIcon() {
  return (
    <svg
      style={{ display: 'block' }}
      height="21.333"
      viewBox="0 0 18.916 21.333"
      width="18.916"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <clipPath id="clip-path">
          <path d="M0 0H18.916V21.333H0z" data-name="Rectangle 916" fill="#202124" />
        </clipPath>
      </defs>
      <path d="M0 0H2.158V6.022H0z" data-name="Rectangle 913" fill="#202124" transform="translate(11.494 10.191)" />
      <g data-name="Groupe 1541">
        <g clipPath="url(#clip-path)" data-name="Groupe 1540" fill="#202124">
          <path
            d="M18.916 11.875a9.458 9.458 0 11-9.458-9.458V0l5.607 3.558-5.607 3.555V4.281a7.593 7.593 0 107.593 7.593z"
            data-name="Tracé 248"
          />
          <path d="M0 0H2.158V8.215H0z" data-name="Rectangle 914" transform="translate(5.196 7.999)" />
          <path d="M0 0H2.158V3.948H0z" data-name="Rectangle 915" transform="translate(8.379 12.265)" />
        </g>
      </g>
    </svg>
  );
}

export default HistoricMarketTrendUpdateIcon;
