import { useQuery } from 'react-query';

import { useApi } from '../../context/ApiContext';
import { useTenant } from '../../context/TenantContext';
import useRedirectToPortal from '../../hooks/useRedirectToPortal';
import { Navigation } from '../../navigation';
import { isMultiAccess } from '../../utils/user';

export const useGetUserInfo = () => {
  const client = useApi();
  const { env, tenantName } = useTenant();
  const { redirectToPortal } = useRedirectToPortal();

  return useQuery(
    'get-user-info',
    () => client.get(`${env.REACT_APP_BACKEND_PORTAL_URL}/users/userinfo?app=follow`).then((res) => res.data),
    {
      onSuccess(data) {
        // Protection for accessing other domain if our tenantName is different
        if (isMultiAccess(data) && data.tenant !== tenantName) {
          redirectToPortal(Navigation.LOGOUT).go();
        }
      },
      onError(err) {
        if (err.response.status === 401) {
          redirectToPortal(Navigation.LOGOUT).go();
        }
      },
    },
  );
};
