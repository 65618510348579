import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { useUserInfo } from '../../context/UserInfoContext';
import { Navigation } from '../../navigation';
import { isClient, isManager, isMultiAccess } from '../../utils/user';
import GroupCreate from './group/GroupCreate';
import GroupDetails from './group/GroupDetails';
import GroupList from './group/GroupList';
import MultiAccessCreate from './MultiAccessCreate';
import MultiAccessDetails from './MultiAccessDetail';
import MultiAccessEdit from './MultiAccessEdit';
import MultiAccessList from './MultiAccessList';

const WrapperMultiAccess = () => {
  const { userInfo } = useUserInfo();

  const routes = React.useMemo(() => {
    const routesPerRoles = [];

    if (isManager(userInfo.role)) {
      routesPerRoles.push({ component: GroupList, path: Navigation.GROUP_LIST });
      routesPerRoles.push({ component: GroupCreate, path: Navigation.GROUP_CREATE });
      routesPerRoles.push({ component: GroupDetails, path: Navigation.GROUP_DETAILS });
    }

    if (isMultiAccess(userInfo) && !isClient(userInfo.role)) {
      routesPerRoles.push({ component: MultiAccessList, path: Navigation.MULTI_ACCESS });
      routesPerRoles.push({ component: MultiAccessCreate, path: Navigation.MULTI_ACCESS_CREATE });
      routesPerRoles.push({ component: MultiAccessDetails, path: Navigation.MULTI_ACCESS_DETAILS });
      routesPerRoles.push({ component: MultiAccessEdit, path: Navigation.MULTI_ACCESS_EDIT });
    }

    return routesPerRoles;
  }, [userInfo]);

  return (
    <Switch>
      {routes.map(({ component, path }) => (
        <Route exact component={component} key={path} path={path} />
      ))}
    </Switch>
  );
};

export default WrapperMultiAccess;
