import i18next from 'i18next';
import React from 'react';

import SafeHTMLTranslate from '../components/SafeHTMLTranslate';

export const getErrorMessage = (error, type) => {
  if (error.message) {
    if (error.message.search('/') > 0) {
      const message = error.message.split(' / ')[0];
      const value = error.message.split(' / ')[1];

      return <SafeHTMLTranslate template={message} variables={{ value }} />;
    }
    return i18next.t(error.message);
  }

  if (error.type === 'required') return i18next.t(`global-field-${type}-required`);
  if (error.type === 'pattern') return i18next.t(`global-field-${type}-pattern`);
  if (error.type === 'validate') return i18next.t(error.message);

  return 'Error field';
};

export const formatNumber = (number) => {
  const numStr = number.toString();

  if (numStr.includes('.')) {
    let [whole, decimal] = numStr.split('.');

    whole = whole.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    decimal = decimal.padEnd(3, '0').slice(0, 3);

    return `${whole},${decimal}`;
  }
  return numStr.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  // if (Number.isInteger(number)) {
  //   return number.toString().replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1.');
  // }
  // const num = (Math.round(parseFloat(number) * 100) / 100).toFixed(2);

  // return num.toString().replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1.');
};

export const isValidNumber = (number) => {
  if (typeof number === 'number' && !Number.isNaN(number)) return true;

  return number && !/[a-zA-Z]/.test(number) && !Number.isNaN(number.replace(/,/g, '.'));
};

export const isNumberLowerOrEqualThan = (number, max) => {
  if (!isValidNumber(number)) return false;

  if (typeof number === 'string') return Number.parseFloat(number.replace(/,/g, '.')) <= max;
  return number <= max;
};

export const isNumberHigherOrEqualThan = (number, min) => {
  if (!isValidNumber(number)) return false;

  if (typeof number === 'string') return Number.parseFloat(number.replace(/,/g, '.')) >= min;
  return number >= min;
};

export const checkValidNumber = (str) => {
  const tmpStr = str.replace(',', '.');
  const number = Number(tmpStr);
  let error;

  if (isNaN(number) || number < 0) {
    error = 'global-wrong-value';
  }

  return { error, number };
};

export const transformStringToNumber = (str) => {
  if (typeof str === 'number' && !Number.isNaN(str)) return str;

  const tmpStr = str.replace(/ /g, '').replace(/,/g, '.');

  if (!isValidNumber(str)) throw new Error('Invalid number');

  return Number(tmpStr);
};

export const transformNumberToString = (number) => {
  if (number === undefined) return null;

  return new Intl.NumberFormat('fr').format(number).replace(/\s/g, '') || null;
};

export const formatCurrency = (number, unit = '€') => {
  const priceFormatted = new Intl.NumberFormat(i18next.language, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);

  return `${priceFormatted} ${unit}`;
};
