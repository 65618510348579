import { Col, Row, Space } from 'antd';
import React from 'react';
import styled from 'styled-components';

import MiniClockIcon from '../../icons/MiniClockIcon';
import { PreAlert, TextWarningBig, TextWarningWhite } from './Wrapper.styled';

export const AlertHeader = ({ alert }) => (
  <PreAlert>
    <Space size={4}>
      <Row align="middle" gutter={[8]}>
        <Col>
          <MiniClockIcon />
        </Col>
        {alert && (
          <Col>
            <TextWarningWhite>{alert.toUpperCase()}</TextWarningWhite>
          </Col>
        )}
      </Row>
    </Space>
  </PreAlert>
);

const Wrapper = ({ className, children, title, alert }) => (
  <Space className={className} direction="vertical" size="middle">
    {alert && <AlertHeader alert={alert} />}

    {title && <TextWarningBig>{title}</TextWarningBig>}

    {children}
  </Space>
);

export default styled(Wrapper)`
  background-color: transparent;
  border: 1px solid #d0d5d9;
  border-radius: 6px;
  padding: 1.8rem 2.18rem;
`;
