import styled, { css } from 'styled-components';

import Input from '../Input';

export const SelectLangStyled = styled(Input.Select)`
  ${({ theme: { colors, fontSize }, desktop }) => css`
    color: ${desktop === 'true' ? colors.headerIcon : colors.headerSmallIcon};
    width: 100px;
    padding-left: ${desktop === 'true' ? '40px' : 'unset'};
    margin: ${desktop === 'true' ? '0 20px 0 -30px' : 'unset'};
    && {
      .ant-select-selection-item {
        color: ${desktop === 'true' ? colors.headerIcon : colors.headerSmallIcon};
      }

      .ant-select-arrow .anticon {
        color: ${desktop === 'true' ? colors.headerIcon : colors.headerSmallIcon};
        font-size: ${fontSize.small};
        font-weight: bold;
      }
    }
  `}
`;
