import startCase from 'lodash/startCase';
import moment from 'moment';
import React, { useMemo } from 'react';

import { CONTRACT_FIXATION_GRANULARITY } from '../../constants';
import { useLocale } from '../../context/LocalContext';

const FormatPeriodDate = ({ granularity, date, periodMonths, format = 'MMMM YYYY', isCapitalize = false }) => {
  // Use to fix 1419 ticket
  useLocale();

  let firstDate;

  if (granularity === CONTRACT_FIXATION_GRANULARITY.MONTH || (periodMonths && periodMonths === 1)) {
    firstDate = startCase(moment(date).format(format));
  } else {
    firstDate = startCase(moment(date).format(format));
  }

  let secondDate;

  if (granularity === CONTRACT_FIXATION_GRANULARITY.QUARTER || (periodMonths && periodMonths !== 1)) {
    secondDate = moment(date)
      .add({ months: periodMonths - 1 || 2 })
      .format(format);
  }
  if (granularity === CONTRACT_FIXATION_GRANULARITY.YEAR) {
    secondDate = moment(date).add({ months: 11 }).format(format);
  }
  if (granularity === CONTRACT_FIXATION_GRANULARITY.SEMESTER) {
    secondDate = moment(date).add({ months: 6 }).format(format);
  }

  secondDate = startCase(secondDate);

  const formattedStringDate = useMemo(() => {
    let stringDate;

    if (granularity === CONTRACT_FIXATION_GRANULARITY.MONTH || (periodMonths && periodMonths === 1))
      stringDate = firstDate;
    else stringDate = `${firstDate} - ${secondDate}`;

    if (isCapitalize) {
      stringDate.toUpperCase();
    }

    return stringDate;
  }, [firstDate, granularity, isCapitalize, periodMonths, secondDate]);

  return <div style={{ display: 'inline-block' }}>{formattedStringDate}</div>;
};

export default FormatPeriodDate;
