import { Modal as ModalAnt, ModalProps, Space } from 'antd';
import React, { FC, useMemo } from 'react';
import styled from 'styled-components';

import { HeaderOne, TextSmall } from '../../style/utils';

type Props = ModalProps & {
  preTitle?: string;
  icon?: React.ReactElement;
  size: 'extraSmall' | 'small' | 'middle' | 'large';
  children: React.ReactElement | React.ReactElement[];
};

const Modal: FC<Props> = ({ className, children, preTitle, title, icon, size, ...modalProps }) => {
  const width = useMemo(() => {
    switch (size) {
      case 'extraSmall':
        return 520;
      case 'small':
        return 660;
      case 'middle':
        return 700;
      case 'large':
        return 960;
      default:
        throw new Error(`This size: ${size} is not handled by the modal`);
    }
  }, [size]);

  const formattedPreTitle = useMemo(() => {
    if (typeof preTitle === 'string') return preTitle.toUpperCase();
    return preTitle;
  }, [preTitle]);

  return (
    <ModalAnt
      closable
      open
      className={className}
      footer={false}
      title={
        <Space size="middle">
          {icon}
          <Space direction="vertical">
            {preTitle && <TextSmall>{formattedPreTitle}</TextSmall>}
            {title && <HeaderOne>{title}</HeaderOne>}
          </Space>
        </Space>
      }
      {...modalProps}
      width={width}
    >
      {children}
    </ModalAnt>
  );
};

const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-radius: 6px 6px 0 0;
    padding: 2.3rem 2.3rem 1rem;
  }

  .ant-modal-body {
    padding: 0 2.3rem 2.3rem;
    border-radius: 6px 6px 0 0;
  }

  .ant-modal-content {
    border-radius: 6px;
  }

  .ant-modal-footer,
  .ant-modal-header {
    border-top: none;
    border-bottom: none;
  }

  ${TextSmall} {
    text-transform: uppercase;
  }
`;

export default StyledModal;
