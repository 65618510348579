import { useQuery } from 'react-query';

import { useApi } from '../../context/ApiContext';
import { useTenant } from '../../context/TenantContext';
import { useUserInfo } from '../../context/UserInfoContext';
import { isManager } from '../../utils/user';

export const useGetGroupList = () => {
  const client = useApi();
  const { userInfo } = useUserInfo();
  const { env } = useTenant();

  return useQuery(
    'group-list',
    () => client.get(`${env.REACT_APP_BACKEND_PORTAL_URL}/users/multi-access/kamGroup/list`).then((res) => res.data),
    {
      enabled: isManager(userInfo.role),
    },
  );
};
