import { useQuery } from 'react-query';

import { useApi } from '../../context/ApiContext';
import { useUserInfo } from '../../context/UserInfoContext';

export const useGetTimeTravel = () => {
  const client = useApi();
  const { userInfo } = useUserInfo();

  return useQuery('get-time-travel', () => client.get('/run/timeTravel').then((res) => res.data), {
    enabled: userInfo.demo || userInfo.admin,
  });
};
