import { Space } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useTenant } from '../../context/TenantContext';
import { TextRegular } from '../../style/utils';
import PoweredYemIcon from '../icons/PoweredYemIcon';
import InvisibleButton from '../InvisibleButton';

const PoweredByYem = () => {
  const [t] = useTranslation();
  const { env } = useTenant();

  return (
    <Space align="center">
      <TextRegular>{t('powered-by')}</TextRegular>
      <InvisibleButton onClick={() => window.open(env.REACT_APP_POWERED_BY_YEM_URL, '_blank')}>
        <PoweredYemIcon />
      </InvisibleButton>
    </Space>
  );
};

export default PoweredByYem;
