import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/nl';

import { ConfigProvider } from 'antd';
import enUs from 'antd/lib/locale/en_US';
import esES from 'antd/lib/locale/es_ES';
import frFR from 'antd/lib/locale/fr_FR';
import itIt from 'antd/lib/locale/it_IT';
import nlBE from 'antd/lib/locale/nl_BE';
import i18next from 'i18next';
import moment from 'moment';
import qs from 'qs';
import React, { createContext, useContext, useEffect, useState } from 'react';

import history from '../routes/history';
import { useUserInfo } from './UserInfoContext';

const LocalContext = createContext(undefined);

const LocalProvider = ({ children }) => {
  const { userInfo } = useUserInfo();
  const { language } = userInfo;
  const { lng } = qs.parse(history.location.search, { ignoreQueryPrefix: true });
  const [localAnt, setLocalAnt] = useState(undefined);

  useEffect(() => {
    if (language) {
      if (language === 'it') setLocalAnt(itIt);
      else if (language === 'es') setLocalAnt(esES);
      else if (language === 'fr') setLocalAnt(frFR);
      else if (language === 'nl') setLocalAnt(nlBE);
      else setLocalAnt(enUs);

      i18next.changeLanguage(lng || language);
      moment.locale(language);
    }
  }, [language, lng]);

  if (!localAnt) {
    return null;
  }

  return (
    <ConfigProvider locale={localAnt}>
      <LocalContext.Provider value={{ moment: moment.locale() }}>{children}</LocalContext.Provider>
    </ConfigProvider>
  );
};

function useLocale() {
  const context = useContext(LocalContext);
  if (context === undefined) {
    // Can be called to refresh by FormatPeriodDate to solve ticket 1419
    // FormatPeriodDate can be call to render the Template page (to generate pdf), without <LocalContext> defined
    return undefined;
    // throw new Error('useLocale must be used within a LocalProvider');
  }
  return context;
}

export { LocalContext, LocalProvider, useLocale };
