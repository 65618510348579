export const regexEmail =
  // eslint-disable-next-line max-len
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const regexNumeric =
  // eslint-disable-next-line max-len
  /^(0|[1-9][0-9]*)$/;

export const MANAGED_BY = {
  CLIENT: 'client',
  KAM: 'kam',
  BOTH: 'both',
};

export const TAB_PERIOD_SELECTED = 'tab-period-selected';
export const TAB_OPTIMIZATION_SELETED = 'tab-optimization-selected';
export const PRODUCT_PERIOD_START = 'product-period-start';

export const OPT_HISTORY_EVENT = {
  NEW_CONTRACT: 'new-contract',
  NEW_STRATEGY: 'new-strategy',
  NEW_OPTIMIZATION_PERIOD: 'new-optimization-period',
  FIXATIONS_CHANGE: 'fixations-change',
  UPDATE_MARKETTRENDS: 'update-markettrends',
  SWITCH_MARKETTRENDS: 'switch-markettrends',
  EXPIRED_OPTIMIZATION: 'expired-optimization',
  MONTH_END: 'month-end',
  MARKET_EVENT_STOP_LOSS: 'marketEventStopLoss',
  MARKET_EVENT_TAKE_PROFIT: 'marketEventTakeProfit',
  MARKET_EVENT_LOW_LEVEL: 'marketEventLowLevel',
  MARKET_EVENT_END: 'marketEventEnd',
  NEW_OBJECTIVES: 'newObjectives',
  OPTIMIZATION_DATE_UPCOMING: 'fixationDateUpcoming',
};

export const CATEGORY = {
  BILLING: 'billing_issue',
  PLATFORM: 'platform_issue',
  FREATURE: 'feature_request',
  OTHER: 'other',
};

export const OPTIMIZATION_EVENT_GROUPS = {
  ALL_EVENTS: 'all',
  OPTIMIZATION_CHANGE: 'optimization',
  REFERENCE_CHANGE: 'reference',
  FIXATION_CHANGE: 'fixation',
};

export const GRANULARITY = {
  MONTH: 'month',
  QUARTER: 'quarter',
  SUMMER_WINTER: 'summer_winter',
  YEAR: 'year',
  GASYEAR: 'gasyear',
};

export const CONTRACT_FIXATION_GRANULARITY = {
  MONTH: 'month',
  QUARTER: 'quarter',
  SEMESTER: 'semester',
  SUMMER_WINTER: 'summer_winter',
  YEAR: 'year',
  GASYEAR: 'gasyear',
};

export const CONTRACT_FIXATION_GRANULARITY_PERCENT = {
  MONTH: 'month',
  QUARTER: 'quarter',
  SEMESTER: 'semester',
  YEAR: 'year',
};

export const CONTRACT_FIXATION_GRANULARITY_BLOCK = {
  MONTH: 'month',
  QUARTER: 'quarter',
  SUMMER_WINTER: 'summer_winter',
  YEAR: 'year',
  GASYEAR: 'gasyear',
};

export const CONTRACT_FIXATION_GRANULARITY_BLOCK_ELEC = {
  MONTH: 'month',
  QUARTER: 'quarter',
  // SEMESTER: 'semester',
  YEAR: 'year',
};

export const EVENT_TYPE = {
  STOP_LOSS: 'stoploss',
  LOW_LEVEL: 'lowlevel',
  TAKE_PROFIT: 'takeprofit',
};

export const CONT_NUMBER = {
  nmb1: '1',
  nmb2: '2',
  nmb3: '3+',
};

export const MARKET_LOCATION = {
  it: 'IT',
  fr: 'FR',
  be: 'BE',
};

export const MARKET_SUPPLY = {
  gas: 'gas',
  power: 'power',
};

export const FIXATION_STATUS = {
  OPTIMIZED: 'optimized',
  DELIVERED: 'delivered',
  FIXED100: 'fixed100',
  FIXEDMAX: 'fixedmax',
  TOOCLOSE: 'tooclose',
};

// export const FIXATION_UNIT = {
//   MWH: 'mwh-hour',
//   MWH_DAY: 'mwh-day',
//   MWH_MONTH: 'mwh-month',
//   SMC: 'smc-hour',
//   SMC_DAY: 'smc-day',
//   SMC_MONTH: 'smc-month',
//   MMBTU: 'mmbtu',
//   MMBTU_DAY: 'mmbtu-day',
//   MMBTU_WEEK: 'mmbtu-week',
//   MMBTU_MONTH: 'mmbtu-month',
// };

export const CONTRACT_PRODUCT = {
  ITgas: 'psv',
  ITpower: 'edb',
  BEgas: 'ztp',
  BEpower: 'beb',
  FRgas: 'peg',
  FRpower: 'f7b',
};

export const CONTRACT_MARKET_INDEX = {
  PSV: 'psv',
  ZTP: 'ztp',
  TTF: 'ttf',
  PEG: 'peg',
  EDB: 'eex-it',
  BEB: 'endex-be',
  F7B: 'eex-fr',
};

export const CONTRACT_MARKET_INDEX_GAS = {
  PSV: 'psv',
  TTF: 'ttf',
  PEG: 'peg',
  ZTP: 'ztp',
};

export const CONTRACT_MARKET = {
  CAL: 'CAL',
  Q: 'Q',
  S: 'S',
  M: 'M',
};

export const CONTRACT_MARKET_INDEX_POWER = {
  EDB: 'eex-it',
  BEB: 'endex-be',
  F7B: 'eex-fr',
};

export const FIXATION = {
  // SMC: 'smc-hour',
  SMC_DAY: 'smc-day',
  SMC_MONTH: 'smc-month',
  MWH: 'mwh-hour',
  MWH_DAY: 'mwh-day',
  MWH_MONTH: 'mwh-month',
};

export const FIXATION_UNIT_IT = {
  // SMC: 'smc-hour',
  SMC_DAY: 'smc-day',
  SMC_MONTH: 'smc-month',
};

export const FIXATION_UNIT_MX = {
  MMBTU: 'mmbtu',
  MMBTU_DAY: 'mmbtu-day',
  MMBTU_WEEK: 'mmbtu-week',
  MMBTU_MONTH: 'mmbtu-month',
};

export const FIXATION_UNIT = {
  MWH: 'mwh-hour',
  MWH_DAY: 'mwh-day',
  MWH_MONTH: 'mwh-month',
};

export const MARKET_TYPE = {
  DAY_AHEAD: 'day-ahead',
  MONTH_AHEAD: 'month-ahead',
};

export const GRANULARITY_MONTH = 'month';
export const GRANULARITY_QUARTER = 'quarter';
export const GRANULARITY_YEAR = 'year';
