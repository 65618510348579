import { message } from 'antd';
import { useQuery } from 'react-query';

import { useApi } from '../../context/ApiContext';

export const useGetMarketList = () => {
  const client = useApi();

  return useQuery('get-market-list', () => client.get('/multiaccess/manager/marketList').then((res) => res.data), {
    onError() {
      message.error('Request failed to get the list');
    },
  });
};
