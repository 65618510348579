import { Space } from 'antd';
import { ArcElement, Chart, Legend, Tooltip } from 'chart.js';
import moment from 'moment';
import React, { useMemo } from 'react';
import { Pie } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import styled, { ThemeContext } from 'styled-components';

import FormatPeriodDate from '../../../../components/FormatPeriodDate';
import { getNumberFormattedLanguage } from '../../../../utils';
import { getCorrectValueFixation } from '../../../../utils/fixations';
import ListItemTemplate from './ListItemTemplate';
import { Title } from './ListProductTemplate.styled';

const ListProduct = ({
  className,
  product,
  isFixation,
  date,
  periodMonths,
  notFixed,
  amountFixed,
  fixationsDetail,
}) => {
  const [t] = useTranslation();
  const themeContext = React.useContext(ThemeContext);
  Chart.register(ArcElement, Tooltip, Legend);

  const data = useMemo(
    () => ({
      datasets: [
        {
          label: 'Population (millions)',
          backgroundColor: [themeContext.colors.green, themeContext.colors.orange, themeContext.colors.gray],
          data: [product.amountFixed, 100 - product.amountFixed - notFixed, notFixed].map(getCorrectValueFixation),
        },
      ],

      labels: [
        t('item-optimization-strategy-fixed'),
        t('item-optimization-strategy-fixations'),
        t('item-optimization-strategy-not-fixed'),
      ],
    }),
    [product.amountFixed, notFixed, t, themeContext.colors.gray, themeContext.colors.green, themeContext.colors.orange],
  );
  const options = useMemo(
    () => ({
      plugins: {
        legend: {
          display: false,
        },
      },
    }),
    [],
  );

  return (
    <div className={className}>
      <Space direction="vertical">
        <Title>
          <FormatPeriodDate
            date={date}
            format={periodMonths === 1 ? 'MMMM YYYY' : 'MMM YYYY'}
            periodMonths={periodMonths}
          />
        </Title>
        {!isFixation && <Pie data={data} options={options} />}
        <div>
          {!isFixation &&
            fixationsDetail &&
            amountFixed > 0 &&
            fixationsDetail.fixations &&
            fixationsDetail.fixations.length > 0 &&
            fixationsDetail?.fixations?.map((fixation, index) => (
              <ListItemTemplate
                color={themeContext.colors.green}
                key={index}
                label={`${moment(fixation.date).format('DD MMM YYYY')} (${getNumberFormattedLanguage(
                  getCorrectValueFixation(fixation.amount),
                )}%)`}
              />
            ))}
          {product.fixations &&
            product.fixations.length > 0 &&
            product.fixations.map((fixation) => {
              const label = `${moment(fixation.date).format('DD MMM YYYY')} (${getNumberFormattedLanguage(
                getCorrectValueFixation(fixation.amount),
              )}%)`;

              return (
                <ListItemTemplate color={!isFixation ? themeContext.colors.orange : null} key={label} label={label} />
              );
            })}
          {!isFixation && notFixed > 0 && (
            <ListItemTemplate
              color={themeContext.colors.gray}
              label={`${t('item-optimization-strategy-not-fixed')} (${getNumberFormattedLanguage(
                getCorrectValueFixation(notFixed),
              )}%)`}
            />
          )}
        </div>
      </Space>
    </div>
  );
};

export default styled(ListProduct)``;
