import React from 'react';

const AccountIcon = ({ color }) => (
  <svg
    style={{ display: 'block' }}
    xmlns="http://www.w3.org/2000/svg"
    width="18.145"
    height="21.402"
    viewBox="0 0 18.145 21.402"
  >
    <g id="picto-compte-noir_" data-name="picto-compte-noir " transform="translate(0.601 0.6)">
      <path
        id="Tracé_164"
        data-name="Tracé 164"
        d="M1697.637,46.337a3.452,3.452,0,1,1-3.451,3.451A3.452,3.452,0,0,1,1697.637,46.337Z"
        transform="translate(-1689.164 -46.337)"
        fill="none"
        stroke={color}
        strokeMiterlimit="10"
        strokeWidth="1.2"
      />
      <path
        id="Tracé_165"
        data-name="Tracé 165"
        d="M1703.276,71.868h-16.943c0-1.021.006-2.265.006-3.792,0-4.078,3.792-7.385,8.466-7.385a9.1,9.1,0,0,1,5.986,2.164,6.932,6.932,0,0,1,2.483,5.22C1703.272,69.6,1703.276,70.85,1703.276,71.868Z"
        transform="translate(-1686.333 -51.667)"
        fill="none"
        stroke={color}
        strokeMiterlimit="10"
        strokeWidth="1.2"
      />
      <line
        id="Ligne_83"
        data-name="Ligne 83"
        y1="4.923"
        transform="translate(4.112 15.278)"
        fill="none"
        stroke={color}
        strokeMiterlimit="10"
        strokeWidth="1.2"
      />
      <line
        id="Ligne_84"
        data-name="Ligne 84"
        y1="4.923"
        transform="translate(12.835 15.278)"
        fill="none"
        stroke={color}
        strokeMiterlimit="10"
        strokeWidth="1.2"
      />
    </g>
  </svg>
);

export default AccountIcon;
