import styled, { css } from 'styled-components';

import { TextWarning } from '../../../style/utils';

export const PreAlert = styled.div`
  ${({ theme: { colors } }) => css`
    height: 25px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    background: ${colors.alert};
    padding: 0 8px;
    display: inline-flex;
  `}
`;

export const TextWarningWhite = styled(TextWarning)`
  font-size: 14px;
  color: white;
`;

export const TextWarningBig = styled(TextWarning)`
  ${({ theme: { fontSize } }) => css`
    font-size: ${fontSize.header};
    font-weight: 600;
    font-family: 'Fira Sans', serif;
  `}
`;
