import { message } from 'antd';
import { useQuery } from 'react-query';

import { useApi } from '../../context/ApiContext';
import { useTenant } from '../../context/TenantContext';
import { useUserInfo } from '../../context/UserInfoContext';
import { isKam, isManager } from '../../utils/user';

export const useGetMultiAccessList = (kamGroupId = '0', enabled = true) => {
  const client = useApi();
  const { userInfo } = useUserInfo();
  const { env } = useTenant();

  return useQuery(
    'get-multi-access-users',
    () =>
      client
        .get(
          `${env.REACT_APP_BACKEND_PORTAL_URL}/users/multi-access/list${
            kamGroupId === '0' ? '' : `?kamGroup=${kamGroupId}`
          }`,
        )
        .then((res) => res.data),
    {
      onError() {
        message.error('Request failed to get the list');
      },
      enabled: (isManager(userInfo.role) || isKam(userInfo.role)) && enabled,
    },
  );
};
