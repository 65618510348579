import { Space } from 'antd';
import React, { FC } from 'react';

import { TextRegular } from '../../../style/utils';
import Hr from '../../Hr';
import Modal from '../../Modal/Modal';

type Props = {
  title?: string;
  content: React.ReactElement;
  onCancel?: () => void;
  footer?: React.ReactElement;
  icon?: React.ReactElement;
  size: 'extraSmall' | 'small' | 'middle' | 'large';
};

const ModalSimple: FC<Props> = ({ size, title, content, onCancel, footer, icon, ...optionsModal }) => (
  <Modal icon={icon} size={size} title={title} onCancel={onCancel} {...optionsModal}>
    <Space direction="vertical" size="large">
      <TextRegular style={{ lineHeight: '1.8rem' }}>{content}</TextRegular>

      {footer && (
        <>
          <Hr />

          {footer}
        </>
      )}
    </Space>
  </Modal>
);

export default ModalSimple;
