import React from 'react';

// eslint-disable-next-line import/no-cycle
import Input from '..';

const SelectMultiple = React.forwardRef(({ items, ...propsSelectMultiple }, ref) => (
  <Input.Select
    defaultValue={[]}
    items={items}
    mode="multiple"
    ref={ref}
    style={{ width: '100%' }}
    {...propsSelectMultiple}
  />
));

SelectMultiple.displayName = 'SelectMultiple';

export default SelectMultiple;
