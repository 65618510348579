import React from 'react';

import { useUserInfo } from '../../context/UserInfoContext';
import MultiAccessClientCreate from './kam/MultiAccessClientCreate';
import MultiAccessKamCreateEdit from './manager/MultiAccessKamCreateEdit/MultiAccessKamCreateEdit';

const MultiAccessCreate = () => {
  const { userInfo } = useUserInfo();
  const [currentStep, setCurrentStep] = React.useState(0);

  const nexStep = React.useCallback(() => {
    setCurrentStep((c) => c + 1);
  }, []);

  const previousStep = React.useCallback(() => {
    setCurrentStep((c) => c - 1);
  }, []);

  return userInfo.role === 'kam' ? (
    <MultiAccessClientCreate currentStep={currentStep} nexStep={nexStep} previousStep={previousStep} />
  ) : (
    <MultiAccessKamCreateEdit />
  );
};

export default MultiAccessCreate;
