import { message } from 'antd';
import { useMutation } from 'react-query';

import { useApi } from '../../context/ApiContext';
import { useUserInfo } from '../../context/UserInfoContext';

export const useUpdateUserLanguage = () => {
  const client = useApi();
  const { setUserInfo } = useUserInfo();

  return useMutation(
    (language) =>
      client
        .post(`${process.env.REACT_APP_BACKEND_PORTAL_URL}/users/setLanguage`, { language })
        .then((res) => res.data),
    {
      onSuccess(_, language) {
        setUserInfo({ language });
      },
      onError() {
        message.error('Request failed to update the language');
      },
    },
  );
};
