import '../InvisibleButton/InvisibleButton';

import styled, { css } from 'styled-components';

import InvisibleButton from '../InvisibleButton';

export const ButtonClose = styled(InvisibleButton)`
  ${({ theme: { colors } }) => css`
    color: ${colors.baseColor};
    font-weight: bold;
    position: absolute;
    top: 23px;
    right: 14px;
  `}
`;

export const ExtraHeader = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  background: #f4f4f4;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding: 20px 30px;
`;
