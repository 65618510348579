import React from 'react';
import { useQueryClient } from 'react-query';

export default () => {
  const queryClient = useQueryClient();
  const groupId = location.pathname.split('/')[3];

  const groupLists = queryClient.getQueryData('group-list');

  if (!groupLists) return null;

  const group = groupLists.find((c) => c.id === groupId);

  if (!group) return null;

  return <span>{group.name}</span>;
};
