import { Col, Row, Space } from 'antd';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ThemeContext } from 'styled-components';

import Button from '../../../../components/Button';
import ButtonLink from '../../../../components/ButtonLink';
import Collapse from '../../../../components/Collapse';
import Hr from '../../../../components/Hr';
import ArrowRight from '../../../../components/icons/ArrowRight';
import CompanyIcon from '../../../../components/icons/CompanyIcon';
import ModalDeleteMultiAccess from '../../../../components/Modals/ModalDeleteMultiAccess';
import { WrapperCountry } from '../../../../components/Modals/ModalMarketTrend/ItemMarketTrend/ItemMarketTrend';
import ModalTransfersAccount from '../../../../components/Modals/ModalTransfersAccount';
import Table from '../../../../components/Table';
import WarningBox from '../../../../components/WarningBox/Box';
import WrapperWhiteBox from '../../../../components/WrapperWhiteBox';
import { MANAGED_BY } from '../../../../constants';
import { useGetCanDeleteUser } from '../../../../endpoints/multiaccess/useGetCanDeleteUser';
import { useGetKamContractList } from '../../../../endpoints/multiaccess/useGetKamContractList';
import { useGetMultiAccessUserDetail } from '../../../../endpoints/multiaccess/useGetMultiAccssUserDetail';
import useCookie from '../../../../hooks/useCookie';
import { Navigation } from '../../../../navigation';
import history from '../../../../routes/history';
import { Label, TextColored, TextRegular, TextRegularBold, TextWarning } from '../../../../style/utils';
import { getFlag } from '../../../../utils/flag';
import { dateMarketIncludeToday } from '../../utils';

const MultiAccessClientDetails = () => {
  const [t] = useTranslation();
  const [showModalTransfers, setShowModalTransfers] = React.useState(false);
  const [showModalDelete, setShowModalDelete] = React.useState(false);
  const { userId } = useParams();
  const themeContext = React.useContext(ThemeContext);
  const { setCookie } = useCookie();
  const { data, isLoading } = useGetMultiAccessUserDetail(userId);
  const { data: kamContractList, isLoading: isKamContractListLoading } = useGetKamContractList(userId);
  const { data: canDeleteUser } = useGetCanDeleteUser(userId);

  const header = React.useMemo(
    () => [
      {
        title: t('multi-access-name-contract-title'),
        dataIndex: 'name',
        render: (name) => <TextColored>{name}</TextColored>,
        width: '30%',
      },
      {
        title: t('multi-access-country-title'),
        dataIndex: 'country',
        width: '15%',
        render: (country) => (
          <Space>
            <WrapperCountry>{getFlag(country)}</WrapperCountry>
            <TextRegular>{t(`country-${country}`)}</TextRegular>
          </Space>
        ),
      },
      {
        title: t('multi-access-energy-title'),
        width: '15%',
        dataIndex: 'energy',
        render: (energy) => <TextRegular>{t(`global-${energy}`)}</TextRegular>,
      },
      {
        title: t('multi-access-optimize-title'),
        dataIndex: 'optimized',
        sorter: (a, b) => {
          if (a.optimized && !b.optimized) {
            return -1;
          }
          if (!a.optimized && b.optimized) {
            return 1;
          }

          return b.name.localeCompare(a.name);
        },
        render: (optimized) => (optimized ? t('multi-access-optimized-true') : t('multi-access-optimized-false')),
      },
      {
        title: t('multi-access-optimized-contract-title'),
        dataIndex: 'marketIsValid',
        width: '15%',
        render: (marketIsValid) =>
          marketIsValid ? (
            t(`multi-access-market-valid`)
          ) : (
            <TextWarning style={{ fontWeight: 'normal' }}>{t('multi-access-market-not-valid')}</TextWarning>
          ),
      },
      {
        key: 'arrow',
        width: '1%',
        render: () => <ArrowRight color={themeContext.colors.baseColor} />,
      },
    ],
    [t, themeContext.colors.baseColor],
  );

  const dataSource = React.useMemo(() => {
    if (!kamContractList) return [];

    return kamContractList.map((item) => ({
      ...item,
      key: item.id,
    }));
  }, [kamContractList]);

  const contractMax = React.useMemo(() => {
    if (!data || !kamContractList) return false;
    let check = false;

    data.user.multiaccess.clientMarkets.forEach((market) => {
      let countContract = 0;
      kamContractList.forEach((item) => {
        if (market.country === item.country && market.energy === item.energy) countContract += 1;
      });
      if (market.contracts === countContract) check = true;
      else check = false;
    });

    return check;
  }, [data, kamContractList]);

  const isAtLeastOneMarketValid = React.useMemo(() => {
    if (!data) return true;

    return dateMarketIncludeToday(data.user.multiaccess.clientMarkets);
  }, [data]);

  const marketHeader = React.useMemo(
    () => [
      {
        title: t('multi-access-header-market'),
        name: 'multiaccess',
        render: (market) => (
          <TextRegular>
            <Space>
              {getFlag(market.country)}
              <div>
                {t(`country-${market.country}`)} / {t(`global-${market.energy}`)}
              </div>
            </Space>
          </TextRegular>
        ),
        width: '30%',
      },
      {
        title: t('multi-access-header-start'),
        dataIndex: 'periodStart',
        render: (periodStart) => <TextRegular>{moment(periodStart).format('DD/MM/YYYY')}</TextRegular>,
        width: '30%',
      },
      {
        title: t('multi-access-header-end'),
        dataIndex: 'periodEnd',
        render(periodEnd) {
          const today = moment().format('YYYY-MM-DD');
          const momentPeriodEnd = moment(periodEnd);
          if (moment(today).isAfter(moment(momentPeriodEnd)))
            return <TextWarning style={{ fontWeight: 'normal' }}>{momentPeriodEnd.format('DD/MM/YYYY')}</TextWarning>;

          return <TextRegular>{momentPeriodEnd.format('DD/MM/YYYY')}</TextRegular>;
        },
        width: '30%',
      },
      {
        title: t('multi-access-header-forecasts'),
        name: 'forecasts',
        render(market) {
          const forecastType = [];

          if (market.marketTrendsPartner) forecastType.push(t('market-item-partner'));
          if (market.marketTrendsCompany) forecastType.push(t('market-item-company'));
          if (market.marketTrendsUser) forecastType.push(t('market-item-user'));

          return (
            <TextRegular>
              {forecastType.map((elem) => (
                <div key={elem}>{elem}</div>
              ))}
            </TextRegular>
          );
        },
        width: '30%',
      },
      {
        title: t('multi-access-header-number-optimisation'),
        dataIndex: 'contracts',
        align: 'center',
        render: (contracts) => <TextRegular>{contracts}</TextRegular>,
        width: '30%',
      },
    ],
    [t],
  );

  const extraHeader = React.useMemo(
    () => (
      <Row gutter={[8, 8]}>
        <Col flex="auto">
          <Button
            variant="secondary"
            onClick={() => history.push(Navigation.MULTI_ACCESS_EDIT.replace(':userId', userId))}
          >
            {t('multi-access-list-modify-client')}
          </Button>
        </Col>
        <Col flex="auto">
          <Button
            disabled={contractMax}
            onClick={() => {
              setCookie('has-client', userId);
              setCookie('client-id', userId);
              history.push(Navigation.CONTRACT_NEW);
            }}
          >
            {t('multi-access-list-create-contract')}
          </Button>
        </Col>
      </Row>
    ),
    [setCookie, t, userId, contractMax],
  );

  if (isLoading) {
    return null;
  }

  return (
    <Space direction="vertical" size={40}>
      {data && data.user.multiaccess.clientCanLogin && !isAtLeastOneMarketValid && (
        <WarningBox
          description={t('multi-access-client-details-description')}
          title={t('multi-access-client-details-title')}
        />
      )}

      {contractMax && (
        <WarningBox
          description={t('multi-access-client-details-description-max-contract')}
          title={t('multi-access-client-details-title-max-contract')}
        />
      )}

      <WrapperWhiteBox extra={extraHeader} icon={<CompanyIcon />} title={data.user.company}>
        <Space direction="vertical" size="large">
          <Row gutter={[0, 25]}>
            <Col span={24}>
              <Collapse panelProps={{ key: 0, header: t('multi-access-user-information-title') }}>
                <Space direction="vertical">
                  <Row gutter={[25, 20]}>
                    <Col sm={7} xs={24}>
                      <Space direction="vertical" size="middle">
                        <Label>{t('multi-access-user-contact-title')}</Label>
                        <div>
                          <TextRegularBold>{t('multi-access-user-contact-name')}</TextRegularBold>
                          <TextRegular>
                            {data.user.firstName} / {data.user.lastName}
                          </TextRegular>
                          <TextRegularBold>{t('multi-access-user-contact-phone')}</TextRegularBold>
                          <TextRegular>{data.user.phone}</TextRegular>
                          <TextRegularBold>{t('multi-access-user-contact-mail')}</TextRegularBold>
                          <TextRegular>{data.user.username}</TextRegular>
                        </div>
                      </Space>
                    </Col>
                    {data.user.multiaccess.clientReference && (
                      <Col sm={7} xs={24}>
                        <Space direction="vertical" size="middle">
                          <Label>{t('multi-access-user-reference-client')}</Label>
                          <div>
                            <TextRegular>{data.user.multiaccess.clientReference}</TextRegular>
                          </div>
                        </Space>
                      </Col>
                    )}
                  </Row>
                </Space>
              </Collapse>
            </Col>
            <Col span={24}>
              <Collapse panelProps={{ key: 0, header: t('multi-access-contract-title') }}>
                <Table
                  columns={header}
                  dataSource={dataSource}
                  loading={isKamContractListLoading}
                  rowKey={(record) => record.id}
                  showSorterTooltip={false}
                  onRow={(record) => ({
                    onClick: () => history.push(Navigation.CONTRACTS_DETAILS.replace(':id', record.id)),
                  })}
                />
              </Collapse>
            </Col>
            <Col span={24}>
              <Collapse
                collapseProps={{ defaultActiveKey: [0] }}
                panelProps={{ key: 0, header: t('multi-access-user-management-client-access') }}
              >
                <Space direction="vertical" size="large">
                  <Space direction="vertical" size={0}>
                    <Label>{t('multi-access-user-management-client-access-title')}</Label>
                    <TextRegular>
                      {t(
                        `multi-access-user-management-client-access-${data.user.multiaccess.clientCanLogin.toString()}`,
                      )}
                    </TextRegular>
                  </Space>
                  {data.user.multiaccess.clientCanLogin && (
                    <>
                      <Space direction="vertical" size={0}>
                        <Label>{t('multi-access-user-management-management-title')}</Label>
                        <TextRegular>
                          {(data.user.multiaccess.contractsManagedBy === MANAGED_BY.KAM ||
                            data.user.multiaccess.contractsManagedBy === MANAGED_BY.CLIENT) && (
                            <Space>
                              <TextRegular>{t('multi-access-user-management-contract-kam')}</TextRegular>
                              <p>&</p>
                              <TextRegular>{t('multi-access-user-management-contract-client')}</TextRegular>
                            </Space>
                          )}
                        </TextRegular>
                      </Space>

                      <Space direction="vertical">
                        <Label>{t('multi-access-user-market-forecast')}</Label>
                        <Table
                          noBgHover
                          columns={marketHeader}
                          dataSource={data.user.multiaccess.clientMarkets.filter((info) => info.enabled)}
                          isClickable={false}
                          rowKey={(market) => `${t(`country-${market.country}`)} / ${t(`global-${market.energy}`)}`}
                        />
                      </Space>
                    </>
                  )}
                  {!data.user.multiaccess.clientCanLogin && <Hr noBottom noTop />}
                </Space>
              </Collapse>
            </Col>
          </Row>

          <Space size="large">
            {!canDeleteUser?.canDelete && (
              <ButtonLink onClick={() => setShowModalTransfers(true)}>{t('multi-access-transfert-account')}</ButtonLink>
            )}
            {canDeleteUser?.canDelete && (
              <ButtonLink color={themeContext.colors.orange} onClick={() => setShowModalDelete(true)}>
                {t('multi-access-delete-account')}
              </ButtonLink>
            )}
          </Space>
        </Space>
      </WrapperWhiteBox>

      {showModalTransfers && (
        <ModalTransfersAccount
          title={`${data.user.company} - ${data.user.firstName} ${data.user.lastName}`}
          userId={data.user.id}
          onClose={() => setShowModalTransfers(false)}
        />
      )}
      {showModalDelete && (
        <ModalDeleteMultiAccess
          canDelete
          companyName={data.user.company}
          firstName={data.user.firstName}
          lastName={data.user.lastName}
          userId={data.user._id}
          onClose={() => setShowModalDelete(false)}
        />
      )}
    </Space>
  );
};

export default MultiAccessClientDetails;
